import PropTypes from 'prop-types';
import React from 'react';
import _every from 'underscore/modules/every';
import _extend from 'underscore/modules/extend';
import DashboardActions from 'react/member/actions/dashboard_actions';
import { arrangeRulesetParams } from 'react/member/utils/Ruleset';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';

export default class SelectMerchantSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchant: '',
      addMerchantName: undefined,
      addMerchantInfo: undefined,
      settingRadioOption: undefined,
      applyToDefaultSpendingMonitor: false,
      allowAllTransactionsLessThanNumber: undefined,
      displayAddMerchantForm: false,
      merchantsToChoose: [],
      hasSubmittedForm: false,
      formErrors: {
        merchant: '',
        settingRadioOption: '',
        addMerchantName: '',
        addMerchantInfo: '',
        allowAllTransactionsLessThanNumber: '',
      },
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('merchantNameClusters.fetch', this.onMerchantNameClustersLoaded);
    DashboardActions.fetchMerchantNameClusters();
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('merchantNameClusters.fetch', this.onMerchantNameClustersLoaded);
  }

  onMerchantNameClustersLoaded() {
    this.setState({
      merchantsToChoose: DashboardRulesetStore.getMerchantNameClusters(),
    });
  }

  submitMerchantSettings(ev) {
    ev.preventDefault();
    this.setState({ hasSubmittedForm: true });
    this.validateMerchantSettingForm();
    if (this.validForm()) {
      const rulesetParams = this.arrangeParamsForPost();
      this.props.submitAddMerchantSettingForm(rulesetParams);
    }
  }

  arrangeParamsForPost() {
    const {
      merchant,
      addMerchantName,
      addMerchantInfo,
      displayAddMerchantForm,
      settingRadioOption,
      allowAllTransactionsLessThanNumber,
      applyToDefaultSpendingMonitor,
    } = this.state;

    // We rely on merchant here, but if they select displayAddMerchantForm we need to use addMerchantName instead
    const rulesetParams = _extend(
      arrangeRulesetParams(
        settingRadioOption,
        merchant || addMerchantName,
        allowAllTransactionsLessThanNumber,
      ),
      { apply_to_default_spending_monitor: applyToDefaultSpendingMonitor },
    );
    if (displayAddMerchantForm) {
      _extend(rulesetParams, {
        merchant_name: addMerchantName,
        description: addMerchantInfo,
      });
    }

    return rulesetParams;
  }

  handleInputChange({ target: { type, checked: checkedValue, value: nonCheckedValue, name } }) {
    const value = type === 'checkbox' ? checkedValue : nonCheckedValue;
    let stateObj = {};
    stateObj[name] = value;
    stateObj = this.discardPrevUserInputs(stateObj, name, value);
    if (this.state.hasSubmittedForm) {
      this.setState(stateObj, () => this.validateMerchantSettingForm());
    } else {
      this.setState(stateObj);
    }
  }

  discardPrevUserInputs(stateObj, name, value) {
    switch (name) {
      case 'settingRadioOption':
        if (value !== 'allow all transactions less than') {
          _extend(stateObj, { allowAllTransactionsLessThanNumber: undefined });
        }
        break;
      case 'merchant':
        if (value !== '') {
          _extend(stateObj, { displayAddMerchantForm: false });
        }
        break;
    }
    return stateObj;
  }

  toggleAddMerchantForm({ target: { checked } }) {
    if (this.state.hasSubmittedForm) {
      this.setState({ displayAddMerchantForm: checked, merchant: '' }, () =>
        this.validateMerchantSettingForm(),
      );
    } else {
      this.setState({ displayAddMerchantForm: checked, merchant: '' });
    }
  }

  validateMerchantSettingForm() {
    const {
      formErrors,
      merchant,
      settingRadioOption,
      displayAddMerchantForm,
      addMerchantName,
      addMerchantInfo,
      allowAllTransactionsLessThanNumber,
    } = this.state;
    formErrors.merchant =
      !displayAddMerchantForm && merchant === '' ? 'Please select a merchant' : '';
    formErrors.settingRadioOption =
      settingRadioOption === undefined ? 'Please select a setting' : '';
    formErrors.addMerchantName =
      displayAddMerchantForm && addMerchantName === undefined ? 'Please enter a merchant name' : '';
    formErrors.addMerchantInfo =
      displayAddMerchantForm && addMerchantInfo === undefined
        ? 'Please enter information about the merchant'
        : '';
    formErrors.allowAllTransactionsLessThanNumber =
      settingRadioOption === 'allow all transactions less than' &&
      allowAllTransactionsLessThanNumber === undefined
        ? 'Please enter an amount'
        : '';
    this.setState({ formErrors });
  }

  validForm() {
    return _every(this.state.formErrors, (error) => error === '');
  }

  renderDisplayMerchantForm() {
    if (!this.state.displayAddMerchantForm) return;

    const { addMerchantName, addMerchantInfo, formErrors } = this.state;

    return (
      <div className="new-form__data__subsection" style={{ marginTop: -6 }}>
        <p>
          With a little more information, we'll add that merchant for you. Just provide the merchant
          name and any other information you might have (e.g., address, phone number, website). This
          change will not go into effect until you receive a confirmation email from us. If you need
          this change urgently, please email support@truelinkfinancial.com or call us
          1-877-264-8783.
        </p>
        <div className="new-form-field">
          <div className="new-form__label">
            <label htmlFor="addMerchantName">Merchant name:</label>
          </div>
          <div className="new-form__data">
            <input
              id="addMerchantName"
              name="addMerchantName"
              onChange={this.handleInputChange}
              style={{ width: '100%' }}
              type="text"
              value={addMerchantName || ''}
            />
            {formErrors.addMerchantName && (
              <div className="form-error">{formErrors.addMerchantName}</div>
            )}
          </div>
        </div>
        <div className="new-form-field">
          <div className="new-form__label">
            <label htmlFor="addMerchantInfo">Merchant info:</label>
          </div>
          <div className="new-form__data">
            <textarea
              cols="33"
              id="addMerchantInfo"
              name="addMerchantInfo"
              onChange={this.handleInputChange}
              rows="5"
              style={{ width: '100%' }}
              value={addMerchantInfo || ''}
            />
            {formErrors.addMerchantInfo && (
              <div className="form-error">{formErrors.addMerchantInfo}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderDefaultSpendingMonitorOption() {
    if (!this.props.showDefaultSpendingMonitorOption) return;
    return (
      <div className="new-form__data__subsection">
        <label className="new-form__checkbox" htmlFor="applyToDefaultSpendingMonitor">
          <input
            checked={this.state.applyToDefaultSpendingMonitor}
            id="applyToDefaultSpendingMonitor"
            name="applyToDefaultSpendingMonitor"
            onChange={this.handleInputChange}
            type="checkbox"
            value="apply default spending monitor"
          />{' '}
          Apply this setting to the default spending monitor
        </label>
      </div>
    );
  }

  render() {
    const { showMerchantSettings, selectedAccountCount } = this.props;

    const {
      merchant,
      settingRadioOption,
      allowAllTransactionsLessThanNumber,
      displayAddMerchantForm,
      merchantsToChoose,
      formErrors,
    } = this.state;

    if (!showMerchantSettings) {
      return (
        <div>
          <h3
            className="select-accounts__header"
            style={{ borderTop: '1px solid #ddd', color: '#aaa' }}
          >
            2. Select merchant settings
          </h3>
        </div>
      );
    }

    let submitButtonText;
    if (displayAddMerchantForm) {
      submitButtonText = 'Email support with merchant setting request';
    } else {
      submitButtonText = `Add setting to ${selectedAccountCount} account${
        selectedAccountCount > 1 ? 's' : ''
      }`;
    }

    return (
      <div>
        <div>
          <h3 className="select-accounts__header">2. Select merchant settings</h3>
          <form className="select-merchant-settings" onSubmit={this.submitMerchantSettings}>
            <div className="new-form-field">
              <div className="new-form__label">
                <label htmlFor="merchant-select">Merchant:</label>
              </div>
              <div className="new-form__data">
                <select name="merchant" onChange={this.handleInputChange} value={merchant || ''}>
                  <option value="">Select from list</option>
                  {merchantsToChoose.map((merchant) => (
                    <option key={merchant} value={merchant}>
                      {merchant}
                    </option>
                  ))}
                </select>
                <div style={{ paddingTop: 10 }}>
                  <label className="new-form__checkbox" htmlFor="displayAddMerchantForm">
                    <input
                      checked={displayAddMerchantForm}
                      id="displayAddMerchantForm"
                      name="displayAddMerchantForm"
                      onChange={this.toggleAddMerchantForm}
                      type="checkbox"
                    />{' '}
                    The merchant I'm looking for isn't on the list
                  </label>
                </div>
                {formErrors.merchant && (
                  <div className="form-error" style={{ paddingTop: 10 }}>
                    {formErrors.merchant}
                  </div>
                )}
              </div>
            </div>
            {this.renderDisplayMerchantForm()}
            <div className="new-form-field" style={{ borderTop: '1px solid #ddd', paddingTop: 15 }}>
              <div className="new-form__label">
                <label htmlFor="setting">Setting:</label>
              </div>
              <div className="new-form__data">
                <div className="radio-group">
                  <label htmlFor="settingBlockAllTransactions">
                    <input
                      checked={settingRadioOption == 'block all transactions'}
                      id="settingBlockAllTransactions"
                      name="settingRadioOption"
                      onChange={this.handleInputChange}
                      type="radio"
                      value="block all transactions"
                    />{' '}
                    Block all transactions
                  </label>
                  <label htmlFor="settingAllowAllTransactions">
                    <input
                      checked={settingRadioOption == 'allow all transactions'}
                      id="settingAllowAllTransactions"
                      name="settingRadioOption"
                      onChange={this.handleInputChange}
                      type="radio"
                      value="allow all transactions"
                    />{' '}
                    Allow all transactions
                  </label>
                  <label htmlFor="settingAllowAllTransactionsLessThan">
                    <input
                      checked={settingRadioOption == 'allow all transactions less than'}
                      id="settingAllowAllTransactionsLessThan"
                      name="settingRadioOption"
                      onChange={this.handleInputChange}
                      type="radio"
                      value="allow all transactions less than"
                    />{' '}
                    Allow all transactions less than &nbsp;&nbsp; ${' '}
                    <input
                      min="1"
                      name="allowAllTransactionsLessThanNumber"
                      onChange={this.handleInputChange}
                      size="5"
                      step="1"
                      style={{ width: 100 }}
                      type="number"
                      value={allowAllTransactionsLessThanNumber || ''}
                    />
                    {formErrors.allowAllTransactionsLessThanNumber && (
                      <span className="form-error">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {formErrors.allowAllTransactionsLessThanNumber}
                      </span>
                    )}
                  </label>
                  {formErrors.settingRadioOption && (
                    <div className="form-error" style={{ paddingTop: 10 }}>
                      {formErrors.settingRadioOption}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.renderDefaultSpendingMonitorOption()}
            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '15px 0 5px',
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <div className="info-box--with-icon--no-background" style={{ marginBottom: 0 }}>
                The selected accounts will be updated to the above setting, replacing any rules
                currently in place on these accounts for this merchant. You will be able to delete
                or replace the setting for this merchant in bulk, but doing so will not restore any
                previous settings you had on these accounts.
              </div>
            </div>
            <div
              className="clearfix"
              style={{
                margin: '0 -20px',
                padding: '15px 20px',
                borderTop: '1px solid #ddd',
                textAlign: 'right',
              }}
            >
              <TrueLinkButton className="btn btn-success" type="submit" variant="none">
                {submitButtonText}
              </TrueLinkButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SelectMerchantSettings.propTypes = {
  selectedAccountCount: PropTypes.number.isRequired,
  showDefaultSpendingMonitorOption: PropTypes.bool.isRequired,
  showMerchantSettings: PropTypes.bool.isRequired,
  submitAddMerchantSettingForm: PropTypes.func.isRequired,
};
