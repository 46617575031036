import PropTypes from 'prop-types';
import React from 'react';
import ClientSelectNav from './clients/ClientSelectNav';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import bindAll from 'react/shared/utils/bind_all';

export default class WmDashboardClientSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      areClientsLoading: !props.orgTrustBenesJson,
    };
    bindAll(this);
  }

  componentDidMount() {
    if (!this.props.orgTrustBenesJson) {
      DashboardClientStore.on('clients.fetchSelectIds', this.onChangeClients);

      if (!this.props.organization) {
        DashboardActions.fetchClientSelectIds();
      } else {
        DashboardActions.fetchClientSelectIdsByOrganization(this.props.organization.slug);
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.orgTrustBenesJson) {
      DashboardClientStore.off('clients.fetchSelectIds', this.onChangeClients);
    }
  }

  onChangeClients(clients) {
    return this.setState({
      areClientsLoading: false,
      clients,
    });
  }

  redirectToClient(clientSlug) {
    return this.props.organization
      ? RailsRoutes.dashboard_organization_client_path(this.props.organization.slug, clientSlug)
      : RailsRoutes.dashboard_client_path(clientSlug);
  }

  render() {
    return (
      <ClientSelectNav
        clients={this.props.orgTrustBenesJson ? this.props.trustBeneficiaries : this.state.clients}
        isLoading={this.state.areClientsLoading}
        redirectToClientURLFunc={this.redirectToClient}
        selected={this.props.client}
      />
    );
  }
}

WmDashboardClientSelect.propTypes = {
  client: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    person: PropTypes.object.isRequired,
  }),
  organization: PropTypes.object,
  orgTrustBenesJson: PropTypes.bool,
  trustBeneficiaries: PropTypes.array,
};
