// Capitalize the first letter of a string, e.g. first word in a sentence
export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

// Capitalize the first letter of every word in a string. Also replaces underscores with spaces
export function capitalizeWords(value) {
  return value.toLowerCase().replace(/_/g, ' ').split(' ').map(capitalize).join(' '); // Capitalize first letter of every word
}

// Replace unfriendly characters with a ' '
export function humanize(value) {
  return value
    .replace(/[-_.]/g, ' ')
    .replace(/([A-Z])/g, ' $1')
    .trim();
}

// Camel case the String
export function camelize(value) {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index == 0 ? letter.toLowerCase() : letter.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

export function camelToTitle(camel) {
  const result = camel.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function possessiverize(value) {
  return value + (value?.match(/s$/) ? '’' : '’s');
}

export function underscorize(value) {
  return camelize(value)
    .replace(/[A-Z]/g, ($1) => `_${$1.toLowerCase()}`)
    .replace(/--/g, '-');
}
