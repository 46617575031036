import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { currencyFormatter } from 'react/shared/utils/Money';

const useStyles = makeStyles(() => ({
  breached: {
    color: '#F24A4A',
    fontWeight: 700,
  },
  highlight: (statusDisplay) => ({
    display: 'flex',
    alignItems: 'center',
    color: statusDisplay.color,
    fontWeight: 600,
    '& svg': {
      marginRight: '10px',
    },
  }),
  label: {
    marginBottom: '5px',
    opacity: '50%',
  },
  largeText: {
    color: '#333',
    fontSize: '26px',
    fontWeight: 400,
    lineHeight: '36px',
  },
  largeCell: {
    flex: '1 1 auto',

    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
    },
  },
  smallCell: {
    flex: '0 1 130px',
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      flex: '0 1 80px',
    },
  },
  section: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 400,
    justifyContent: 'space-between',
    lineHeight: '22px',
    '&:not(:last-child)': {
      marginBottom: '10px',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const ACTIVE = {
  color: PALETTE.emerald,
  icon: 'fa fa-check-circle',
  text: 'Active',
};
const CARD_REQUESTED = {
  color: PALETTE.blue,
  icon: 'fa fa-check-circle',
  text: 'Card Requested',
};
const CLOSED = {
  color: PALETTE.red,
  icon: 'fa fa-circle-xmark',
  text: 'Closed',
};
const DISABLED = {
  color: PALETTE.orange,
  icon: 'fa fa-minus-circle',
  text: 'Temporarily inactive',
};
const IN_TRANSIT = {
  color: PALETTE.orange,
  icon: 'fa fa-arrow-circle-right',
  text: 'In Transit',
};
const LOST = {
  color: PALETTE.orange,
  icon: 'fa fa-minus-circle',
  text: 'Lost Card',
};
const NOT_ACTIVATED = {
  color: PALETTE.orange,
  icon: 'fa fa-minus-circle',
  text: 'Not Activated',
};
const REPLACEMENT_CARD_REQUESTED = {
  color: PALETTE.blue,
  icon: 'fa fa-check-circle',
  text: 'Replacement Card Requested',
};

const STATUS_DISPLAY_MAPPING = {
  // first card order
  [CARD_STATUSES.PRE_RELEASE_FIRST_CARD_ORDER_STATUS]: CARD_REQUESTED,
  [CARD_STATUSES.RELEASED_FIRST_CARD_ORDER_STATUS]: IN_TRANSIT,
  [CARD_STATUSES.OVERDUE_PRE_RELEASE_FIRST_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  [CARD_STATUSES.OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  // additional card order
  [CARD_STATUSES.PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS]: CARD_REQUESTED,
  [CARD_STATUSES.RELEASED_ADDITIONAL_CARD_ORDER_STATUS]: IN_TRANSIT,
  [CARD_STATUSES.OVERDUE_PRE_RELEASE_ADDITIONAL_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  [CARD_STATUSES.OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  // backup card (reissue C)
  [CARD_STATUSES.PRE_RELEASE_BACKUP_CARD_ORDER_STATUS]: REPLACEMENT_CARD_REQUESTED,
  [CARD_STATUSES.RELEASED_BACKUP_CARD_ORDER_STATUS]: IN_TRANSIT,
  [CARD_STATUSES.OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  // replacement card (reissue A)
  [CARD_STATUSES.PRE_RELEASE_REPLACEMENT_CARD_ORDER_STATUS]: REPLACEMENT_CARD_REQUESTED,
  [CARD_STATUSES.RELEASED_REPLACEMENT_CARD_ORDER_STATUS]: IN_TRANSIT,
  [CARD_STATUSES.OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  // legacy card (reissue C legacy)
  [CARD_STATUSES.PRE_RELEASE_LEGACY_CARD_ORDER_STATUS]: REPLACEMENT_CARD_REQUESTED,
  [CARD_STATUSES.RELEASED_LEGACY_CARD_ORDER_STATUS]: IN_TRANSIT,
  [CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS]: NOT_ACTIVATED,
  // card
  [CARD_STATUSES.CLOSED_CARD_STATUS]: CLOSED,
  [CARD_STATUSES.INACTIVE_CARD_STATUS]: DISABLED,
  [CARD_STATUSES.LOST_CARD_STATUS]: LOST,
  [CARD_STATUSES.OPEN_CARD_STATUS]: ACTIVE,
  [CARD_STATUSES.REPLACED_CARD_STATUS]: LOST,
};

export default function CardDetails({
  cardBalance,
  cardBreached,
  cardLast4,
  expiryDate,
  note,
  status,
}) {
  const classes = useStyles(STATUS_DISPLAY_MAPPING[status], theme);

  let balance;
  if (cardLast4 && cardBalance) {
    balance = currencyFormatter(cardBalance);
  } else if (cardLast4) {
    balance = currencyFormatter(0);
  } else {
    balance = currencyFormatter(null);
  }

  let expires;
  if (cardLast4 && expiryDate) {
    expires = moment(expiryDate).format('MM/YYYY');
  } else if (cardLast4) {
    expires = 'Expired';
  } else {
    expires = '-';
  }

  return (
    <>
      <div className={classes.section}>
        <span className={classes.largeCell}>
          <div className={classes.label}>Status</div>
          <div className={classNames(classes.largeText, classes.highlight)}>
            <i className={STATUS_DISPLAY_MAPPING[status].icon} />
            {STATUS_DISPLAY_MAPPING[status].text}
          </div>
        </span>
        <span className={classes.smallCell}>
          <div className={classes.label}>Card ending in</div>
          <div className={classes.largeText}>{cardLast4 ? `...${cardLast4}` : '-'}</div>
        </span>
        <span className={classes.smallCell}>
          <div className={classes.label}>Balance</div>
          <div className={classes.largeText}>{balance}</div>
        </span>
        <span className={classes.smallCell}>
          <div className={classes.label}>Expires</div>
          <div className={classes.largeText}>{expires}</div>
        </span>
      </div>
      {note && (
        <div className={classes.section}>
          <span>
            <div className={classes.label}>Note</div>
            {cardBreached && (
              <p className={classes.breached}>
                This card was compromised in a recent merchant data breach.
              </p>
            )}
            <div>{note}</div>
          </span>
        </div>
      )}
    </>
  );
}

CardDetails.propTypes = {
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardLast4: PropTypes.string,
  expiryDate: PropTypes.string,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  status: PropTypes.oneOf(Object.values(CARD_STATUSES)).isRequired,
};

CardDetails.defaultProps = {
  cardBalance: null,
  cardBreached: false,
  cardLast4: null,
  expiryDate: null,
  note: null,
};
