import PropTypes from 'prop-types';
import React from 'react';
import { estimatedArrivalShape } from 'react/member/shapes/estimated_arrival_shape';
import GenericSubmitPopUp from 'react/shared/components/popups/GenericSubmitPopUp';
import { possessiverize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class ProfessionalSignupConfirmationModal extends React.Component {
  static get propTypes() {
    return {
      cardholderFirstName: PropTypes.string.isRequired,
      cardholderFullName: PropTypes.string.isRequired,
      contactEmail: PropTypes.string.isRequired,
      estimatedArrival: estimatedArrivalShape.isRequired,
      firstCardOrder: PropTypes.bool.isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      openModal: true,
    };

    bindAll(this);
  }

  onDismiss() {
    this.setState({ openModal: false });
  }

  renderBody() {
    const { contactEmail, estimatedArrival, firstCardOrder } = this.props;
    const styles = { borderBottom: '1px solid #ddd', paddingBottom: 13, display: 'inline-block' };
    return (
      <div className="align-center">
        <p style={styles}>
          {possessiverize(this.props.cardholderFullName)} card is estimated to arrive by{' '}
          {estimatedArrival.arrival_date}.<br />
          {firstCardOrder && (
            <span style={{ color: '#666', fontSize: 'smaller', fontStyle: 'italic' }}>
              We will contact you at {contactEmail} if we have any questions about your order, which
              might impact the estimated arrival date.
            </span>
          )}
        </p>
        <div style={styles}>
          Interested in ordering more True Link Cards? Click {this.orderAdditionalCard()}
        </div>
        <div style={{ paddingTop: 13 }}>
          <div style={{ paddingBottom: 10 }}>
            Ready to set up {this.props.cardholderFullName}'s True Link Card?
          </div>
          You can connect a bank account to fund the card and set up the Spending Monitor. For more
          information on how to use the online dashboard to configure the True Link Card, please
          check out our videos{' '}
          <a
            href="https://www.truelinkfinancial.com/other/resources"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </div>
      </div>
    );
  }

  orderAdditionalCard() {
    return (
      <form action={RailsRoutes.add_account_path()} method="POST" style={{ display: 'inline' }}>
        <input
          className="cancel"
          style={{ padding: 0, lineHeight: '22px', verticalAlign: 'top' }}
          type="submit"
          value="here."
        />
      </form>
    );
  }

  render() {
    if (!this.state.openModal) return null;

    return (
      <GenericSubmitPopUp
        headerText={`${possessiverize(
          this.props.cardholderFirstName,
        )} True Link Card has been ordered!`}
        messageNode={this.renderBody()}
        onSubmit={this.onDismiss}
        popupProps={{ maxWidth: '570px', paddingTop: '0px', hideDismissButton: true }}
        submitButtonProps={{ className: 'btn btn-default normal' }}
      />
    );
  }
}
