import PropTypes from 'prop-types';
import React from 'react';
import ToggleLine from './ToggleLine';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';

export default class AccessToCash extends React.Component {
  static get propTypes() {
    return {
      block_rules: PropTypes.object.isRequired,
      addBlockRules: PropTypes.func.isRequired,
      removeBlockRules: PropTypes.func.isRequired,
    };
  }

  render() {
    const { block_rules, addBlockRules, removeBlockRules } = this.props;
    let maxCashVal, maxCashChecked;

    if (block_rules['maxTransactionSizeCash'] === undefined) {
      maxCashVal = '0';
      maxCashChecked = false;
    } else {
      maxCashVal = block_rules['maxTransactionSizeCash'].toString();
      maxCashChecked = true;
    }

    return (
      <div>
        <ToggleLine
          is_checked={!!block_rules['blockATM']}
          name="blockATM"
          rules={{ blockATM: true }}
          text="Cash withdrawals at ATMs"
          updateChecked={addBlockRules}
          updateUnChecked={removeBlockRules}
        />
        <ToggleLine
          is_checked={!!block_rules['blockSignatureCash']}
          name="blockSignatureCash"
          rules={{ blockSignatureCash: true }}
          text="Cash withdrawals inside a bank"
          updateChecked={addBlockRules}
          updateUnChecked={removeBlockRules}
        />
        <ToggleLine
          is_checked={!!block_rules['blockPOSCash']}
          name="blockPOSCash"
          rules={{ blockPOSCash: true }}
          text="Cash-back at time of purchase"
          updateChecked={addBlockRules}
          updateUnChecked={removeBlockRules}
        />
        <CheckboxLine
          isChecked={maxCashChecked}
          name="maxTransactionSizeCash"
          text2="Block cash withdrawals over&nbsp;&nbsp;&nbsp;$"
          textfield={maxCashVal}
          updateChecked={addBlockRules}
          updateUnChecked={removeBlockRules}
        />
      </div>
    );
  }
}
