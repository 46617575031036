import PropTypes from 'prop-types';
import React from 'react';
import _filter from 'underscore/modules/filter';
import _isEmpty from 'underscore/modules/isEmpty';
import _map from 'underscore/modules/map';
import _reduce from 'underscore/modules/reduce';
import DashboardProcessDateInput from './DashboardProcessDateInput';
import DashboardBudgetItemCategories from 'react/member/components/dashboard/budget_items/DashboardBudgetItemCategories';
import FrequencyInputs from 'react/member/components/dashboard/disbursements/FrequencyInputs';
import PostageType from 'react/member/components/dashboard/disbursements/PostageType';
import DashboardPayee from 'react/member/components/dashboard/payees/DashboardPayee';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemAttachmentStore from 'react/member/stores/DashboardBudgetItemAttachmentStore';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import childrenShape from 'react/shared/shapes/children';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardNewBudgetItem extends React.Component {
  constructor(props) {
    super(props);

    let disbursementCategories;
    if (this.buildInitialDisbursementCategories()) {
      disbursementCategories = this.props.categories;
    } else {
      disbursementCategories = [this.blankCategory()];
    }
    this.state = {
      uploading: false,
      postageCode: 1,
      budgetItemType: this.props.budgetItemType,
      disbursementCategories,
      totalPayments: this.calculateTotalPayments(disbursementCategories),
      selectedCardIsOpen: false,
      nonClosedCards: [],
    };

    this.categoryFields = ['amounts', 'memos', 'disbursement_categories'];

    bindAll(this);
  }

  componentDidMount() {
    DashboardBudgetItemAttachmentStore.on('attachment.uploading', this.whileUploading);
    DashboardBudgetItemAttachmentStore.on('attachment.upload', this.finishedUploading);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      this.setCategories(this.props.categories);
    }
    if (this.props.currentPaymentMethod === 'Card') {
      if (prevProps.currentPaymentMethod !== this.props.currentPaymentMethod) {
        if (this.props.currentClient !== null) {
          const client = DashboardClientStore.get(this.props.currentClientSlug);
          let nonClosedCards;
          if (client) {
            nonClosedCards = _filter(client.cards, (card) => card.status !== 'CLOSED');
          } else {
            nonClosedCards = [];
          }
          this.setState({ nonClosedCards });
          if (nonClosedCards.length > 0) {
            this.setState({
              selectedCardIsOpen: nonClosedCards[0]?.status === 'OPEN',
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    DashboardBudgetItemAttachmentStore.off('attachment.uploading', this.whileUploading);
    DashboardBudgetItemAttachmentStore.off('attachment.upload', this.finishedUploading);
  }

  whileUploading() {
    this.setState({ uploading: true });
  }

  finishedUploading(attachment) {
    if (attachment) {
      DashboardBudgetItemAttachmentStore.addAttachment(attachment);
    }
    this.setState({ uploading: false });
  }

  hiddenFieldForTrustBeneficiary() {
    return (
      <input name="trust_beneficiary_id" type="hidden" value={this.props.currentClientSlug || ''} />
    );
  }

  buildInitialDisbursementCategories() {
    return this.props.categories && this.props.categories.length;
  }

  // This is a method instead of a property, otherwise we end up referencing the same object multiple times
  blankCategory() {
    return {
      amounts: null,
      memos: '',
      disbursement_categories: '',
      attachments: [],
    };
  }

  addCategory(e) {
    e.stopPropagation();
    return this.setState((prevState) =>
      prevState.disbursementCategories.push(this.blankCategory()),
    );
  }

  destroyCategory(e) {
    e.stopPropagation();
    e.preventDefault();
    const categories = this.state.disbursementCategories;
    // Ensure we always have at least one category
    if (categories.length > 1) {
      const index = e.target.attributes['data-index'].value;
      categories.splice(index, 1);
      return this.setCategories(categories);
    }
  }

  handleSelectedCard(e) {
    const selectedCardStatus = this.state.nonClosedCards.find(
      (card) => card.slug === e.target.value,
    )?.status;
    this.setState({ selectedCardIsOpen: selectedCardStatus === 'OPEN' });
  }

  handleChange(e) {
    let categories;
    const { value, attributes, name } = e.target;

    if (Array.from(this.categoryFields).includes(name)) {
      const index = attributes['data-index'].value;
      categories = this.state.disbursementCategories;
      categories[index][name] = value;

      this.setCategories(categories);
    }

    if (name === 'budget_item_type') {
      categories =
        value === 'recurring'
          ? [this.state.disbursementCategories[0]]
          : this.state.disbursementCategories;

      this.setCategories(categories);
    }

    if (name === 'payment_method') {
      categories =
        value === 'Card' || value === 'Wire'
          ? [this.state.disbursementCategories[0]]
          : this.state.disbursementCategories;

      return this.setCategories(categories);
    }
  }

  setCategories(categories) {
    return this.setState({
      disbursementCategories: categories,
      totalPayments: this.calculateTotalPayments(categories),
    });
  }

  calculateTotalPayments(categories) {
    return _reduce(categories, (memo, category) => memo + Number(category['amounts']), 0).toFixed(
      2,
    );
  }

  multiCategoryEligible() {
    if (this.props.budgetItemType === 'recurring') {
      return false;
    }
    if (
      !['Check', 'EFT', 'External Check', 'Direct Debit'].includes(this.props.currentPaymentMethod)
    ) {
      return false;
    }
    return true;
  }

  cardDropdown() {
    if (this.props.currentPaymentMethod === 'Card') {
      let cards;
      if (this.state.nonClosedCards.length > 0) {
        cards = _map(this.state.nonClosedCards, (card) => (
          <option data-card={card.status} key={card.slug} value={card.slug}>
            {card.name}
          </option>
        ));
      } else {
        cards = [];
      }
      const selectCard = (() => {
        if (_isEmpty(cards)) {
          const uri = window.location.href.split('#')[0];
          const link = `${uri}?reload=1#client-card-tab`;

          return (
            <div className="new-form__callout new-form__callout--warning">
              {"There are no True Link Cards linked to this beneficiary's account."}
              <br />
              Please click <a href={link}>here</a> to link a True Link Card to this beneficiary.
            </div>
          );
        }
        return (
          <select name="card_id" onChange={this.handleSelectedCard}>
            {cards}
          </select>
        );
      })();

      return (
        <div>
          <div className="new-form__label">
            <label htmlFor="card_id">Card</label>
          </div>
          <div className="new-form__data">{selectCard}</div>
        </div>
      );
    }
  }

  handleBudgetItemTypeChange(ev) {
    this.props.setBudgetItemType(ev);
    if (this.props.clearOverdrawError) {
      this.props.clearOverdrawError();
    }
    return this.setState({ budgetItemType: ev.target.value });
  }

  budgetItemTypes(oneTimeChecked) {
    if (['Direct Debit', 'Wire'].includes(this.props.currentPaymentMethod)) {
      if (this.state.budgetItemType !== 'one_time') {
        this.setState({ budgetItemType: 'one_time' });
      }
      return (
        <div className="clearfix">
          <div className="new-form__label" style={{ marginBottom: 10 }}>
            Schedule
          </div>
          <div className="new-form__data" style={{ marginBottom: 10 }}>
            <div className="radio-group radio-group--inline">
              <label htmlFor="one_time_budget_item_type">
                <input
                  checked={oneTimeChecked}
                  id="one_time_budget_item_type"
                  name="budget_item_type"
                  onChange={this.handleBudgetItemTypeChange}
                  type="radio"
                  value="one_time"
                />
                One-Time
              </label>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="clearfix">
        <div className="new-form__label" style={{ marginBottom: 10 }}>
          Schedule
        </div>
        <div className="new-form__data" style={{ marginBottom: 10 }}>
          <div className="radio-group radio-group--inline">
            <label htmlFor="one_time_budget_item_type">
              <input
                checked={oneTimeChecked}
                id="one_time_budget_item_type"
                name="budget_item_type"
                onChange={this.handleBudgetItemTypeChange}
                type="radio"
                value="one_time"
              />
              One-Time
            </label>
            <label htmlFor="recurring_budget_item_type">
              <input
                checked={!oneTimeChecked}
                id="recurring_budget_item_type"
                name="budget_item_type"
                onChange={this.handleBudgetItemTypeChange}
                type="radio"
                value="recurring"
              />
              Recurring
            </label>
          </div>
        </div>
      </div>
    );
  }

  postageForCheckDisbursement() {
    if (this.props.currentPaymentMethod === 'Check') {
      return (
        <div className="new-form__section">
          <PostageType codeCallback={this.setPostageCode} />
        </div>
      );
    }
  }

  setPostageCode(event) {
    this.setState({ postageCode: event.target.value });
  }

  budgetItemInputs(oneTimeChecked, budgetItemTypes) {
    const cardNotOpen =
      this.props.currentPaymentMethod === 'Card' && !this.state.selectedCardIsOpen;
    const processDateInput =
      oneTimeChecked === true ? (
        <DashboardProcessDateInput
          cardIsOpen={this.state.selectedCardIsOpen}
          currentPaymentMethod={this.props.currentPaymentMethod}
          customDateChecked={this.props.formData.custom_date || cardNotOpen || false}
          deliveryDate={this.props.formData.delivery_date}
          orgSlug={this.props.organizationSlug}
        />
      ) : (
        <div className="new-form__data__subsection">
          <FrequencyInputs formData={this.props.formData} subclass="disbursement" />
        </div>
      );

    return (
      <div>
        {this.cardDropdown()}
        {budgetItemTypes}
        {processDateInput}
      </div>
    );
  }

  disbursementAgreementText(oneTimeChecked) {
    if (oneTimeChecked) {
      return (
        <div className={'new-form__section'}>
          <p>
            By clicking “Submit”, you authorize True Link to complete the transaction described
            above, and you certify that you are authorized to initiate transactions from this
            account.
          </p>
        </div>
      );
    } else if (!oneTimeChecked) {
      return (
        <div className={'new-form__section'}>
          <p>
            By clicking "Submit," you authorize True Link to complete the transaction described
            above, and you certify that you are authorized to initiate transactions from this
            account.
          </p>
          <p>
            You understand this authorization will remain in effect until you have removed this
            authorization in such time and in such manner as to allow True Link a reasonable
            opportunity to act on it. You also understand that if corrections on the posted amount
            are necessary, it may involve an adjustment (credit or debit) to your account(s).
          </p>
        </div>
      );
    }
  }

  renderOverdrawError() {
    if (!_isEmpty(this.props.overdrawError)) {
      const errorClass = this.props.overdrawError.urgent
        ? 'alert alert--banner alert-error'
        : 'alert alert-banner alert-warning';
      return (
        <div className="new-form__section nopadding" style={{ textAlign: 'center' }}>
          <div className={errorClass}>{this.props.overdrawError.text}</div>
        </div>
      );
    }
  }

  handleDraftSubmit(ev) {
    this.props.handleSubmit(ev, true);
  }

  toggleAndClearForm() {
    let disbursementCategories;
    if (this.buildInitialDisbursementCategories()) {
      disbursementCategories = this.props.categories;
    } else {
      disbursementCategories = [this.blankCategory()];
    }

    this.setState(
      {
        uploading: false,
        postageCode: 1,
        budgetItemType: this.props.budgetItemType,
        disbursementCategories,
        totalPayments: this.calculateTotalPayments(disbursementCategories),
        formData: {},
      },
      this.props.toggleBudgetItemForm,
    );
  }

  render() {
    let shippingOptions;
    const { budgetItemType } = this.props;
    const oneTimeChecked = budgetItemType === 'one_time';
    const multiCategory = this.multiCategoryEligible();
    const budgetItemTypesHTML = this.budgetItemTypes(oneTimeChecked);
    const inputs = this.budgetItemInputs(oneTimeChecked, budgetItemTypesHTML);
    if (oneTimeChecked) {
      shippingOptions = this.postageForCheckDisbursement();
    }
    const header = 'Create Disbursement';
    const submitButtonText = 'Submit Disbursement Request';

    const totalAmount = asMoney(this.state.totalPayments);

    // Currently only supporting the creation of one-time DraftDisbursements.
    // 'Save As Draft' button rendered only if budgetItemType === 'one_time'
    // (i.e. oneTimeCheck === true).
    let secondaryActionButton;
    if (oneTimeChecked) {
      secondaryActionButton = (
        <button className="btn normal fleft" onClick={this.handleDraftSubmit} type="button">
          Save As Draft
        </button>
      );
    } else {
      secondaryActionButton = (
        <button
          className="btn btn-default cancel nopadding fleft"
          href="#"
          onClick={this.toggleAndClearForm}
          style={{ marginTop: 6 }}
          type="button"
        >
          Cancel
        </button>
      );
    }

    return (
      <div className="new-budget-item-form" id="new_budget_item_form">
        {this.props.showDashboardPayeeForm && (
          <DashboardPayee
            className="dashboard-new-payee"
            clientSlug={this.props.currentClientSlug}
            displayedPaymentMethods={this.props.displayedPaymentMethods}
            onClose={this.props.closePayeeForm}
            paymentMethod={this.props.currentPaymentMethod}
            userRoles={this.props.userRoles}
          />
        )}
        <div className="sub_section_header">
          <h1>{header}</h1>
          <div className="sub_section_header__right">
            <a
              className="btn-link"
              href="#"
              onClick={this.toggleAndClearForm}
              style={{ marginRight: 10 }}
            >
              Cancel
            </a>
          </div>
          <div className="clearfix"> </div>
        </div>
        <div className="new-budget-item-form__body">
          <form
            id="budget_item_form"
            method="post"
            onChange={this.handleChange}
            onSubmit={this.props.handleSubmit}
          >
            {this.props.children}
            <div className="new-form__section">
              {inputs}
              {this.hiddenFieldForTrustBeneficiary()}
            </div>
            {shippingOptions}
            <DashboardBudgetItemCategories
              addCategoryFunc={this.addCategory}
              categories={this.state.disbursementCategories}
              clearOverdrawError={this.props.clearOverdrawError}
              currentPaymentMethod={this.props.currentPaymentMethod}
              destroyCategoryFunc={this.destroyCategory}
              formData={this.props.formData}
              multiCategory={multiCategory}
              organizationSlug={this.props.organizationSlug}
            />
            {this.disbursementAgreementText(oneTimeChecked)}
            {this.renderOverdrawError()}
            <div className="new-form__section__footer">
              {secondaryActionButton}
              <TrueLinkButton
                className="fright"
                disabled={this.state.uploading == true || this.props.overdrawError.urgent}
                type="submit"
                variant="primary"
              >
                {submitButtonText}
              </TrueLinkButton>
              <div className="fright" style={{ marginTop: 6, marginRight: 25 }}>
                <span className="bold">Total Amount:&nbsp;</span>
                <span>{totalAmount}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

DashboardNewBudgetItem.propTypes = {
  formData: PropTypes.object.isRequired,
  budgetItemType: PropTypes.string.isRequired,
  children: childrenShape.isRequired,
  currentPaymentMethod: PropTypes.string.isRequired,
  toggleBudgetItemForm: PropTypes.func.isRequired,
  setBudgetItemType: PropTypes.func.isRequired,
  // For disbursements
  showDashboardPayeeForm: PropTypes.bool,
  closePayeeForm: PropTypes.func,
  initialClient: PropTypes.shape({
    trust: PropTypes.shape({
      is_pooled: PropTypes.bool,
    }),
  }),
  organizationSlug: PropTypes.string,
  currentClientSlug: PropTypes.string,
  currentClient: PropTypes.object,
  displayedPaymentMethods: PropTypes.array,
  overdrawError: PropTypes.object,
  handleSubmit: PropTypes.func,
  clearOverdrawError: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
  userRoles: userRoleShape.isRequired,
};

DashboardNewBudgetItem.defaultProps = {
  overdrawError: {},
};
