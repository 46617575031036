import PropTypes from 'prop-types';
import React from 'react';

export default class PendingMerchantSettings extends React.Component {
  createPendingRuleLine(pending_rule) {
    let setting;
    const rules = pending_rule.rules;
    if (rules && Object.prototype.hasOwnProperty.call(rules, 'denylistMerchants')) {
      setting = 'BLOCK';
    } else if (rules && Object.prototype.hasOwnProperty.call(rules, 'allowlistMerchants')) {
      setting = 'ALLOW';
    } else {
      setting = 'TBD';
    }

    if (setting != 'TBD') {
      return (
        <div key={pending_rule.merchant_name} style={{ paddingBottom: 15 }}>
          <div className="pending_rule">{setting}</div>
          {pending_rule.merchant_name}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div style={{ marginTop: '14px' }}>
        {this.props.pending_rules.map(this.createPendingRuleLine)}
        <hr />
      </div>
    );
  }
}

PendingMerchantSettings.propTypes = {
  pending_rules: PropTypes.array.isRequired,
};
