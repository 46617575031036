import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import WmDashboardClientSelect from 'react/member/components/dashboard/WmDashboardClientSelect';
import WmDashboardNav from 'react/member/components/dashboard/WmDashboardNav';
import { getTrustBeneficiaryAnalysis } from 'react/member/components/dashboard/clients/dashboard_client/overview/api';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  backLinkWrapper: {
    fontWeight: 400,
    color: PALETTE.grey2,
    marginBottom: theme.spacing(4),
  },
  backLinkIcon: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
  backLinkLink: {
    color: PALETTE.grey2,
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  // Individual bene overview header
  rightHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  assetLabel: {
    color: PALETTE.grey2,
    fontSize: '1.1rem',
  },
  tooltipLink: {
    borderBottom: `1px dashed ${PALETTE.grey3}`,
  },
}));

export default function IndividualTrustBeneficiaryOverviewHeader({
  client,
  organization,
  trustBeneficiaries,
  orgTrustBenesJson,
}) {
  const classes = useStyles();
  const [balanceValues, setBalanceValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const analysisRes = await getTrustBeneficiaryAnalysis(client.slug, [
          'investmentSum',
          'availableCashSum',
        ]);
        setBalanceValues({
          securities: analysisRes.investmentSum.amount,
          availableCash: analysisRes.availableCashSum.amount,
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [client.slug]);

  const handleBack = () => {
    let clientsRoute;
    if (organization) {
      clientsRoute = RailsRoutes.dashboard_organization_clients_path(organization.slug);
    } else {
      clientsRoute = RailsRoutes.dashboard_clients_path();
    }
    window.location.href = clientsRoute;
  };

  return (
    <WmDashboardNav>
      <Typography className={classes.backLinkWrapper}>
        <TrueLinkLink className={classes.backLinkLink} onClick={handleBack}>
          <TrueLinkIcon className={classes.backLinkIcon} icon="chevron-left" />
          Back to Home
        </TrueLinkLink>
      </Typography>
      <div className={classes.header}>
        {/* Left header */}
        <div className={classes.leftHeader}>
          <Typography component="h1" variant="h2">
            {client.person.name}
          </Typography>
        </div>
        {/* Balances / right (middle) header */}
        <div className={classes.rightHeader}>
          <div
            style={{
              marginRight: '26px',
              minWidth: '6rem',
            }}
          >
            <TrueLinkTooltip
              tooltipContent={
                <Typography>
                  Investments minus your cash balance - so only marketable securities, such as
                  equities and fixed income.
                </Typography>
              }
            >
              <Typography
                className={`${classes.assetLabel} ${classes.tooltipLink}`}
                component="span"
              >
                Securities
              </Typography>
            </TrueLinkTooltip>
            <Typography>
              {loading ? (
                <Skeleton variant="text" />
              ) : (
                tlFieldTransformers.formatMoney(balanceValues?.securities)
              )}
            </Typography>
          </div>
          <div
            style={{
              marginRight: '37px',
              minWidth: '6rem',
            }}
          >
            <TrueLinkTooltip
              tooltipContent={
                <Typography>
                  Your current cash balance minus approved and processed disbursements.
                </Typography>
              }
            >
              <Typography
                className={`${classes.assetLabel} ${classes.tooltipLink}`}
                component="span"
              >
                Available Cash
              </Typography>
            </TrueLinkTooltip>
            <Typography>
              {loading ? (
                <Skeleton variant="text" />
              ) : (
                tlFieldTransformers.formatMoney(balanceValues?.availableCash)
              )}
            </Typography>
          </div>
        </div>
        {/* Right header */}
        <div className={classes.rightHeader}>
          <WmDashboardClientSelect
            client={client}
            orgTrustBenesJson={orgTrustBenesJson}
            organization={organization}
            trustBeneficiaries={trustBeneficiaries}
          />
        </div>
      </div>
    </WmDashboardNav>
  );
}

IndividualTrustBeneficiaryOverviewHeader.propTypes = {
  client: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    person: PropTypes.shape({ name: PropTypes.string }).isRequired,
  }).isRequired,
  organization: PropTypes.object,
  orgTrustBenesJson: PropTypes.bool,
  trustBeneficiaries: PropTypes.array,
};
