import { useQuery } from '@tanstack/react-query';
import { PropTypes } from 'prop-types';
import { getTrustBeneficiaryAnalysis } from 'react/member/components/dashboard/clients/dashboard_client/overview/api';

export default function useTrustBeneficiaryAnalysisQuery({ clientSlug }) {
  const result = useQuery(['trustBeneficiaryAnalysis', clientSlug], {
    queryFn: () =>
      getTrustBeneficiaryAnalysis(clientSlug, [
        'canHaveDisbursementRequested',
        'canHaveCashRaiseRequested',
        'canAddCardForBene',
        'investmentSum',
        'availableCashSum',
        'uniqueAssetsSum',
        'approvedDisbursements',
        'approvedDisbursementsSum',
        'processedDisbursementsSum',
      ]),
  });

  return { ...result };
}

useTrustBeneficiaryAnalysisQuery.propTypes = {
  clientSlug: PropTypes.string.isRequired,
};
