import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import _map from 'underscore/modules/map';
import DashboardActions from 'react/member/actions/dashboard_actions';
import CustomCheckbox from 'react/shared/card/components/CustomCheckbox';
import GenericRadioInput from 'react/shared/components/forms/GenericRadioInput';
import GenericSelect from 'react/shared/components/forms/GenericSelect';
import GenericTextInput from 'react/shared/components/forms/GenericTextInput';
import GenericTextarea from 'react/shared/components/forms/GenericTextarea';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';
import { callAPI, getUrl } from 'react/shared/utils/call_api';

export default class SpecificMerchantSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.cleanState();

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('merchantNameClusters.fetch', this.handleMerchantNameClustersFetch);
    DashboardActions.fetchMerchantNameClusters();
  }

  componentDidUpdate() {
    if ($('#select_cluster').data('chosen')) {
      $('#select_cluster').trigger('chosen:updated');
    } else {
      $('#select_cluster')
        .chosen()
        .change((e) => {
          this.selectClusterChanged(e);
        });
    }
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('merchantNameClusters.fetch', this.handleMerchantNameClustersFetch);
  }

  cleanState() {
    return {
      customMerchantRequest: false,
      selectedMerchantCluster: null,
      newRuleSetting: null,
      maxAmount: null,
      formReady: false,
      errors: [],
      hideErrorMessage: true,
    };
  }

  selectClusterChanged(event) {
    const update = { selectedMerchantCluster: event.target.value };
    this.updateFormReady(update);
  }

  updateFormReady(updates) {
    const batchedUpdates = _extend({ formReady: this.isformReady() }, updates);
    this.setState(batchedUpdates);
  }

  handleMerchantNameClustersFetch() {
    const update = {
      merchantNameClusters: DashboardRulesetStore.getMerchantNameClusters(),
    };
    this.updateFormReady(update);
  }

  customMerchantRequestChange(event) {
    const update = { customMerchantRequest: event.target.checked };
    this.updateFormReady(update);
  }

  maxAmountChange(event) {
    const update = { maxAmount: event.target.value };
    this.updateFormReady(update);
  }

  newRuleChange(event) {
    if (event.target.checked) {
      const update = { newRuleSetting: event.target.value };
      this.updateFormReady(update);
    }
  }

  customMerchantNameChange(event) {
    const update = { customMerchantName: event.target.value };
    this.updateFormReady(update);
  }

  customMerchantInfoChange(event) {
    let val = event.target.value;
    if (event.keyCode === 13) {
      val += '\n';
      event.target.value = val;
    }
    const update = { customMerchantInfo: val };
    this.updateFormReady(update);
  }

  isformReady() {
    this.setState({ hideErrorMessage: true });
    const errors = [];
    let merchantInfoReady;
    if (
      this.state.customMerchantRequest &&
      this.state.customMerchantName &&
      this.state.customMerchantInfo
    ) {
      merchantInfoReady = true;
    } else if (this.state.selectedMerchantCluster && !this.state.customMerchantRequest) {
      merchantInfoReady = true;
    } else {
      errors.push('Please complete the merchant name and merchant info fields.');
      merchantInfoReady = false;
    }

    let validSetting;
    if (this.state.newRuleSetting === 'max_transaction' && this.state.maxAmount) {
      validSetting = true;
    } else if (this.state.newRuleSetting === 'allow' || this.state.newRuleSetting === 'block') {
      validSetting = true;
    } else {
      errors.push('Please pick a setting for this merchant.');
      validSetting = false;
    }

    this.setState({ errors });

    return merchantInfoReady && validSetting;
  }

  submitForm() {
    if (this.isformReady()) {
      // custom rule, so we'll POST to custom_merchant_request with denylistMerchant/allowlistMerchant rule type
      let data, newRule, url;
      const maxTxRule = {};

      if (this.state.customMerchantRequest) {
        const ruleRequest = {
          merchant_name: this.state.customMerchantName,
          description: this.state.customMerchantInfo,
        };
        const name = this.state.customMerchantName;
        switch (this.state.newRuleSetting) {
          case 'block':
            newRule = { denylistMerchants: name };
            maxTxRule[name] = '';
            break;
          case 'allow':
            newRule = { allowlistMerchants: this.state.customMerchantName };
            maxTxRule[name] = '';
            break;
          case 'max_transaction':
            newRule = { allowlistMerchants: this.state.customMerchantName };
            maxTxRule[name] = this.state.maxAmount;
            break;
        }

        newRule['maxTransactionSizeByMerchant'] = maxTxRule;
        ruleRequest['rules'] = newRule;
        data = { rule_request: ruleRequest };

        url = getUrl('custom_merchant_request', this.props.accountId);
      } else if (this.state.selectedMerchantCluster) {
        const cluster = this.state.selectedMerchantCluster;
        switch (this.state.newRuleSetting) {
          case 'block':
            newRule = { denylistMerchantClusters: cluster };
            maxTxRule[cluster] = '';
            break;
          case 'allow':
            newRule = { allowlistMerchantClusters: cluster };
            maxTxRule[cluster] = '';
            break;
          case 'max_transaction':
            newRule = { allowlistMerchantClusters: cluster };
            maxTxRule[cluster] = this.state.maxAmount;
            break;
        }

        newRule['maxTransactionSizeByMerchantCluster'] = maxTxRule;

        data = newRule;

        url = getUrl('add_block_rules', this.props.accountId);
      } else {
        return false;
      }

      callAPI(url, data, null).then(() => {
        this.setState(this.cleanState());
        this.props.closeModal();
        DashboardActions.fetchRulesetInfo(this.props.accountId);
      });
    } else {
      this.setState({ hideErrorMessage: false });
    }
  }

  generateErrorMessage() {
    return this.state.errors.map((err, index) => <div key={index}>{err}</div>);
  }

  addMerchantText() {
    if (this.state.customMerchantRequest) {
      return (
        <tr className="custom_inputs">
          <td />
          <td>
            <hr style={{ margin: '15px 0px 10px' }} />
            <p style={{ fontSize: '14px' }}>
              With a little more information, we'll add that merchant for you. Just provide the
              merchant name and any other information you might have (e.g., address, phone number,
              website). You can also add merchant settings directly by clicking "Review" next to any
              transaction on the Transactions tab.
            </p>
          </td>
        </tr>
      );
    }
  }

  addMerchantInput() {
    if (!this.state.customMerchantRequest) return;
    return (
      <tr className="custom_inputs">
        <td />
        <td>
          <hr style={{ margin: '10px 0px 20px' }} />
          <table>
            <tbody>
              <tr>
                <td style={{ width: '135px', verticalAlign: 'top' }}>
                  <label htmlFor="merchant_name">Merchant name:</label>
                </td>
                <td style={{ paddingBottom: '15px' }}>
                  <GenericTextInput
                    id="merchant_name"
                    name="merchant_name"
                    onChange={this.customMerchantNameChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: '135px', verticalAlign: 'top' }}>
                  <label htmlFor="merchant_description">Merchant info:</label>
                </td>
                <td>
                  <GenericTextarea
                    id="merchant_description"
                    name="description"
                    onKeyUp={this.customMerchantInfoChange}
                    style={{
                      width: '470px',
                      height: '62px',
                      marginBottom: '0',
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  optionList() {
    const options = _map(this.state.merchantNameClusters, (merchantName, i) => (
      <option key={i} value={merchantName}>
        {' '}
        {merchantName}{' '}
      </option>
    ));
    return (
      <GenericSelect id="select_cluster" name="select_cluster">
        <option key={'blank'} value="">
          {' '}
          Select from list{' '}
        </option>
        {options}
      </GenericSelect>
    );
  }

  modalContent() {
    return (
      <form className="specific-settings-form">
        <table className="form-table" style={{ tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td style={{ width: '120px' }}>Merchant:</td>
              <td style={{ paddingBottom: '15px' }}>
                <label htmlFor="select_cluster">{this.optionList()}</label>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="checkbox_label">
                  <CustomCheckbox
                    checked={this.state.customMerchantRequest}
                    name={'custom_merchant_request'}
                    onChange={this.customMerchantRequestChange}
                    ref="checkbox"
                  />
                  <span>The merchant I'm looking for isn't on the list!</span>
                </label>
              </td>
            </tr>
            {this.addMerchantText()}
            {this.addMerchantInput()}
          </tbody>
        </table>
        <hr style={{ margin: '20px 0px' }} />
        <table className="form-table">
          <tbody>
            <tr>
              <td style={{ width: '120px' }}>Setting:</td>
              <td style={{ width: '350px', paddingBottom: '5px' }}>
                <label className="radio_label" htmlFor="new_rule_setting_block">
                  <GenericRadioInput
                    handleChange={this.newRuleChange}
                    id="new_rule_setting_block"
                    name="new_rule_setting"
                    value="block"
                  />
                  &nbsp; Block all transactions
                </label>
              </td>
            </tr>
            <tr>
              <td />
              <td style={{ paddingBottom: '5px' }}>
                <label className="radio_label" htmlFor="new_rule_setting_allow">
                  <GenericRadioInput
                    handleChange={this.newRuleChange}
                    id="new_rule_setting_allow"
                    name="new_rule_setting"
                    value="allow"
                  />
                  &nbsp; Allow all transactions
                </label>
              </td>
            </tr>
            <tr>
              <td />
              <td style={{ paddingBottom: '5px' }}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="radio_label">
                  <GenericRadioInput
                    handleChange={this.newRuleChange}
                    name="new_rule_setting"
                    value="max_transaction"
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label style={{ display: 'inline' }}>
                    &nbsp; Allow all transactions less than&nbsp;&nbsp;&nbsp;$&nbsp;
                    <input
                      className="checkbox_textfield"
                      min="1"
                      onChange={this.maxAmountChange}
                      size="5"
                      step="1"
                      type="number"
                      value={this.state.maxAmount || ''}
                    />
                  </label>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }

  render() {
    const header = 'Add merchant setting';
    const footer = (
      <div>
        <TrueLinkButton onClick={this.submitForm} variant="primary">
          Add setting
        </TrueLinkButton>
      </div>
    );

    return (
      <div>
        <PopUp
          bannerMessage={this.generateErrorMessage()}
          cancel={this.props.closeModal}
          footer={footer}
          header={header}
          maxWidth="780px"
          minBodyHeight="260px"
          modalOpen={this.props.modalOpen}
          showBanner={!this.state.hideErrorMessage}
        >
          {this.modalContent()}
        </PopUp>
      </div>
    );
  }
}

SpecificMerchantSettingsModal.propTypes = {
  accountId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};
