import axios from 'axios';
import DashboardActions from 'react/member/actions/dashboard_actions';

const TransactionAttachmentStore = flux.createStore({
  actions: [
    DashboardActions.fetchTransactionAttachmentsByTransaction,
    DashboardActions.destroyTransactionAttachment,
  ],

  fetchTransactionAttachmentsByTransaction(cardTransactionId) {
    axios
      .get(
        RailsRoutes.api_v2_attachments_path({
          attachableId: cardTransactionId,
          attachableType: 'CardTransaction',
          format: 'json',
        }),
      )
      .then((response) => {
        this.emit('attachments.fetch', cardTransactionId, response.data.data);
      });
  },

  destroyTransactionAttachment(attachmentId, cardTransactionId) {
    const path = RailsRoutes.api_v2_attachment_path(attachmentId, {
      format: 'json',
    });
    axios.delete(path).then(() => {
      this.emit('attachments.destroy');
      this.fetchTransactionAttachmentsByTransaction(cardTransactionId);
    });
  },
  exports: {
    uploadAttachmentToTransaction(fileInput, cardTransactionId) {
      const formData = new window.FormData();
      formData.append(fileInput.name, fileInput.files[0]);
      fileInput.value = '';
      this.emit('attachment.uploading');

      const url = RailsRoutes.api_v2_attachments_path({
        format: 'json',
        attachableId: cardTransactionId,
        attachableType: 'CardTransaction',
      });
      axios
        .post(url, formData)
        .then((response) => {
          const attachment = response.data;
          this.emit('attachment.upload', attachment);
          this.fetchTransactionAttachmentsByTransaction(cardTransactionId);
        })
        .catch(() => {
          Truelink.flash(
            'error',
            'An error occurred while uploading the attachment. Please check the file size is less than 25MB.',
          );
          this.emit('attachment.upload', null);
        });
    },
  },
});

export default TransactionAttachmentStore;
