import PropTypes from 'prop-types';
import React from 'react';
import programNameShape from 'react/shared/shapes/account_program_name_shape';

function SpendingMonitorBanners(props) {
  const handleRemoveAllowOverride = () => {
    const message = `Transactions on this ${props.programName} card will no longer be allowed by default. All other spending monitor settings have returned to their previous state.`;

    props.removeBlockRules({ allowAllTransactions: true }, null, () => {
      Truelink.flash('success', message, false);
    });
  };

  const handleRemoveBlockOverride = () => {
    const message = `Transactions on this ${props.programName} card will no longer be blocked by default. All other spending monitor settings have returned to their previous state.`;

    props.removeBlockRules({ blockAllTransactions: true }, null, () => {
      Truelink.flash('success', message, false);
    });
  };

  let banner = null;

  if (props.allowOverrideEnabled) {
    banner = (
      <div className="allow_all_banner above_overlay">
        You have temporarily <b>allowed</b> all transactions! This overrides your custom card
        settings below and permits the cardholder to complete any purchases less than or equal to
        the balance on the card. To turn off this temporary allow setting,&nbsp;
        <a href="#" onClick={handleRemoveAllowOverride}>
          click here
        </a>
        .
      </div>
    );
  } else if (props.blockOverrideEnabled) {
    banner = (
      <div className="block_all_banner above_overlay">
        You have temporarily <b>blocked</b> all transactions! This overrides your custom card
        settings below and prevents the cardholder from completing any purchases. To turn off this
        temporary block setting, &nbsp;
        <a href="#" onClick={handleRemoveBlockOverride}>
          click here
        </a>
        .
      </div>
    );
  }

  return (
    <div>
      {banner}
      <br />
      {banner ? <div className="disabling_overlay" /> : null}
    </div>
  );
}

SpendingMonitorBanners.propTypes = {
  allowOverrideEnabled: PropTypes.bool.isRequired,
  blockOverrideEnabled: PropTypes.bool.isRequired,
  programName: programNameShape.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
};

export default SpendingMonitorBanners;
