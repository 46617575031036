import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import _filter from 'underscore/modules/filter';
import DeprecatedDashboardTransactionRow from './DeprecatedDashboardTransactionRow';
import DeprecatedDashboardWealthAccountEventsExport from './DeprecatedDashboardWealthAccountEventsExport';
import bindAll from 'react/shared/utils/bind_all';

export default class DeprecatedDashboardWealthAccountEventsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: null,
      dateTo: null,
      currentOpenTransaction: null,
    };
    this.dateFormat = 'MM/DD/YYYY';

    bindAll(this);
  }

  componentDidMount() {
    const config = {
      minDate: '-1Y',
      maxDate: '+1Y',
      dateFormat: 'mm/dd/yy',
    };
    const fromConfig = {
      onSelect: (date) => {
        // make sure dateTo is larger
        let dateFrom;
        if (this.state.dateTo) {
          dateFrom = moment(date, this.dateFormat);
          let dateTo = moment(this.state.dateTo, this.dateFormat);
          if (dateTo.isBefore(dateFrom)) {
            dateTo = dateFrom;
          }
          return this.setState({
            dateFrom: dateFrom.format(this.dateFormat),
            dateTo: dateTo.format(this.dateFormat),
          });
        }
        return this.setState({ dateFrom: date });
      },
    };

    const toConfig = {
      onSelect: (date) => this.setState({ dateTo: date }),
    };
    $('#date_range_from').datepicker(_extend(fromConfig, config));
    $('#date_range_to').datepicker(_extend(toConfig, config));
  }

  transactionList() {
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    const transactions = _filter(this.props.transactions, (transaction) => {
      if (!!dateFrom && !!dateTo) {
        const { dateFormat } = this;
        dateFrom = moment(dateFrom, dateFormat);
        dateTo = moment(dateTo, dateFormat);
        // datePosted is in ISO format
        const datePosted = moment(transaction.date_posted);
        return (
          (datePosted.isSame(dateFrom) || datePosted.isAfter(dateFrom)) &&
          (datePosted.isSame(dateTo) || datePosted.isBefore(dateTo))
        );
      }
      return true;
    });
    if (transactions.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={5}>
              No transactions have been completed in this account. Once a transaction is completed,
              it will appear in this section.
            </td>
          </tr>
        </tbody>
      );
    }
    return transactions.map((transaction, key) => (
      <DeprecatedDashboardTransactionRow
        closeTransactionDetails={this.closeTransactionDetails}
        currentOpenTransaction={this.state.currentOpenTransaction}
        key={`${key} investment`}
        openTransactionDetails={this.openTransactionDetails}
        transaction={transaction}
      />
    ));
  }

  openTransactionDetails(transactionId) {
    this.setState({ currentOpenTransaction: transactionId });
  }

  closeTransactionDetails() {
    this.setState({ currentOpenTransaction: null });
  }

  transactionHeader() {
    return (
      <tr>
        <th>Date</th>
        <th>Description</th>
        <th>Category</th>
        <th className="table-cell-right">Amount</th>
        <th className="table-cell-right">Details</th>
      </tr>
    );
  }

  onFromChange(evt) {
    return this.setState({ dateFrom: evt.target.value });
  }

  onToChange(evt) {
    return this.setState({ dateTo: evt.target.value });
  }

  render() {
    const { clientId, clientName } = this.props;
    const onClick = () => this.setState({ dateFrom: null, dateTo: null });
    const showAll =
      this.state.dateFrom && this.state.dateTo ? (
        <button
          className="btn btn-default cancel"
          onClick={onClick}
          style={{ lineHeight: 'normal' }}
          type="button"
        >
          Show all
        </button>
      ) : undefined;
    return (
      <div>
        <div style={{ paddingTop: 50, height: 100 }}>
          <label htmlFor="date_range_from" style={{ display: 'inline-block' }}>
            <span style={{ paddingRight: 5 }}>From:</span>
            <input
              className="datepicker"
              id="date_range_from"
              name="date_range_from"
              onChange={this.onFromChange}
              placeholder="mm/dd/yyyy"
              type="text"
              value={this.state.dateFrom || ''}
            />
          </label>
          <label
            htmlFor="date_range_to"
            style={{ display: 'inline-block', paddingLeft: 10, paddingRight: 5 }}
          >
            <span style={{ paddingLeft: 10, paddingRight: 5 }}>To:</span>
            <input
              className="datepicker"
              id="date_range_to"
              name="date_range_to"
              onChange={this.onToChange}
              placeholder="mm/dd/yyyy"
              type="text"
              value={this.state.dateTo || ''}
            />
          </label>
          {showAll}
          <span className="pull-right">
            <DeprecatedDashboardWealthAccountEventsExport
              clientId={clientId}
              clientName={clientName}
            />
          </span>
        </div>
        <div className="sub_section_header" style={{ textAlign: 'left' }}>
          Completed Transactions
        </div>
        <table className="table table-hover">
          <thead>{this.transactionHeader()}</thead>
          {this.transactionList()}
        </table>
      </div>
    );
  }
}

DeprecatedDashboardWealthAccountEventsIndex.propTypes = {
  transactions: PropTypes.array.isRequired,
  clientId: PropTypes.string,
  clientName: PropTypes.string,
};
