import PropTypes from 'prop-types';
import React from 'react';
import SpendingMonitor from 'react/shared/card/components/spending_monitor_tab/SpendingMonitor';

export default function CardInInvestSpendingMonitorTabContainer({ accountId, canEdit }) {
  return <SpendingMonitor accountId={accountId} canEdit={canEdit} />;
}

CardInInvestSpendingMonitorTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
