import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AccountReceipts from './account_receipts/AccountReceipts';
import api from './api.js';
import MonthlyStatements from './monthly_statements/MonthlyStatements';
import BlockedChargesReportSection from 'react/shared/card/components/reports_tab/blocked_charges_report/BlockedChargesReportSection';
import TransactionReportSection from 'react/shared/card/components/reports_tab/transaction_report/TransactionReportSection';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import { isAbleActAccount, isRepPayee } from 'react/shared/utils/account_card_program';

export default function CardInInvestReportsTabContainer({ accountId, canEdit }) {
  const [account, setAccount] = useState(null);
  const [monthlyStatementDates, setMonthlyStatementDates] = useState(null);

  useEffect(() => {
    let isMounted = true;
    async function getAccountApi() {
      const accountRes = await api.getAccount(accountId);
      const statementsRes = await api.getStatements(accountId);

      if (isMounted) {
        setAccount(accountRes.data.data);
        setMonthlyStatementDates(statementsRes.data);
      }
    }
    getAccountApi();
    // cleaning the state on unmount
    return () => {
      isMounted = false;
      setAccount(null);
      setMonthlyStatementDates(null);
    };
  }, [accountId]);

  if (!account || !monthlyStatementDates) return <LoadingIndicator />;

  const { cardProgramNumber } = account.attributes;
  const { showAccountReceipts } = account.meta;

  const enableBlockedChargesReport = !isRepPayee(cardProgramNumber);
  const enableReceipts = showAccountReceipts && !isAbleActAccount(cardProgramNumber);

  return (
    <div className="text-left">
      <div id="dashboard_reports">
        <TransactionReportSection accountId={accountId} isCardholder={false} />
        {enableBlockedChargesReport && <BlockedChargesReportSection accountId={accountId} />}
        <MonthlyStatements
          isCardholder={false}
          monthlyStatementDates={monthlyStatementDates}
          showIncludeReceiptsCheckbox={enableReceipts}
        />
        {enableReceipts && <AccountReceipts accountId={accountId} canEdit={canEdit} />}
      </div>
    </div>
  );
}

CardInInvestReportsTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};
