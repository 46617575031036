import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { bulkRulesetChangeShape } from './BulkOperationsShape';
import { formatRuleDisplay } from 'react/member/utils/Ruleset';
import bindAll from 'react/shared/utils/bind_all';

export default class SpendingMonitorDetails extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  deleteMerchantSetting() {
    this.props.toggleDeleteMerchantSettings();
  }

  addNewMerchantSetting() {
    this.props.closeDetailsModal();
    this.props.openAccountMerchantSettingsModal({ prePopulate: true });
  }

  render() {
    const { bulkRulesetChange, showMonitorDetails } = this.props;

    if (!showMonitorDetails) return <div />;

    return (
      <div>
        <div className="new-form--compact">
          <div className="new-form-field">
            <div className="new-form__label" htmlFor="date">
              Date:
            </div>
            <div className="new-form__data" name="date">
              {moment(bulkRulesetChange.applied_at).format('YYYY-MM-DD, LT')}
            </div>
          </div>

          <div className="new-form-field">
            <div className="new-form__label" htmlFor="merchant">
              Merchant:
            </div>
            <div className="new-form__data" name="merchant">
              {bulkRulesetChange.merchant}
            </div>
          </div>

          <div className="new-form-field">
            <div className="new-form__label" htmlFor="setting">
              Setting:
            </div>
            <div className="new-form__data" name="setting">
              <div>{formatRuleDisplay(bulkRulesetChange)}</div>
              {bulkRulesetChange.apply_to_default_spending_monitor && (
                <p>Added to default Spending Monitor</p>
              )}
            </div>
          </div>

          <div className="new-form-field">
            <div className="new-form__label" htmlFor="cards">
              Cards:
            </div>
            <div className="new-form__data" name="cards">
              <p>{bulkRulesetChange.accounts.length} cards</p>
            </div>
          </div>
          <div
            style={{
              borderTop: '1px solid #aaa',
              marginTop: -3,
              borderBottom: '1px solid #ddd',
              maxHeight: 230,
              overflow: 'auto',
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Account ID</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {bulkRulesetChange.accounts.map((account) => (
                  <tr key={account.account_id}>
                    <td>{account.cardholder_first_name}</td>
                    <td>{account.cardholder_last_name}</td>
                    <td>{account.nickname}</td>
                    <td className="align-right">
                      <a
                        href={RailsRoutes.select_account_path({ id: account.slug })}
                        rel="noreferrer"
                        target="_blank"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div className="new-form-field">
            <div className="new-form__label" htmlFor="actions">
              Actions:
            </div>
            <div className="new-form__data" name="actions" style={{ margin: 0 }}>
              {bulkRulesetChange.rule != 'delete all merchant settings' && (
                <div style={{ marginBottom: 5 }}>
                  <a
                    onClick={this.deleteMerchantSetting}
                    onKeyUp={this.deleteMerchantSetting}
                    role="link"
                    tabIndex="0"
                  >
                    Delete this merchant setting on these cards
                  </a>
                </div>
              )}
              <div>
                <a
                  onClick={this.addNewMerchantSetting}
                  onKeyUp={this.addNewMerchantSetting}
                  role="link"
                  tabIndex="0"
                >
                  Add a new merchant setting for these cards
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SpendingMonitorDetails.propTypes = {
  bulkRulesetChange: bulkRulesetChangeShape,
  closeDetailsModal: PropTypes.func.isRequired,
  openAccountMerchantSettingsModal: PropTypes.func.isRequired,
  showMonitorDetails: PropTypes.bool.isRequired,
  toggleDeleteMerchantSettings: PropTypes.func.isRequired,
};
