import PropTypes from 'prop-types';
import React from 'react';
import { bulkRulesetChangeShape } from './BulkOperationsShape';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { capitalize } from 'react/shared/utils/Strings';

export default function DeleteSettingsConfirmation({
  bulkRulesetChange,
  onDeleteConfirmation,
  showDeleteSettings,
}) {
  const numberOfAccounts = bulkRulesetChange.accounts.length;
  return (
    <div>
      {showDeleteSettings && (
        <div className="new-form--compact">
          <div className="new-form-field">
            <div className="new-form__label">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Merchant:</label>
            </div>
            <div className="new-form__data">{bulkRulesetChange.merchant}</div>
          </div>
          <div className="new-form-field">
            <div className="new-form__label">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Setting to delete:</label>
            </div>
            <div className="new-form__data">
              {capitalize(bulkRulesetChange.rule)}
              {bulkRulesetChange.apply_to_default_spending_monitor && (
                <div>Delete this merchant setting from default spending monitor</div>
              )}
            </div>
          </div>
          <div
            style={{
              borderTop: '1px solid #ddd',
              margin: '15px 0 5px',
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <div className="info-box--with-icon--no-background" style={{ marginBottom: 0 }}>
              Spending monitor rules matching the above setting will be deleted from the{' '}
              {numberOfAccounts} accounts.
            </div>
          </div>
          <div
            className="clearfix"
            style={{
              margin: '0 -20px -10px',
              padding: '15px 20px 0',
              borderTop: '1px solid #ddd',
              textAlign: 'right',
            }}
          >
            <TrueLinkButton
              className="btn btn-success"
              onClick={onDeleteConfirmation}
              variant="none"
            >
              Delete setting from {numberOfAccounts} account{numberOfAccounts > 1 ? 's' : ''}
            </TrueLinkButton>
          </div>
        </div>
      )}
    </div>
  );
}

DeleteSettingsConfirmation.propTypes = {
  bulkRulesetChange: bulkRulesetChangeShape,
  onDeleteConfirmation: PropTypes.func.isRequired,
  showDeleteSettings: PropTypes.bool.isRequired,
};
