import PropTypes from 'prop-types';
import React from 'react';
import TransactionReport from './TransactionReport';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function TransactionReportSection(props) {
  return (
    <div id="transaction_report">
      <SubSectionHeader first>Transaction Report</SubSectionHeader>
      <div className="account_info_section">
        <p>Generate a report of transactions on this card for any time period:</p>
        <TransactionReport accountId={props.accountId} isCardholder={props.isCardholder} />
      </div>
    </div>
  );
}

TransactionReportSection.propTypes = {
  accountId: PropTypes.string.isRequired,
  isCardholder: PropTypes.bool.isRequired,
};
