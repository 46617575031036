import PropTypes from 'prop-types';
import React from 'react';
import OldAttachCard from './OldAttachCard';
import OldDashboardCardHeader from './OldDashboardCardHeader';
import CardInInvestAccountTabContainer from 'react/member/card/components/account_tab/CardInInvestAccountTabContainer';
import CardInInvestAlertsTabContainer from 'react/member/card/components/alerts_tab/CardInInvestAlertsTabContainer';
import CardInInvestReportsTabContainer from 'react/member/card/components/reports_tab/CardInInvestReportsTabContainer';
import CardInInvestSpendingMonitorTabContainer from 'react/member/card/components/spending_monitor_tab/CardInInvestSpendingMonitorTabContainer';
import CardInInvestTransactionsTabContainer from 'react/member/card/components/transactions_tab/CardInInvestTransactionsTabContainer';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import bindAll from 'react/shared/utils/bind_all';

export default class OldDashboardCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardClientStore.on('client.linkCard', this.reload);
  }

  componentWillUnmount() {
    DashboardClientStore.off('client.linkCard', this.reload);
  }

  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  clientHasCards() {
    return this.props.client.cards && this.props.client.cards.length > 0;
  }

  isRepPayee() {
    return this.props.client.cards && this.props.client.cards.slice(-1)[0].account.is_rep_payee;
  }

  isViewOnly() {
    return this.props.userRoles.viewOnly;
  }

  tabList() {
    const spendingMonitorTab = {
      id: 'wm-spending-monitor-tab',
      title: 'Spending Monitor',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const alertsTab = {
      id: 'wm-alerts-tab',
      title: 'Alerts',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const reportsTab = {
      id: 'wm-reports-tab',
      title: 'Reports',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const transactionsTab = {
      id: 'wm-transactions-tab',
      title: 'Transactions',
      className: 'btn btn-secondary',
      hideHref: true,
    };
    const accountTab = {
      id: 'wm-account-tab',
      title: 'Account',
      className: 'btn btn-secondary',
      hideHref: true,
    };

    return [
      this.isRepPayee() ? null : spendingMonitorTab,
      this.isRepPayee() ? null : alertsTab,
      reportsTab,
      transactionsTab,
      this.isViewOnly() ? null : accountTab,
    ].filter((tab) => tab !== null);
  }

  reload() {
    window.location.reload(true);
  }

  render() {
    if (!this.clientHasCards()) {
      return (
        <OldAttachCard client={this.props.client} organization={this.props.client.organization} />
      );
    }

    const account = this.props.client.accounts[0];
    const accountId = account.slug;
    const repPayee = this.isRepPayee();
    const viewOnly = this.isViewOnly();

    return (
      <div style={{ textAlign: 'center', marginTop: '25px' }}>
        <OldDashboardCardHeader accountId={accountId} client={this.props.client} />
        <TabNavigation
          classOptions={'btn-group'}
          disableHash
          initialTabIndex={0}
          style={{ marginBottom: 20 }}
          tabs={this.tabList()}
        >
          {!repPayee && (
            <TabContent>
              <CardInInvestSpendingMonitorTabContainer accountId={accountId} canEdit={!viewOnly} />
            </TabContent>
          )}
          {!repPayee && (
            <TabContent>
              <CardInInvestAlertsTabContainer accountId={accountId} canEdit={!viewOnly} />
            </TabContent>
          )}
          <TabContent>
            <CardInInvestReportsTabContainer accountId={accountId} canEdit={!viewOnly} />
          </TabContent>
          <TabContent>
            <CardInInvestTransactionsTabContainer accountId={accountId} />
          </TabContent>
          {!viewOnly && (
            <TabContent>
              <CardInInvestAccountTabContainer
                accountId={accountId}
                personName={this.props.client.person.name}
              />
            </TabContent>
          )}
        </TabNavigation>
      </div>
    );
  }
}

OldDashboardCard.propTypes = {
  client: PropTypes.object,
  userRoles: userRoleShape.isRequired,
};
