import PropTypes from 'prop-types';
import addressShape from 'react/shared/shapes/address_shape';

const { bool, string, shape } = PropTypes;

export const organizationShape = shape({
  address: addressShape,
  address_as_string: string,
  name: string,
  parentOrganizationAddress: addressShape,
  professional: bool.isRequired,
});
