import PropTypes from 'prop-types';
import React from 'react';
import IndividualTrustTable from './IndividualTrustTable';
import PortfolioList from 'react/member/components/dashboard/portfolios/PortfolioList';
import DashboardNewPortalUserForm from 'react/member/components/dashboard/trust_beneficiaries/DashboardNewPortalUserForm';
import DashboardNewTrustBeneficiaryForm from 'react/member/components/dashboard/trust_beneficiaries/DashboardNewTrustBeneficiaryForm';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import { isRestrictedViewUser } from 'react/shared/utils/Authorization';
import bindAll from 'react/shared/utils/bind_all';

export default class TrustList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      portfolioCounts: {},
    };

    bindAll(this);
  }
  setPortfolioCount(count, trustId) {
    const { portfolioCounts } = this.state;
    portfolioCounts[trustId] = count;

    this.setState({ portfolioCounts });
  }

  pooledTrustList() {
    const trustList = this.props.trusts
      .filter((trust) => trust.is_pooled)
      .map((trust) => {
        const showNewBeneficiaryLink = this.props.updatedClientsIndexTrustsJson
          ? trust.portfolios.length > 0
          : this.state.portfolioCounts[trust.id];
        const newBeneficiaryLink = showNewBeneficiaryLink ? (
          <div className="pull-right">
            <DashboardNewTrustBeneficiaryForm
              allowBeneDisbursementRequestsByDefault={
                trust.organization.allow_bene_disbursement_requests_by_default
              }
              fee={trust.organization.trust_setup_fee}
              object={{ person: {}, portfolios: [], trust }}
            />
          </div>
        ) : undefined;

        return (
          <div key={trust.id} style={{ marginBottom: 15 }}>
            <div className="sub_section_header__subhead">
              {trust.name}
              {newBeneficiaryLink}
            </div>

            <PortfolioList
              setPortfolioCount={this.setPortfolioCount}
              trust={trust}
              updatedClientsIndexTrustsJson={this.props.updatedClientsIndexTrustsJson}
            />
          </div>
        );
      });

    if (!trustList.length) {
      return;
    }

    const newPortalUserLink =
      this.props.userRoles.managesSubaccounts && this.props.showNewPortalUserInvite === true ? (
        <div className="pull-right">
          <DashboardNewPortalUserForm orgSlug={this.props.orgSlug} />
        </div>
      ) : undefined;

    return (
      <div>
        <SubSectionHeader noMarginBottom>
          Pooled Trusts
          {newPortalUserLink}
        </SubSectionHeader>
        {trustList}
      </div>
    );
  }

  individualTrustList() {
    const individualTrusts = this.props.trusts.filter((trust) => !trust.is_pooled);

    if (!individualTrusts.length) {
      return;
    }

    return (
      <div id="dashboard-clients-individual-trusts-list">
        <SubSectionHeader style={{ borderBottom: 'none' }}>Standalone Accounts</SubSectionHeader>
        <IndividualTrustTable trusts={individualTrusts} />
      </div>
    );
  }

  render() {
    if (isRestrictedViewUser()) {
      return null;
    }
    return (
      <div style={{ marginBottom: 25 }}>
        {this.pooledTrustList()}
        {this.individualTrustList()}
      </div>
    );
  }
}

TrustList.propTypes = {
  trusts: PropTypes.array.isRequired,
  showNewPortalUserInvite: PropTypes.bool,
  orgSlug: PropTypes.string,
  updatedClientsIndexTrustsJson: PropTypes.bool.isRequired,
  userRoles: userRoleShape.isRequired,
};
