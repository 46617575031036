import PropTypes from 'prop-types';
import React from 'react';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';

export default function PurchaseSpendingLimit(props) {
  const block_rules = props.block_rules;
  let max_tx_size_val;
  let max_tx_size_checked;
  if (block_rules['maxTransactionSize'] === undefined) {
    max_tx_size_val = '250';
    max_tx_size_checked = false;
  } else {
    max_tx_size_val = block_rules['maxTransactionSize'].toString();
    max_tx_size_checked = true;
  }

  return (
    <div>
      <CheckboxLine
        id="blockMaxTransactionSize"
        isChecked={max_tx_size_checked}
        name="maxTransactionSize"
        text2="Block transactions over&nbsp;&nbsp;&nbsp;$"
        textfield={max_tx_size_val}
        updateChecked={props.addBlockRules}
        updateUnChecked={props.removeBlockRules}
      />
    </div>
  );
}

PurchaseSpendingLimit.propTypes = {
  addBlockRules: PropTypes.func.isRequired,
  block_rules: PropTypes.object.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
};
