// Used in accounts and users views. Handles change events in customer_service_note fields and makes an ajax call to update the db
$(document).ready(function () {
  $('.customer_service_note').each(function (idx, elem) {
    elem = $(elem);
    var prev_val = elem.html();
    elem.on('blur', function () {
      if (elem.html() == prev_val) return;
      prev_val = elem.html();
      $.post(
        elem.attr('data-href'),
        {
          _method: elem.attr('data-method'),
          format: 'json',
          'customer_service_note[note_content]': elem.html(),
        },
        function (response) {
          elem.attr('data-href', response.path || elem.attr('data-base-href'));
          elem.attr('data-method', response.path ? 'put' : 'post'); // Switches between PUT and POST http requests depending on whether its a new note or an update
          elem.css('opacity', 0);
          elem.animate(
            {
              opacity: 1,
            },
            200,
          );
        },
      );
    });
  });
});

// Used in rulesets view. Copies one ruleset to many
$(document).ready(function () {
  // Copy
  $('.ruleset_copy').on('click', function () {
    $('.ruleset_copy_targets').show();
    $('.ruleset_copy').hide();
    $(this).parent().find('.ruleset_copy_activated').show();
    $(this).parent().find('.ruleset_copy_targets').hide();
    return false; // Returning false prevents the page from jerking back up to the top on click
  });

  // Cancel
  $('.ruleset_copy_cancel').on('click', function () {
    $('.ruleset_copy_targets').removeAttr('checked');
    $('.ruleset_copy_activated').hide();
    $('.ruleset_copy_targets').hide();
    $('.ruleset_copy').show();
    return false;
  });

  // Submit
  $('.ruleset_copy_submit').on('click', function () {
    var id = $(this).closest('td').attr('id'); // Get id of ruleset to be copied
    var myTargets = $('.ruleset_copy_targets:checked');
    var myTargetIds = [];
    for (var i = 0; i < myTargets.length; i++) {
      myTargetIds.push(myTargets[i].dataset.rulesetId); // Create array of rulesets to be copied to
    }

    if (myTargetIds.length === 0) return false;

    var confirm = window.confirm(
      'Are you sure you want to overwrite the selected rulesets? This action can not be undone.' +
        '\n\n' +
        'Base ruleset: ' +
        id +
        '\n\n' +
        'Target rulesets: ' +
        myTargetIds,
    );

    if (confirm !== true) return false;

    $.ajax({
      url: 'rulesets/' + id + '/copy',
      type: 'POST',
      data: { target_ruleset_ids: myTargetIds },
    })
      .then(function (data, textStatus, jqXHR) {
        // Set back to normal
        $('.ruleset_copy_targets').removeAttr('checked');
        $('.ruleset_copy_activated').hide();
        $('.ruleset_copy_targets').hide();
        $('.ruleset_copy').show();
        Truelink.flash('success', 'Ruleset copied successfully!', false);
        window.location.reload();
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        Truelink.flashClear(); // Clear default js flash message
        Truelink.flash('error', 'Unexpected error', false);
      });
  });
});

// Used in transfers 'open requests' view to handle clicks of Send Text and Mark Complete buttons
$(document).ready(function () {
  // mark any form to submit with ajax
  $('.flash_on_ajax_response').on('ajax:success', function (e, data, status, xhr) {
    // $form = $(this).closest("form");
    $(this).css('opacity', 0).animate(
      {
        opacity: 1,
      },
      200,
    );
  });
});

// Handles ajaxy stuff on the users page
$(document).ready(function () {
  // Multiple accounts checkbox
  $('#checkbox_multiple_accounts').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $('#multiple_accounts_success_text').text('');

    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      data: {
        organization: { multiple_accounts_flag: checked },
      },
    }).done(function () {
      $('#multiple_accounts_success_text').text('UPDATED').css('color', 'green');
    });
  });

  $.each($('p.allowed-card-programs > input'), function (index, el) {
    $(el).change(function () {
      $('#card_program_success_text').text('');
      var $this = $(this);
      var $notificationArea = $('#card_program_success_text');
      var checked = $this.is(':checked') ? 1 : 0;
      var paramHash = { card_programs: {} };

      paramHash['card_programs'][$this.attr('name')] = checked;

      $.ajax({
        url: $this.attr('data-form-path'),
        type: 'PUT',
        data: {
          organization: paramHash,
        },
      }).done(function () {
        $notificationArea.text('UPDATED!').css('color', 'green');
        window.setTimeout(function () {
          $notificationArea.text('');
        }, 3000);
      });
    });
  });

  $('.gov-benefits-checkbox').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $('#government_benefits_success_text').text('');
    var o = {};
    o[$(this).val()] = checked;

    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      format: 'json',
      data: {
        organization: { government_benefit_id: o },
      },
    }).done(function () {
      $('#government_benefits_success_text').text('UPDATED').css('color', 'green');
    });
  });

  $('#super_approver_checkbox').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      data: {
        user: { user_settings: { disbursement_approver: checked } },
      },
    }).done(function () {
      $('#super_approver_success_text').text('UPDATED').css('color', 'green');
    });
  });

  // Allow ach fees checkbox
  $('#checkbox_ach_fees').change(function () {
    var checked = $(this).is(':checked') ? 1 : 0;
    $('#ach_fees_success_text').text('');

    $.ajax({
      url: $(this).attr('data-form-path'),
      type: 'PUT',
      data: {
        organization: { show_ach_fees: checked },
      },
    }).done(function () {
      $('#ach_fees_success_text').text('UPDATED').css('color', 'green');
    });
  });
});

$(document).ready(function () {
  if ($('#organization_select').length) {
    $('#organization_select').select2({
      ajax: {
        url: RailsRoutes.users_organization_select_path(),
        cache: false,
        dataType: 'json',
      },
      allowClear: true,
      placeholder: '(None)',
    });
    $('#organization_select').next().css('margin-left', '30px');
  }
});
