import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

export default function CardNotPresentExceptionsModal({ rule, onModalClose, onOkay }) {
  const ruleText = rule
    .replace('UTILITIES', 'Utilities')
    .replace('MEDICAL_AND_CARE', 'Medical and Care')
    .replace('FOOD_RESTAURANT', 'Restaurant delivery')
    .replace('PHONE_TV_CABLE', 'Phone, TV, and Cable');

  return (
    <PopUp
      footer={
        <TrueLinkButton onClick={onOkay} variant="primary">
          Okay
        </TrueLinkButton>
      }
      header={`You have chosen to always allow payments for ${ruleText}`}
      maxWidth="600px"
      onClose={onModalClose}
      openModal
    >
      {`If you select to allow payments for ${ruleText} here, the block you put in place on payments for ${ruleText} in the spending categories section below will be removed. Are you sure that's what you want?`}
    </PopUp>
  );
}

CardNotPresentExceptionsModal.propTypes = {
  onOkay: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  rule: PropTypes.string.isRequired,
};
