import PropTypes from 'prop-types';
import React from 'react';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';

function BlockedTransactionAlerts({ notificationPrefs, onUpdatePrefs, showClosedCardCheckbox }) {
  return (
    <div className="alert-checkbox-container">
      <CheckboxLine
        isChecked={!!notificationPrefs['transactions']}
        name="transactions"
        text1="Blocked transaction: "
        text2="Alert me about all attempted transactions blocked by the Spending Monitor"
        updateChecked={onUpdatePrefs}
        updateUnChecked={onUpdatePrefs}
      />
      <CheckboxLine
        isChecked={!!notificationPrefs['InsufficientFunds']}
        name="InsufficientFunds"
        text1="Insufficient funds: "
        text2="Alert me if a transaction is declined because there were not enough funds on the card"
        updateChecked={onUpdatePrefs}
        updateUnChecked={onUpdatePrefs}
      />
      <CheckboxLine
        isChecked={!!notificationPrefs['InvalidPin']}
        name="InvalidPin"
        text1="Invalid PIN: "
        text2="Alert me if a transaction is declined because an invalid PIN was used"
        updateChecked={onUpdatePrefs}
        updateUnChecked={onUpdatePrefs}
      />
      <CheckboxLine
        isChecked={!!notificationPrefs['i2c_block']}
        name="i2c_block"
        text1="Declined card: "
        text2="Alert me if a transaction is declined for any other reason"
        updateChecked={onUpdatePrefs}
        updateUnChecked={onUpdatePrefs}
      />
      {showClosedCardCheckbox && (
        <div style={{ marginLeft: '65px' }}>
          <CheckboxLine
            isChecked={!!notificationPrefs['closed_card_i2c_block']}
            name="closed_card_i2c_block"
            text1="Closed cards: "
            text2="Alert me on transactions on closed cards"
            updateChecked={onUpdatePrefs}
            updateUnChecked={onUpdatePrefs}
          />
        </div>
      )}
    </div>
  );
}

BlockedTransactionAlerts.propTypes = {
  notificationPrefs: PropTypes.object.isRequired,
  onUpdatePrefs: PropTypes.func.isRequired,
  showClosedCardCheckbox: PropTypes.bool.isRequired,
};

export default BlockedTransactionAlerts;
