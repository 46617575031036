import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DashboardAccount from 'react/member/components/dashboard/clients/dashboard_client/cards/DashboardAccount';
import { getTrustBeneficiary } from 'react/member/components/dashboard/clients/dashboard_client/overview/api';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(3),
  },
  backLinkWrapper: {
    fontWeight: 400,
    color: PALETTE.grey2,
    marginBottom: theme.spacing(4),
  },
  backLinkIcon: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
  backLinkLink: {
    color: PALETTE.grey2,
  },
  loadingIndicator: {
    display: 'block',
    margin: 'auto',
  },
}));

export default function AccountPage({ accountId, beneficiaryId, userRoles }) {
  const classes = useStyles();
  const handleBackToOverview = () =>
    (window.location.href = RailsRoutes.dashboard_client_path(beneficiaryId));
  const [trustBeneficiary, setTrustBeneficiary] = useState(null);
  const [loading, setLoading] = useState(true);

  const queryClient = new QueryClient();

  useEffect(() => {
    async function fetchBeneData() {
      try {
        const res = await getTrustBeneficiary(beneficiaryId);
        setTrustBeneficiary(res);
      } finally {
        setLoading(false);
      }
    }
    fetchBeneData();
  }, [beneficiaryId]);

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            {loading ? (
              <CircularProgress className={classes.loadingIndicator} />
            ) : (
              <>
                <Typography className={classes.backLinkWrapper}>
                  <TrueLinkLink className={classes.backLinkLink} onClick={handleBackToOverview}>
                    <TrueLinkIcon className={classes.backLinkIcon} icon="chevron-left" />
                    Back to {trustBeneficiary.firstName}'s Dashboard
                  </TrueLinkLink>
                </Typography>
                <DashboardAccount
                  client={trustBeneficiary}
                  selectedAccountId={accountId}
                  userRoles={userRoles}
                />
              </>
            )}
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

AccountPage.propTypes = {
  beneficiaryId: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  userRoles: userRoleShape.isRequired,
};
