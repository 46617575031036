import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

export default function SubAccountQuarterlyStatementSection({
  onSubmitReportRequest,
  quarterlyStatementDates,
  reportType,
}) {
  const [quarterlyStatementDate, setQuarterlyStatementDate] = useState('all');

  const onQuarterlyStatementDateChange = (event) => {
    setQuarterlyStatementDate(event.target.value);
  };

  const onClick = () => {
    const date =
      quarterlyStatementDate === 'all'
        ? 'all'
        : moment(quarterlyStatementDate).format('YYYY-MM-DD');
    const params = {
      report_type: 'Dashboard::GenerateTrustBeneficiariesQuarterlyStatement',
      quarterly_statement_date: date,
    };
    onSubmitReportRequest(params);
  };

  const options = Array.from(quarterlyStatementDates).map((date, idx) => (
    <option key={idx} value={date}>
      {date}
    </option>
  ));

  return (
    <div>
      <SubSectionHeader>Download Sub-Account Statements</SubSectionHeader>
      <p>Select a date to download all quarterly sub-account statements for this {reportType}</p>
      <div style={{ marginBottom: 40 }}>
        <select onChange={onQuarterlyStatementDateChange} style={{ marginBottom: 0 }}>
          <option value="all">All</option>
          {options}
        </select>
        <input
          className="btn normal"
          onClick={onClick}
          style={{ marginLeft: 10 }}
          type="submit"
          value="Open"
        />
      </div>
    </div>
  );
}

SubAccountQuarterlyStatementSection.propTypes = {
  onSubmitReportRequest: PropTypes.func.isRequired,
  quarterlyStatementDates: PropTypes.array,
  reportType: PropTypes.string.isRequired,
};
