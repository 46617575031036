import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import _map from 'underscore/modules/map';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import bindAll from 'react/shared/utils/bind_all';

export default class DatatableLite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    bindAll(this);
  }

  componentDidMount() {
    this.setState({ isLoading: false });
    this.initDatatable();
  }

  datatableNode() {
    return $(this.refs.datatableLite);
  }

  normalizedColumnDefs() {
    return _map(this.props.columnDefs, (columnDef) => _extend({ defaultContent: '' }, columnDef));
  }

  customButtonsFromProps() {
    if (this.props.customButtons) {
      return this.props.customButtons.map((button) => button);
    }
  }

  initDatatable() {
    // TODO -- Once we fix/uncouple 'search' with the account_list, set `serverSide: true` down below to push pagination
    // and searching there (to the server-side)...
    const table = this.datatableNode().DataTable({
      dom: this.props.dom,
      autoWidth: false,
      info: this.props.info,
      scrollCollapse: this.props.scrollCollapse,
      scrollY: this.props.scrollY,
      columnDefs: this.normalizedColumnDefs(),
      select: this.props.selectOption,
      paging: this.props.paging,
      order: this.props.initialSort,
      pageLength: this.props.pageLength,
      buttons: this.customButtonsFromProps(),
      language: {
        emptyTable: this.props.emptyTableText,
      },
      // eslint-disable-next-line no-dupe-keys
      select: {
        style: this.props.selectStyle,
      },
      createdRow: (row, data, _dataIndex) => {
        if (this.props.dataAccountId) {
          return $(row).attr('data-account-id', data.account_id);
        }
      },
      ajax: {
        url: this.props.route,
        data: (data) => _extend(data, { tl_datatable: true, paginate: false }),
      },
      initComplete: (_settings, _json) => {
        const datatable = $($.fn.dataTable.tables(true)).DataTable();
        datatable.columns.adjust(); // Fixes incorrect header calculation

        if (this.refs.datatableLite == undefined) {
          return;
        }
        // Enables preselection if toggling between components within AccountMerchantSettingsModal
        if (this.props.selected && this.props.selected != []) {
          const dt = this.datatableNode().DataTable();
          const accountIds = _map(this.props.selected, (rowData) => rowData.account_id);
          dt.rows().every((rowIndex) => {
            const chosenOne = dt.row(rowIndex);
            if (accountIds.includes($(chosenOne.node()).data('account-id'))) {
              chosenOne.select();
            }
          });
        }
      },
      drawCallback: (_settings) => {
        if (this.props.addEventListeners) {
          this.props.addEventListeners();
        }
      },
    });

    // Bind container component functions to Datatable
    // Function signature: function ( e, dt, type, indexes )
    if (this.props.onSelect) {
      table.on('select', this.props.onSelect);
    }
    if (this.props.onDeselect) {
      table.on('deselect', this.props.onDeselect);
    }
  }

  render() {
    const { isLoading } = this.state;
    const { classRef } = this.props;

    return (
      <div>
        {isLoading && <LoadingIndicator />}

        <div className={isLoading ? 'hidden' : ''}>
          <table
            className={classRef ? `table table-hover ${classRef}` : 'table table-hover'}
            ref="datatableLite"
          >
            <thead>
              <tr>{this.props.columns}</tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    );
  }
}

DatatableLite.propTypes = {
  addEventListeners: PropTypes.func,
  classRef: PropTypes.string,
  columnDefs: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  customButtons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  dataAccountId: PropTypes.bool,
  dom: PropTypes.string.isRequired,
  emptyTableText: PropTypes.string.isRequired,
  info: PropTypes.bool,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  pageLength: PropTypes.number,
  paging: PropTypes.bool,
  route: PropTypes.string.isRequired,
  scrollCollapse: PropTypes.bool,
  scrollY: PropTypes.string,
  selected: PropTypes.array,
  selectOption: PropTypes.bool,
  selectStyle: PropTypes.string.isRequired,
  initialSort: PropTypes.array.isRequired,
};

DatatableLite.defaultProps = {
  rowId: 'DT_RowId',
  pageLength: 10,
};
