import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import CustomCheckbox from 'react/shared/card/components/CustomCheckbox';
import SavedMessage from 'react/shared/components/SavedMessage';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';

export default function SpendingCurfew({ blockRules, addBlockRules, removeBlockRules }) {
  const dt = new Date();
  const currentMoment = moment(dt);

  const timeZoneName = moment.tz.guess(currentMoment);
  const timeZoneAbbr = moment.tz(timeZoneName).zoneAbbr();
  const ref = useRef();

  const [modal, setModal] = useState(null);
  const [curfewStartUTC, setCurfewStartUTC] = useState(blockRules.curfewStartUTC);
  const [curfewEndUTC, setCurfewEndUTC] = useState(blockRules.curfewEndUTC);
  const [isCurfewSet, setIsCurfewSet] = useState(!!(curfewStartUTC && curfewEndUTC));
  const timezoneOffset = dt.getTimezoneOffset() / 60;

  const getTimes = useCallback(() => {
    let i = 0;
    const times = {};
    let key, hour, suffix;

    while (i < 24) {
      switch (i) {
        case 0:
          key = 'Midnight';
          break;
        case 12:
          key = 'Noon';
          break;
        default:
          hour = Math.floor(i) % 12;
          suffix = i < 12 ? ' AM' : ' PM';
          key = `${hour.toString()}:00${suffix}`;
      }
      times[key] = (i + timezoneOffset) % 24;
      i++;
    }
    return times;
  }, [timezoneOffset]);

  useEffect(() => {
    if (!(blockRules.curfewStartUTC && blockRules.curfewEndUTC)) {
      const times = getTimes();

      setCurfewStartUTC(times['11:00 PM']);
      setCurfewEndUTC(times['6:00 AM']);
    }

    // This is only to provide default initial values, so we don't update it later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartTimeChanges = useCallback(
    (e) => {
      const raw_value = e.target.value;
      const real_value = raw_value ? Number.parseInt(raw_value, 10) : undefined;
      setCurfewStartUTC(real_value);
      if (isCurfewSet) {
        addBlockRules({ curfewStartUTC: real_value }, ref.current);
      }
    },
    [isCurfewSet, addBlockRules],
  );

  const handleEndTimeChanges = useCallback(
    (e) => {
      const raw_value = e.target.value;
      const real_value = raw_value ? Number.parseInt(raw_value, 10) : undefined;
      setCurfewEndUTC(real_value);
      if (isCurfewSet) {
        addBlockRules({ curfewEndUTC: real_value }, ref.current);
      }
    },
    [addBlockRules, isCurfewSet],
  );

  const setCurfew = useCallback(() => {
    addBlockRules(
      {
        curfewStartUTC,
        curfewEndUTC,
      },
      ref.current,
      () => {
        setIsCurfewSet(true);
        $('#confirmation-modal').modal('hide');
      },
    );
  }, [addBlockRules, curfewStartUTC, curfewEndUTC]);

  const removeCurfew = useCallback(() => {
    removeBlockRules(
      {
        curfewStartUTC: '',
        curfewEndUTC: '',
      },
      ref.current,
      () => {
        setIsCurfewSet(false);
        $('#confirmation-modal').modal('hide');
      },
    );
  }, [removeBlockRules]);

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, []);

  const showConfirmationModal = useCallback(() => {
    if (isCurfewSet) {
      const message = 'Are you sure you want to remove the curfew on this card?';
      setModal({
        message,
        actionText: 'Remove Curfew',
        handleSubmit: () => {
          removeCurfew();
          handleCloseModal();
        },
      });
    } else {
      const message =
        'During these hours all purchases on the card will be blocked, ' +
        'including emergency medical services. ' +
        "Please confirm that you are certain you'd like to set a curfew on this card.";
      setModal({
        message,
        actionText: 'Set Curfew',
        handleSubmit: () => {
          setCurfew();
          handleCloseModal();
        },
      });
    }
  }, [isCurfewSet, removeCurfew, setCurfew, handleCloseModal]);

  const times = getTimes();
  const selectOptions = Object.keys(times).map((k) => {
    if (Object.prototype.hasOwnProperty.call(times, k)) {
      return (
        <option key={times[k]} value={times[k]}>
          {k}
        </option>
      );
    }
  });

  return (
    <div className="spendingCurfew" ref={ref}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="checkbox_label">
        <CustomCheckbox
          checked={isCurfewSet}
          id="spending_curfew"
          onChange={showConfirmationModal}
        />
        Block purchases on this card from &nbsp;
        <select onChange={handleStartTimeChanges} value={curfewStartUTC}>
          {selectOptions}
        </select>
        &nbsp; to &nbsp;
        <select onChange={handleEndTimeChanges} value={curfewEndUTC}>
          {selectOptions}
        </select>
        <SavedMessage />
      </label>
      <div className="timezone-note" style={{ marginTop: 16 }}>
        <small>Note: These times are displayed in {timeZoneAbbr}</small>
      </div>
      {modal && (
        <AreYouSurePopUp
          dismissBtnText="Cancel"
          headerText="Are you sure?"
          messageText={modal.message}
          onClose={handleCloseModal}
          onSubmit={modal.handleSubmit}
          openModal
          popupProps={{ maxWidth: '800px', maxHeight: '800px' }}
          submitText={modal.actionText}
        />
      )}
    </div>
  );
}

SpendingCurfew.propTypes = {
  addBlockRules: PropTypes.func.isRequired,
  blockRules: PropTypes.object.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
};
