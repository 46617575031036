import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import DatatableLite from './DatatableLite';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class SelectAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFormErrors: false,
    };

    bindAll(this);
  }

  route() {
    return RailsRoutes.accounts_list_path({ hideClosedCards: 'true', format: 'json' });
  }

  columns() {
    return [
      <th className="js-datatabe-col-checkbox" key="datatable-checkbox" />,
      <th className="js-datatable-col-first-name" key="datatable-first-name">
        First name
      </th>,
      <th className="js-datatable-col-last-name" key="datatable-last-name">
        Last name
      </th>,
      <th className="js-datatable-col-nickname" key="datatable-nickname">
        Account ID
      </th>,
      <th className="js-datatable-col-status" key="datatable-status">
        Status
      </th>,
    ];
  }

  columnDefs() {
    return [
      {
        targets: 0,
        width: '5%',
        data: null,
        defaultContent: '',
        orderable: false,
        sortable: false,
        className: 'select-checkbox',
      },
      {
        targets: ['js-datatable-col-first-name'],
        width: '14%',
        sortable: false,
        data: 'first_name',
      },
      {
        targets: ['js-datatable-col-last-name'],
        width: '14%',
        sortable: false,
        data: 'last_name',
      },
      {
        targets: ['js-datatable-col-nickname'],
        width: '14%',
        sortable: false,
        data: 'nickname',
      },
      {
        targets: ['js-datatable-col-status'],
        width: '14%',
        sortable: false,
        data: 'status',
      },
    ];
  }

  onToggleSelection(e, dt) {
    const newElements = _map(dt.rows({ selected: true }).data(), (row) => row);
    this.props.handleSelectAccounts({
      selectedAccounts: newElements,
      selectedAccountCount: newElements.length,
    });
    const $span = $('button.select-all > span');
    if (dt.rows().count() == dt.rows({ selected: true }).count()) {
      $span.text('Deselect All');
      this.toggleAllAccountsSelect(true);
    } else {
      $span.text('Select All');
      this.toggleAllAccountsSelect(false);
    }

    if (this.props.selectAccountFormValid()) {
      this.setState({ showFormErrors: false });
    }
  }

  toggleAllAccountsSelect(selectAll) {
    this.props.toggleAllAccountsSelect(selectAll);
  }

  initialSort() {
    return [];
  }

  generateNextButton() {
    return {
      text: 'Next',
      action: (_ev, _dt, _node, _config) => this.toggleShowSelectAccounts(),
    };
  }

  generateToggleSelectButton() {
    return {
      extend: 'selectAll',
      className: 'select-all',
      text: 'Select All',
      action: (ev, dt, _node, _config) => {
        const $span = $(ev.target);
        if (dt.rows().count() != dt.rows({ selected: true }).count()) {
          $span.text('Deselect All');
          this.toggleAllAccountsSelect(true);
          dt.rows().select();
        } else {
          $span.text('Select All');
          this.toggleAllAccountsSelect(false);
          dt.rows().deselect();
        }
      },
    };
  }

  toggleShowSelectAccounts() {
    if (this.props.selectAccountFormValid()) {
      this.props.toggleSelectView({
        showSelectAccounts: !this.props.showSelectAccounts,
        showMerchantSettings: this.props.showSelectAccounts,
      });
    } else {
      this.setState({ showFormErrors: true });
    }
  }

  render() {
    const { selectedAccountCount, selectedAccounts, showSelectAccounts } = this.props;

    const { showFormErrors } = this.state;

    return (
      <div className="select-accounts">
        <h3 className="select-accounts__header" style={{ borderBottom: '1px solid #ddd' }}>
          <div>1. Select accounts - {selectedAccountCount} selected </div>
          {!showSelectAccounts && (
            <TrueLinkButton
              className="btn normal"
              onClick={this.toggleShowSelectAccounts}
              variant="none"
            >
              View/Edit
            </TrueLinkButton>
          )}
        </h3>
        {showSelectAccounts && (
          <div className="relative" id="select_accounts__table-wrapper">
            <DatatableLite
              columnDefs={this.columnDefs()}
              columns={this.columns()}
              customButtons={[this.generateToggleSelectButton(), this.generateNextButton()]}
              dataAccountId
              dom="frtipB"
              emptyTableText="No accounts available"
              info={false}
              initialSort={this.initialSort()}
              onDeselect={this.onToggleSelection}
              onSelect={this.onToggleSelection}
              paging={false}
              route={this.route()}
              scrollCollapse
              scrollY="300px"
              selectOption
              selectStyle="multi"
              selected={selectedAccounts}
              showInfo={false}
            />
            {showFormErrors && (
              <div
                className="new-form__error"
                style={{ position: 'absolute', right: 130, bottom: 26 }}
              >
                Please select one or more accounts
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

SelectAccounts.propTypes = {
  handleSelectAccounts: PropTypes.func.isRequired,
  selectAccountFormValid: PropTypes.func.isRequired,
  selectedAccountCount: PropTypes.number.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  showSelectAccounts: PropTypes.bool.isRequired,
  toggleAllAccountsSelect: PropTypes.func.isRequired,
  toggleSelectView: PropTypes.func.isRequired,
};
