import PropTypes from 'prop-types';
import React from 'react';
import SpecificRuleLine from './SpecificRuleLine';

export default class AdditionalSpendingCategories extends React.Component {
  render() {
    const block_rules = this.props.block_rules;
    const mcc_labels = this.props.mcc_labels;
    const denylist = block_rules['denylistMCCs'] || [];
    const allowlist = block_rules['allowlistMCCs'] || [];
    const categories = denylist.concat(allowlist).sort();

    const create_category_line = function (category) {
      const checkedRules = { denylistMCCs: category };
      const unCheckedRules = { allowlistMCCs: category };
      const on_denylist = denylist.includes(category);
      const spending_limit =
        block_rules['maxTransactionSizeByMCC'] && block_rules['maxTransactionSizeByMCC'][category];

      return (
        <SpecificRuleLine
          addBlockRules={this.props.addBlockRules}
          checkedRules={checkedRules}
          is_checked={on_denylist}
          key={category}
          limit={spending_limit}
          ruleType="MCC"
          showEditModal={this.props.showEditModal}
          text={mcc_labels[category]}
          unCheckedRules={unCheckedRules}
        />
      );
    }.bind(this);

    return (
      <div>
        <table className="specific_rules_table">
          <thead>
            <tr>
              <th>MY SETTINGS</th>
              <th>MERCHANT</th>
              <th>MAXIMUM TRANSACTION SIZE</th>
              <th>EDIT</th>
            </tr>
          </thead>
          <tbody>{categories.map(create_category_line)}</tbody>
        </table>
      </div>
    );
  }
}

AdditionalSpendingCategories.propTypes = {
  addBlockRules: PropTypes.func.isRequired,
  block_rules: PropTypes.object.isRequired,
  mcc_labels: PropTypes.object.isRequired,
  showEditModal: PropTypes.func.isRequired,
};
