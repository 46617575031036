import { capitalize } from 'react/shared/utils/Strings';

export function arrangeRulesetParams(
  settingRadioOption,
  merchant,
  allowAllTransactionsLessThanNumber,
) {
  const maxTransactionRule = {};
  switch (settingRadioOption) {
    case 'block all transactions':
      maxTransactionRule[merchant] = '';
      return {
        rule: settingRadioOption,
        merchant,
        amount: '',
        denylistMerchantClusters: merchant,
        maxTransactionSizeByMerchantCluster: maxTransactionRule,
      };
    case 'allow all transactions':
      maxTransactionRule[merchant] = '';
      return {
        rule: settingRadioOption,
        merchant,
        amount: '',
        allowlistMerchantClusters: merchant,
        maxTransactionSizeByMerchantCluster: maxTransactionRule,
      };
    case 'allow all transactions less than':
      maxTransactionRule[merchant] = allowAllTransactionsLessThanNumber;
      return {
        rule: settingRadioOption,
        merchant,
        amount: allowAllTransactionsLessThanNumber,
        allowlistMerchantClusters: merchant,
        maxTransactionSizeByMerchantCluster: maxTransactionRule,
      };
  }
}

export function formatRuleDisplay(bulkRulesetChange) {
  const rule = bulkRulesetChange.rule;
  let displayRule;
  if (rule === 'delete all merchant settings') {
    displayRule = rule;
  } else {
    const amount = bulkRulesetChange.amount;
    displayRule = rule + (amount != (undefined || null) ? ` $${amount}` : '');
  }

  return capitalize(displayRule);
}
