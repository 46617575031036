import PropTypes from 'prop-types';
import React from 'react';
import _find from 'underscore/modules/find';
import BeneficiaryCardReportsSection from './BeneficiaryCardReportsSection';
import CardholderTransactionList from 'react/shared/card/components/cardholder_dashboard/CardholderTransactionList';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';

function BeneficiaryDashboardCardTab(props) {
  if (!props.account) {
    return <div />;
  }

  // card_balance loads from javascripts in client/dashboard/home.html.haml
  return (
    <div>
      <div className="widescreen-inner-container">
        <h3 className="current-balance-header">
          <div className="pull-right">Status: {props.account.card_humanized_status}</div>
          Current Balance: &nbsp;
          <div className="card_balance" />
          &nbsp;
          <small>
            <br />
            card ending in {props.account.card_last_four}
          </small>
        </h3>
        <ul id="cardholder_home_menu">
          <li>
            <a href="#recent_transactions">This Month's Transactions</a>
          </li>
          <li>
            <a href="#transaction_report">Transaction Report</a>
          </li>
          <li>
            <a href="#monthly_statements">Monthly Statements</a>
          </li>
          <li>
            <a href="#card_settings">Card Settings</a>
          </li>
        </ul>
      </div>
      <div>
        <SubSectionHeader id="recent_transactions">This Month's Transactions</SubSectionHeader>
        <div style={{ marginTop: 20, width: '100%' }}>
          <CardholderTransactionList additionalClassNames="" nightlyPosts={props.transactions} />
        </div>
      </div>
      <BeneficiaryCardReportsSection
        account={_find(
          // competing notions of account serialization
          props.trustBeneficiary.accounts,
          (acct) => acct.id === props.account.account_id,
        )}
      />
      {/* Disabling since this was done before we agreed on JSON and APIs */}
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: props.cardSettings }} />
    </div>
  );
}

export default BeneficiaryDashboardCardTab;

BeneficiaryDashboardCardTab.propTypes = {
  trustBeneficiary: PropTypes.object.isRequired,
  account: PropTypes.object,
  cardSettings: PropTypes.node,
  transactions: PropTypes.array,
};
