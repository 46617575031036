import PropTypes from 'prop-types';
import React from 'react';
import DashboardPaginatedClientList from './DashboardPaginatedClientList';
import TrustList from 'react/member/components/dashboard/clients/TrustList';
import { originTypes } from 'react/member/components/dashboard/reports/Constants';
import Reports from 'react/member/components/dashboard/reports/Reports';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import TabContent from 'react/shared/components/TabContent';
import TabNavigation from 'react/shared/components/TabNavigation';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardClients extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  tabList() {
    return this.props.showReportsTab
      ? [
          { id: 'client-trusts-tab', title: 'Trusts', type: 'choseTrusts' },
          { id: 'client-reports-tab', title: 'Reports', type: 'choseReports' },
        ]
      : [{ id: 'client-trusts-tab', title: 'Trusts', type: 'choseTrusts' }];
  }

  trustsTab() {
    return (
      <TabContent id="client-trusts-tab" key="client-trusts-tab">
        {this.props.isPooledOrganization && (
          <TrustList
            orgSlug={this.props.orgSlug}
            showNewPortalUserInvite={this.props.showNewPortalUserInvite}
            trusts={this.props.trusts}
            updatedClientsIndexTrustsJson={this.props.updated_clients_index_trusts_json}
            userRoles={this.props.userRoles}
          />
        )}
        {this.props.showBeneficiariesHeader && (
          <div className="sub_section_header margin-bottom-0">Beneficiaries</div>
        )}
        {this.showStatusUI()}
      </TabContent>
    );
  }

  showStatusUI() {
    if (this.props.showStatusUI) {
      return (
        <div id="dashboard-clients-bene-status-lists">
          {this.showPending()}
          {this.showActive()}
          {this.showClosing()}
          {this.showInactive()}
        </div>
      );
    }
    return (
      <DashboardPaginatedClientList
        isPooled={this.props.isPooledOrganization}
        orgSlug={this.props.orgSlug}
      />
    );
  }

  showPending() {
    if (this.props.showPending) {
      return (
        <DashboardPaginatedClientList
          isPooled={this.props.isPooledOrganization}
          orgSlug={this.props.orgSlug}
          statusFilter="Pending"
        />
      );
    }
  }

  showActive() {
    return (
      <DashboardPaginatedClientList
        isPooled={this.props.isPooledOrganization}
        orgSlug={this.props.orgSlug}
        statusFilter="Active"
      />
    );
  }

  showClosing() {
    if (this.props.showClosing) {
      return (
        <DashboardPaginatedClientList
          isPooled={this.props.isPooledOrganization}
          orgSlug={this.props.orgSlug}
          statusFilter="Closing"
        />
      );
    }
  }

  showInactive() {
    if (this.props.showInactive) {
      return (
        <DashboardPaginatedClientList
          isPooled={this.props.isPooledOrganization}
          orgSlug={this.props.orgSlug}
          statusFilter="Inactive"
        />
      );
    }
  }

  reportsTab() {
    const origin = {
      customStatementDates: this.props.availableCustomStatementDates,
      quarterlyStatementDates: this.props.quarterlyStatementDates,
      id: this.props.orgSlug,
      type: originTypes.organization,
    };
    return (
      <TabContent id="client-reports-tab" key="client-reports-tab">
        <Reports origin={origin} />
      </TabContent>
    );
  }

  tabContentList() {
    return this.props.showReportsTab ? [this.trustsTab(), this.reportsTab()] : [this.trustsTab()];
  }

  render() {
    return (
      <div className="widescreen-inner-container">
        <TabNavigation showBackground tabs={this.tabList()}>
          {this.tabContentList()}
        </TabNavigation>
      </div>
    );
  }
}

DashboardClients.propTypes = {
  availableCustomStatementDates: PropTypes.shape({
    minDate: PropTypes.string.isRequired,
    maxDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  }).isRequired,
  isPooledOrganization: PropTypes.bool.isRequired,
  showBeneficiariesHeader: PropTypes.bool.isRequired,
  showStatusUI: PropTypes.bool.isRequired,
  showReportsTab: PropTypes.bool.isRequired,
  showPending: PropTypes.bool,
  showClosing: PropTypes.bool,
  showInactive: PropTypes.bool,
  showNewPortalUserInvite: PropTypes.bool,
  orgSlug: PropTypes.string,
  quarterlyStatementDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  trusts: PropTypes.array.isRequired,
  updated_clients_index_trusts_json: PropTypes.bool.isRequired,
  userRoles: userRoleShape.isRequired,
};
