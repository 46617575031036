import PropTypes from 'prop-types';
import React from 'react';
import DisbursementsIndex from './DisbursementsIndex';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardBudgetItemForm from 'react/member/components/dashboard/budget_items/DashboardBudgetItemForm';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemStore from 'react/member/stores/DashboardBudgetItemStore';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPendingDisbursements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisbursementForm: false,
      showDisbursementNotificationBubble: false,
      showRecurringDisbursementNotificationBubble: false,
      showOnHoldDisbursementNotificationBubble: false,
      payeesLoaded: false,
      resumedDraftDisbursementId: undefined,
      currentOrgSlug: this.props.orgSlug,
    };

    this._isMounted = false;

    bindAll(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.orgSlug) {
      DashboardActions.fetchClientsByOrganization(this.props.orgSlug);
    } else {
      DashboardActions.fetchClients();
    }

    DashboardPayeeStore.on('payees.clear', this.onChangeBeneficiaryPayee);
    DashboardPayeeStore.on('payees.fetch', this.onChangeBeneficiaryPayee);
    DashboardBudgetItemStore.on('budgetItem.create', this.callAfterCreate);
    DashboardDraftDisbursementStore.on('draftDisbursement.update', this.callAfterCreate);
    DashboardDraftDisbursementStore.on(
      'draftDisbursement.fetch',
      this.resumeEditingDraftDisbursement,
    );
    DashboardDisbursementStore.on('disbursement.update', this.checkDisbursementNotifications);
    DashboardDraftDisbursementStore.on(
      'draftDisbursement.update',
      this.checkDisbursementNotifications,
    );
    RecurringDisbursementStore.on(
      'recurringDisbursement.update',
      this.checkDisbursementNotifications,
    );

    this.checkDisbursementNotifications();
  }

  componentWillUnmount() {
    this._isMounted = false;

    DashboardPayeeStore.off('payees.clear', this.onChangeBeneficiaryPayee);
    DashboardPayeeStore.off('payees.fetch', this.onChangeBeneficiaryPayee);
    DashboardDisbursementStore.off('disbursement.update', this.checkDisbursementNotifications);
    DashboardBudgetItemStore.off('budgetItem.create', this.callAfterCreate);
    DashboardDraftDisbursementStore.off('draftDisbursement.update', this.callAfterCreate);
    DashboardDraftDisbursementStore.off(
      'draftDisbursement.update',
      this.checkDisbursementNotifications,
    );
    RecurringDisbursementStore.off(
      'recurringDisbursement.update',
      this.checkDisbursementNotifications,
    );
  }

  onChangeBeneficiaryPayee(payees, bool) {
    this.setState({
      payees,
      payeesLoaded: bool,
    });
  }

  callAfterCreate(disbursement) {
    if (disbursement.status === 'Draft') {
      Truelink.flash('success', 'Draft disbursement created!');
    } else {
      Truelink.flash('success', 'New disbursement created!');
    }
    this.toggleDisbursementForm(null);
  }

  checkDisbursementNotifications() {
    this.getDisbursementNotification();
    this.getDraftDisbursementNotification();
    this.getOnHoldDisbursementNotification();
    this.getRecurringDisbursementNotification();
  }

  getDisbursementNotification() {
    const url = this.props.orgSlug
      ? RailsRoutes.dashboard_organization_disbursements_notification_icon_path(this.props.orgSlug)
      : RailsRoutes.disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' }).done((response) => {
      if (this._isMounted) {
        return this.setState({ showDisbursementNotificationBubble: response });
      }
    });
  }

  getDraftDisbursementNotification() {
    const url = this.props.orgSlug
      ? RailsRoutes.dashboard_organization_draft_disbursements_notification_icon_path(
          this.props.orgSlug,
        )
      : RailsRoutes.draft_disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' }).done((response) => {
      if (this._isMounted) {
        return this.setState({ showDraftDisbursementNotificationBubble: response });
      }
    });
  }

  getOnHoldDisbursementNotification() {
    const url = this.props.orgSlug
      ? RailsRoutes.dashboard_organization_on_hold_disbursements_notification_icon_path(
          this.props.orgSlug,
        )
      : RailsRoutes.on_hold_disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' }).done((response) => {
      if (this._isMounted) {
        return this.setState({ showOnHoldDisbursementNotificationBubble: response });
      }
    });
  }

  getRecurringDisbursementNotification() {
    const url = this.props.orgSlug
      ? RailsRoutes.dashboard_organization_recurring_disbursements_notification_icon_path(
          this.props.orgSlug,
        )
      : RailsRoutes.recurring_disbursements_notification_icon_path();
    return $.ajax({ url, type: 'GET', dataType: 'JSON' }).done((response) => {
      if (this._isMounted) {
        return this.setState({
          showRecurringDisbursementNotificationBubble: response,
        });
      }
    });
  }

  resumeEditingDraftDisbursement(data) {
    this.setState({
      currentOrgSlug: data.organization_slug,
      showDisbursementForm: true,
    });
  }

  handleResumeDraft(draftDisbursementId) {
    if (!this.props.orgSlug) {
      DashboardActions.fetchDraftDisbursementById(draftDisbursementId);
      this.setState({
        resumedDraftDisbursementId: draftDisbursementId,
        budgetItemType: 'one_time',
      });
    } else {
      this.setState({
        showDisbursementForm: true,
        resumedDraftDisbursementId: draftDisbursementId,
        budgetItemType: 'one_time',
      });
    }
  }

  form() {
    const orgSlug = this.props.organization
      ? this.props.organization.slug
      : this.props.orgSlug || this.state.currentOrgSlug;

    return (
      <DashboardBudgetItemForm
        actionPath={this.actionPath}
        budgetItemType={this.state.budgetItemType}
        currentClientSlug={this.state.currentClientSlug}
        displayedPaymentMethods={this.props.displayedPaymentMethods}
        editExternalCheckDisposition={this.props.editExternalCheckDisposition}
        initialClient={this.props.initialClient}
        organizationSlug={orgSlug}
        payees={this.state.payees || []}
        payeesLoaded={this.state.payeesLoaded || false}
        resumedDraftDisbursementId={this.state.resumedDraftDisbursementId}
        setBudgetItemType={this.setBudgetItemType}
        threeMonthInfo={this.state.threeMonthInfo}
        toggleBudgetItemForm={this.toggleDisbursementForm}
        userRoles={this.props.userRoles}
      />
    );
  }

  toggleDisbursementForm(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    return this.setState({
      showDisbursementForm: !this.state.showDisbursementForm,
      budgetItemType: 'one_time',
      resumedDraftDisbursementId: null,
    });
  }

  setBudgetItemType(budgetItemType) {
    this.setState({ budgetItemType });
  }

  actionPath(disbursementType, isDraft = false) {
    if (disbursementType === 'recurring') {
      return RailsRoutes.dashboard_recurring_disbursements_path({ format: 'json' });
    } else if (disbursementType === 'one_time' && isDraft) {
      return RailsRoutes.dashboard_draft_disbursements_path({ format: 'json' });
    }

    return RailsRoutes.dashboard_disbursements_path({ format: 'json' });
  }

  render() {
    return this.state.showDisbursementForm ? (
      this.form()
    ) : (
      <DisbursementsIndex
        onResumeDraft={this.handleResumeDraft}
        orgSlug={this.props.orgSlug}
        renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
        showAdditionalApprovalTable={this.props.showAdditionalApprovalTable}
        showDisbursementNotificationBubble={this.state.showDisbursementNotificationBubble}
        showDraftDisbursementNotificationBubble={this.state.showDraftDisbursementNotificationBubble}
        showOnHoldDisbursementNotificationBubble={
          this.state.showOnHoldDisbursementNotificationBubble
        }
        showRecurringDisbursementNotificationBubble={
          this.state.showRecurringDisbursementNotificationBubble
        }
        showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
        userRoles={this.props.userRoles}
      />
    );
  }
}

DashboardPendingDisbursements.propTypes = {
  displayedPaymentMethods: PropTypes.array,
  editExternalCheckDisposition: PropTypes.bool,
  initialClient: PropTypes.object,
  orgSlug: PropTypes.string,
  showAdditionalApprovalTable: PropTypes.bool.isRequired,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  organization: PropTypes.object,
  userRoles: userRoleShape.isRequired,
};
