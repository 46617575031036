import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import _map from 'underscore/modules/map';
import SelectAccounts from './SelectAccounts';
import SelectMerchantSettings from './SelectMerchantSettings';
import PopUp from 'react/shared/components/popups/PopUp';
import bindAll from 'react/shared/utils/bind_all';
import { callAPI, getUrl } from 'react/shared/utils/call_api';

export default class AccountMerchantSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSelectAccounts: true,
      showMerchantSettings: false,
    };

    bindAll(this);
  }

  toggleSelectView({ showSelectAccounts, showMerchantSettings }) {
    this.setState({ showSelectAccounts, showMerchantSettings });
  }

  handleSelectAccounts({ selectedAccounts, selectedAccountCount }) {
    this.props.handleSelectAccounts(selectedAccounts, selectedAccountCount);
  }

  selectAccountFormValid() {
    return this.props.selectedAccountCount > 0;
  }

  submitAddMerchantSettingForm(ruleset) {
    const { selectedAccounts, selectedAccountCount, allAccountsSelected } = this.props;

    const accountIds = _map(selectedAccounts, (account) => account.account_id);
    _extend(ruleset, {
      account_ids: accountIds,
      account_count: selectedAccountCount,
      all_accounts_selected: allAccountsSelected,
    });
    const [url, successMsg] = ruleset.merchant_name
      ? [getUrl('bulk_custom_merchant_request'), 'Your request has been emailed to support.']
      : [getUrl('bulk_add_block_rules'), 'Bulk operation successfully applied!'];

    callAPI(url, ruleset, null).then(() => {
      Truelink.flash('success', successMsg);
      window.setTimeout(() => Truelink.flashClear(), 5000);
      this.closeAccountMerchantSettingsModal();
      this.props.reDrawDatatable();
    });
  }

  toggleAllAccountsSelect(allSelected) {
    this.props.toggleAllAccountsSelect(allSelected);
  }

  closeAccountMerchantSettingsModal() {
    this.setState({ showSelectAccounts: true, showMerchantSettings: false });
    this.props.closeAccountMerchantSettingsModal();
  }

  render() {
    const {
      showDefaultSpendingMonitorOption,
      showAccountMerchantSettingsModal,
      allAccountsSelected,
      selectedAccountCount,
      selectedAccounts,
    } = this.props;

    const { showSelectAccounts, showMerchantSettings } = this.state;

    return (
      <div>
        <PopUp
          header="Add merchant setting for multiple accounts"
          hideFooter
          maxWidth="50rem"
          modalBodyClass="nopadding modal-body--no-footer"
          onClose={this.closeAccountMerchantSettingsModal}
          openModal={showAccountMerchantSettingsModal}
        >
          <SelectAccounts
            allAccountsSelected={allAccountsSelected}
            handleSelectAccounts={this.handleSelectAccounts}
            selectAccountFormValid={this.selectAccountFormValid}
            selectedAccountCount={selectedAccountCount}
            selectedAccounts={selectedAccounts}
            showSelectAccounts={showSelectAccounts}
            toggleAllAccountsSelect={this.toggleAllAccountsSelect}
            toggleSelectView={this.toggleSelectView}
          />
          <SelectMerchantSettings
            selectedAccountCount={selectedAccountCount}
            showDefaultSpendingMonitorOption={showDefaultSpendingMonitorOption}
            showMerchantSettings={showMerchantSettings}
            submitAddMerchantSettingForm={this.submitAddMerchantSettingForm}
            submitMerchantSettings={this.submitMerchantSettings}
            toggleAddMerchantForm={this.toggleAddMerchantForm}
            toggleSelectView={this.toggleSelectView}
          />
        </PopUp>
      </div>
    );
  }
}

AccountMerchantSettingsModal.propTypes = {
  allAccountsSelected: PropTypes.bool.isRequired,
  closeAccountMerchantSettingsModal: PropTypes.func.isRequired,
  handleSelectAccounts: PropTypes.func.isRequired,
  reDrawDatatable: PropTypes.func.isRequired,
  selectedAccountCount: PropTypes.number.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  showAccountMerchantSettingsModal: PropTypes.bool.isRequired,
  showDefaultSpendingMonitorOption: PropTypes.bool.isRequired,
  toggleAllAccountsSelect: PropTypes.func.isRequired,
};
