import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import CardNotPresentExceptionsModal from './CardNotPresentExceptionsModal';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';

export default function CardNotPresentExceptions({
  blockRules,
  addBlockRules,
  removeBlockRules,
  hidden,
}) {
  const [modal, setModal] = useState(null);
  const addBlockRulesWithModal = (data, el) => {
    const ruleKey = Object.keys(el.props.rules)[0];
    const ruleValue = el.props.rules[ruleKey];
    const semantics = blockRules['denylistSemantics'];

    if (semantics === undefined || !semantics.includes(ruleValue)) {
      addBlockRules(data, el); // If semantic isn't already blocked in spending categories section, proceed as normal
    } else {
      setModal({ ruleValue, data, el });
    }
  };

  const handleBlockConfirmation = useCallback(() => {
    addBlockRules(modal.data, modal.el);
    setModal(null);
  }, [modal, addBlockRules]);

  const handleModalCancel = () => {
    setModal(null);
  };

  const semantics = blockRules.allowlistSemantics || [];
  const utilities_is_checked = semantics.includes('UTILITIES');
  const medical_is_checked = semantics.includes('MEDICAL_AND_CARE');
  const food_is_checked = semantics.includes('FOOD_RESTAURANT');
  const phone_is_checked = semantics.includes('PHONE_TV_CABLE');

  return (
    <div
      className={hidden ? 'hidden' : null}
      style={{ marginLeft: '124px', marginTop: '14px', marginBottom: '20px' }}
    >
      {modal && (
        <CardNotPresentExceptionsModal
          onModalClose={handleModalCancel}
          onOkay={handleBlockConfirmation}
          rule={modal.ruleValue}
        />
      )}
      <CheckboxLine
        hr={false}
        id="exception_utilities"
        isChecked={utilities_is_checked}
        rules={{ allowlistSemantics: 'UTILITIES' }}
        text2="Exception: Allow utility bill payments online or by phone"
        updateChecked={addBlockRulesWithModal}
        updateUnChecked={removeBlockRules}
      />
      <hr style={{ height: '0px', margin: '10px' }} />
      <CheckboxLine
        hr={false}
        id="exception_medical_and_care"
        isChecked={medical_is_checked}
        rules={{ allowlistSemantics: 'MEDICAL_AND_CARE' }}
        text2="Exception: Allow medical and health payments online or by phone"
        updateChecked={addBlockRulesWithModal}
        updateUnChecked={removeBlockRules}
      />
      <hr style={{ height: '0px', margin: '10px' }} />
      <CheckboxLine
        hr={false}
        id="exception_food_restaurant"
        isChecked={food_is_checked}
        rules={{ allowlistSemantics: 'FOOD_RESTAURANT' }}
        text2="Exception: Allow restaurant delivery payment online or by phone"
        updateChecked={addBlockRulesWithModal}
        updateUnChecked={removeBlockRules}
      />
      <hr style={{ height: '0px', margin: '10px' }} />
      <CheckboxLine
        hr={false}
        id="exception_phone_tv_cable"
        isChecked={phone_is_checked}
        rules={{ allowlistSemantics: 'PHONE_TV_CABLE' }}
        text2="Exception: Allow phone, TV, and cable payments online or by phone"
        updateChecked={addBlockRulesWithModal}
        updateUnChecked={removeBlockRules}
      />
    </div>
  );
}

CardNotPresentExceptions.propTypes = {
  blockRules: PropTypes.object.isRequired,
  addBlockRules: PropTypes.func.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
};
