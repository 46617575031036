import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardPortfolioStore = flux.createStore({
  actions: [
    DashboardActions.fetchPortfoliosByTrust,
    DashboardActions.generateOutstandingChecksReport,
    DashboardActions.generateLowBalanceReport,
  ],

  fetchPortfoliosByTrust(trustSlug) {
    $.get(
      RailsRoutes.dashboard_trust_portfolios_path(trustSlug, { format: 'json' }),
      (portfolios) => {
        this.emit('portfolios.fetch', portfolios, trustSlug);
      },
    );
  },

  generateOutstandingChecksReport(portfolio_id) {
    $.get(
      RailsRoutes.dashboard_portfolio_outstanding_checks_report_path(portfolio_id, {
        format: 'json',
      }),
      (report) => {
        this.emit('portfolios.outstandingChecksReportGenerated', report);
      },
    );
  },

  generateLowBalanceReport(portfolio_id) {
    $.get(
      RailsRoutes.dashboard_portfolio_low_balance_report_path(portfolio_id, {
        format: 'json',
      }),
      (report) => {
        this.emit('portfolios.lowBalanceReportGenerated', report);
      },
    );
  },
});

export default DashboardPortfolioStore;
