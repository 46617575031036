import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RemoveCircleRounded from '@mui/icons-material/RemoveCircleRounded';
import WarningOutlined from '@mui/icons-material/WarningOutlined';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import _compact from 'underscore/modules/compact';
import _filter from 'underscore/modules/filter';
import _find from 'underscore/modules/find';
import _sortBy from 'underscore/modules/sortBy';
import TransactionRow from './TransactionRow';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import SavedMessage from 'react/shared/components/SavedMessage';
import Pagination from 'react/shared/components/forms/Pagination';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const pendingTransUrl =
  'https://help.truelinkfinancial.com/article/352-pending-and-pre-authorized-transactions';
const helpCenterUrl = 'https://help.truelinkfinancial.com/';

const useStyles = makeStyles(() => ({
  transactionList: {
    maxWidth: '940px',
    fontSize: '18px',
    '.status-column': {
      width: '62px',
    },
    '& .date-column': {
      width: '105px',
    },
    '& .merchant-column': {
      width: '300px',
    },
    '& .location-column': {
      width: '148px',
    },
    '& .amount-column': {
      width: '120px',
    },
    '& .details-column': {
      width: '205px',
    },
  },
  transactionListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  [theme.breakpoints.down('md')]: {
    transactionTable: {
      marginTop: '20px',
      width: '100%',
      overflowX: 'scroll',
    },
  },
  filterButtons: {
    '& button': {
      textTransform: 'capitalize',
      '&.active': {
        backgroundColor: PALETTE.grey6,
      },
    },
  },
  searchInput: {
    '& input': {
      height: '40px',
      borderRadius: '20px',
      paddingLeft: '15px',
      marginBottom: '0px',
    },
  },
  tooltipInnerText: {
    fontSize: '16px',
  },
  flexContainer: {
    flex: '1',
    padding: '10px',
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
      marginRight: '0px',
    },
  },
  zeroState: {
    padding: '50px 30px',
    textAlign: 'center',
  },
}));

export default function TransactionList(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [transactions, setTransactions] = useState([]);
  const [hideClosed, setHideClosed] = useState(props.hideClosedCardTransactions);
  const savedRef = useRef();

  useEffect(() => {
    const url = `${RailsRoutes.api_v2_account_transactions_path(props.accountId)}?include=card`;

    axios.get(url).then(({ data }) => {
      data.data.forEach((transaction) => {
        const cardId = transaction.relationships?.card?.data.id;
        const cardInfo = data.included?.find((card) => card.type === 'card' && card.id === cardId);
        transaction.meta.card = cardInfo;
      });

      setTransactions(data.data);
      setLoading(false);
    });
  }, [props.accountId]);

  const queryTransactions = () => {
    // Defining the function here so that `searchTerm` is still within JS scope.
    const searchFilter = function (transaction) {
      // Early return if there isn't a search term. Would be ideal to not call this function in the first
      // place, but that makes our final _chain all the more complicated to generate
      if (searchTerm === undefined || searchTerm === '') {
        return true;
      }
      // Run this through _compact to get rid of falsy values
      const fields = _compact([
        transaction.attributes.name,
        transaction.attributes.category,
        transaction.attributes.explanation,
        String(transaction.attributes.amount.amount),
        transaction.attributes.location,
        transaction.attributes.merchantName,
      ]);
      return _find(fields, (field) => field.toLowerCase().includes(searchTerm.toLowerCase()));
    };

    const typeFilter = (transaction) => {
      if (filter === 'all') return true;
      return transaction.attributes.status === filter;
    };
    const closedFilter = (transaction) => {
      if (!hideClosed) return true;
      return transaction.meta.card.attributes.status !== 'CLOSED';
    };
    const sortBySortField = (transaction) => transaction.attributes[sortField];

    const filteredTransactions = _filter(
      _filter(_filter(_sortBy(transactions, sortBySortField), closedFilter), typeFilter),
      searchFilter,
    );

    if (sortDirection === 'desc') {
      filteredTransactions.reverse();
    }

    // We do not know the new page variables until we execute our query above, so calculate them now
    const total = filteredTransactions.length;

    // Finally, we need to return the page we're currently viewing
    const offset = (page - 1) * pageSize;

    return {
      filteredTransactions: filteredTransactions.slice(offset, offset + pageSize),
      total,
    };
  };

  const setSortStatusAsc = () => {
    setSortField('status');
    setSortDirection('asc');
  };

  const setSortStatusDesc = () => {
    setSortField('status');
    setSortDirection('desc');
  };

  const setSortDateAsc = () => {
    setSortField('timestamp');
    setSortDirection('asc');
  };

  const setSortDateDesc = () => {
    setSortField('timestamp');
    setSortDirection('desc');
  };

  const setFilterAll = () => {
    setFilter('all');
  };

  const setFilterBlocked = () => {
    setFilter('blocked');
  };

  const setFilterAlerted = () => {
    setFilter('flagged');
  }; // NOTE: we consider these to be alerted, even though the value is 'flagged'

  const searchUpdated = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleHideCardClosedTransactionToggle = (e) => {
    const { checked } = e.target;

    axios
      .patch(
        RailsRoutes.dashboard_toggle_hide_card_close_transaction_path({
          account_id: props.accountId,
        }),
      )
      .then(() => {
        savedRef?.current?.saved();
        setHideClosed(checked);
      });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const toggleDateSort = () => {
    if (sortDirection === 'desc') {
      setSortDateAsc();
    } else {
      setSortDateDesc();
    }
  };

  const toggleStatusSort = () => {
    if (sortDirection === 'desc') {
      setSortStatusAsc();
    } else {
      setSortStatusDesc();
    }
  };

  const renderHideClosedCardTransactionControl = () => {
    const { isRepPayee, cardCount, hideClosedCardTransactionsEnabled } = props;
    if (!hideClosedCardTransactionsEnabled || cardCount == 1) return '';

    let tooltipText = 'Check this box to hide transactions on closed cards.';
    if (!isRepPayee) {
      tooltipText +=
        ' To stop receiving transaction alerts for closed cards, update the settings on the Alerts tab.';
    }

    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="hide-closed-card-transactions" style={{ margin: 0 }}>
          <InfoTooltip
            allowClickThrough
            id="hide-closed-card-transactions-tooltip"
            placement="bottom"
            template={`
              <div class="tooltip" role="tooltip">
                <div class="tooltip-arrow" style="border-bottom-color: #6EBD6A;"></div>
                <div class="tooltip-inner" style="background: #6EBD6A; color: #FFFFFF;"></div>
              </div>
            `}
            tooltipText={tooltipText}
          >
            <div className="flex">
              <input
                defaultChecked={props.hideClosedCardTransactions}
                id="hide-closed-card-transactions"
                onClick={handleHideCardClosedTransactionToggle}
                style={{ width: 20 }}
                type="checkbox"
              />

              <span style={{ marginLeft: 5 }}>Hide transactions on closed cards</span>
              <SavedMessage ref={savedRef} />
            </div>
          </InfoTooltip>
        </label>
      </div>
    );
  };

  const helpTooltipContent = (
    <div className={classes.tooltipInnerText}>
      <p>
        Need help with pending transactions?
        <a href={pendingTransUrl} rel="noreferrer" target="_blank">
          Learn more here.{' '}
        </a>{' '}
      </p>
      <p>
        For more questions about transactions check out our{' '}
        <a href={helpCenterUrl} rel="noreferrer" target="_blank">
          help center.
        </a>
      </p>
    </div>
  );

  const renderTransactionRows = (transactions) =>
    transactions.map((transaction, index) => (
      <TransactionRow
        accountId={props.accountId}
        cardCount={props.cardCount}
        hideClosedCardTransactionsEnabled={props.hideClosedCardTransactionsEnabled}
        index={index}
        isCardholderView={props.isCardholderView}
        key={index}
        showNotes={props.showNotes}
        showQDE={props.showQDE}
        showReceipts={props.showReceipts}
        supportEmail={props.supportEmail}
        transaction={transaction}
      />
    ));

  const getSortClass = (field) => {
    if (field === sortField) {
      if (sortDirection === 'desc') {
        return 'sorting_desc';
      }
      return 'sorting_asc';
    }
    return '';
  };

  if (loading) return <LoadingIndicator />;

  const dateSortClass = getSortClass('date');
  const statusSortClass = getSortClass('status');
  const hideFilters = props.isCardholderView;

  const allFilterSelectedClass = filter === 'all' ? 'active' : '';
  const blockedFilterSelectedClass = filter === 'blocked' ? 'active' : '';
  const alertedFilterSelectedClass = filter === 'flagged' ? 'active' : '';

  const { filteredTransactions, total } = queryTransactions();

  return (
    <div className={classes.transactionList}>
      <div className={classes.transactionListHeader}>
        {!hideFilters && (
          <ButtonGroup className={classes.filterButtons} variant="outlined">
            <Button className={`${allFilterSelectedClass}`} onClick={setFilterAll}>
              All
            </Button>
            <Button className={`${blockedFilterSelectedClass}`} onClick={setFilterBlocked}>
              <RemoveCircleRounded fontSize="small" style={{ fill: PALETTE.red }} />
              &nbsp;&nbsp;Blocked
            </Button>
            <Button className={`${alertedFilterSelectedClass}`} onClick={setFilterAlerted}>
              <WarningOutlined fontSize="small" style={{ fill: '#E7B12C' }} />
              &nbsp;&nbsp;Alerted
            </Button>
          </ButtonGroup>
        )}
        <TrueLinkTooltip placement="bottom" tooltipContent={helpTooltipContent}>
          <div className="flex">
            <HelpOutlineIcon fontSize="small" style={{ fill: PALETTE.grey3, marginRight: '5px' }} />
            <span>Help</span>
          </div>
        </TrueLinkTooltip>
        {renderHideClosedCardTransactionControl()}
        <div className={classes.flexContainer} />
        <div className={classes.searchInput}>
          <input onChange={searchUpdated} placeholder="Search" type="search" />
        </div>
      </div>

      <div className={classes.transactionTable}>
        <table className="dataTable table table-hover" id="transactions_table">
          <thead>
            <tr role="row">
              <th
                className={`vertical-align-middle ${hideFilters ? 'cursor-default' : 'sorting'} ${statusSortClass} status-column`}
                onClick={hideFilters ? null : toggleStatusSort}
              >
                Status
              </th>
              <th
                className={`${hideFilters ? 'cursor-default' : 'sorting'} ${dateSortClass} date-column`}
                onClick={hideFilters ? null : toggleDateSort}
              >
                Date
              </th>
              <th className="cursor-default merchant-column">Merchant</th>
              <th className="cursor-default location-column">Location</th>
              <th className="align-right cursor-default amount-column">Amount</th>
              <td className="cursor-default details-column" />
            </tr>
          </thead>
          {renderTransactionRows(filteredTransactions)}
        </table>
        {filteredTransactions.length == 0 && (
          <div className={classes.zeroState}>No transactions to display</div>
        )}
        {filteredTransactions.length > 0 && (
          <Pagination
            itemsName="transactions"
            itemsTotal={total}
            onPageChange={handlePageChange}
            page={page}
            pageSize={pageSize}
          />
        )}
      </div>
    </div>
  );
}

TransactionList.propTypes = {
  accountId: PropTypes.string.isRequired,
  cardCount: PropTypes.number.isRequired,
  hideClosedCardTransactions: PropTypes.bool.isRequired,
  hideClosedCardTransactionsEnabled: PropTypes.bool.isRequired,
  isCardholderView: PropTypes.bool,
  isRepPayee: PropTypes.bool.isRequired,
  showNotes: PropTypes.bool.isRequired,
  showQDE: PropTypes.bool.isRequired,
  showReceipts: PropTypes.bool.isRequired,
  supportEmail: PropTypes.string.isRequired,
};
