import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardTrustStore = flux.createStore({
  actions: [
    DashboardActions.generateOutstandingChecksTrustReport,
    DashboardActions.generateLowBalanceTrustReport,
  ],

  generateOutstandingChecksTrustReport(trust_id) {
    $.get(
      RailsRoutes.dashboard_trust_outstanding_checks_report_path(trust_id, {
        format: 'json',
      }),
      (report) => {
        this.emit('trust.outstandingChecksReportGenerated', report);
      },
    );
  },

  generateLowBalanceTrustReport(trust_id) {
    $.get(
      RailsRoutes.dashboard_trust_low_balance_report_path(trust_id, {
        format: 'json',
      }),
      (report) => {
        this.emit('trust.lowBalanceReportGenerated', report);
      },
    );
  },
});

export default DashboardTrustStore;
