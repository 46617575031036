import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CardTransactionAttachments from './CardTransactionAttachments';
import SaveOrCancelButtons from './SaveOrCancelButtons';
import TransactionNote from './TransactionNote';
import CardTransactionShape from 'react/member/card/shapes/CardTransactionShape';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import { capitalize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class TransactionRowDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionSettings: null,
      ruleType: 'do_nothing',
      maxAllowAmount: '',
      noLimitChecked: true,
      QDEValue: this.getQDEValue(),
      showSettings: true,
      showQDEbuttons: false,
    };

    bindAll(this);
  }

  canEdit() {
    return this.props.transaction.meta.permissions.edit;
  }

  getQDEValue() {
    return this.props.transaction.attributes.qde;
  }

  hideQualifiedDisabilityExpense() {
    return !this.props.transaction.id || !this.props.showQDE;
  }

  hideNotes() {
    return !this.props.transaction.id || !this.props.showNotes;
  }

  hideReceipts() {
    return !this.props.transaction.id || !this.props.showReceipts;
  }

  handleSelectChange(e) {
    this.setState({ ruleType: e.target.value });
  }

  handleMaxAllowAmount(e) {
    this.setState({ maxAllowAmount: e.target.value });
  }

  setTransactionLimitRadio(e) {
    const noLimitChecked = e.target.value === 'true';
    const newState = { noLimitChecked };

    if (noLimitChecked) {
      newState.maxAllowAmount = '';
    }

    this.setState(newState);
  }

  async ajaxCall(url, data, successMessage, callback, type) {
    Truelink.flash('info', 'Sending...', false);
    const response = await $.ajax({ url, type, data, dataType: 'json' });
    Truelink.flashClear();
    if (successMessage) Truelink.flash('success', successMessage, false);
    if (callback) callback(response);
  }

  saveRuleset() {
    const { accountId } = this.props;
    const { merchantName, mid } = this.props.transaction.attributes;
    const max_amount_rule = {};
    const merchants = {};
    const params = {};

    merchants[merchantName] = mid;
    params['merchants'] = merchants;
    params['account_id'] = accountId;

    const successMessage = `Your settings for ${merchantName} have been updated. To change other settings please visit your Spending Monitor.`;

    let url;
    switch (this.state.ruleType) {
      case 'do_nothing':
        url = '/member/dashboard/clear_rules';
        params['allowlistMerchants'] = merchantName;
        max_amount_rule[merchantName] = '';
        break;
      case 'never_block':
        url = '/member/dashboard/add_block_rules';
        params['allowlistMerchants'] = merchantName;
        max_amount_rule[merchantName] = this.state.maxAllowAmount;
        break;
      case 'block':
        url = '/member/dashboard/add_block_rules';
        params['denylistMerchants'] = merchantName;
        max_amount_rule[merchantName] = '';
        break;
    }

    params['maxTransactionSizeByMerchant'] = max_amount_rule;
    this.ajaxCall(url, params, successMessage, this.closeSettingsAndUpdateRuleset, 'POST');
  }

  closeDetailView() {
    this.props.closeDetail();
    this.setState({ ruleType: 'do_nothing', maxAllowAmount: '' });
  }

  updateQDE() {
    const { id: cardTransactionId } = this.props.transaction;
    const url = RailsRoutes.dashboard_card_transaction_path(cardTransactionId, { format: 'json' });

    const params = {
      card_transaction: {
        qde: this.state.QDEValue,
      },
    };

    const successMessage = 'The QDE setting has been saved!';
    this.ajaxCall(url, params, successMessage, this.hideQDEButtons, 'PUT');
  }

  toggleShowSettings() {
    this.setState({ showSettings: !this.state.showSettings });
  }

  closeSettingsAndUpdateRuleset(response) {
    this.setState({ showSettings: false });
    DashboardRulesetStore.updateBlockRules(response.block_rules);
  }

  renderCardInfo() {
    if (this.props.cardCount > 1) {
      const cardInfo = this.props.transaction?.meta?.card.attributes;
      const expiryDate = cardInfo.expiryDate
        ? moment(cardInfo.expiryDate).format('MM/DD/YYYY')
        : 'This card has expired.';

      return (
        <div className="new-form__section new-form--compact new-form__section--subheader">
          <div className="new-form-field">
            <div className="new-form__label">Card information:</div>
          </div>
          <div className="new-form-field">
            <div className="new-form__label" style={{ width: '86px' }}>
              Ending in:
            </div>
            <div className="new-form__data" style={{ paddingRight: '10px' }}>
              {cardInfo.lastFour}
            </div>
          </div>
          <div className="new-form-field">
            <div className="new-form__label" style={{ width: '60px' }}>
              Expiry:
            </div>
            <div className="new-form__data" style={{ paddingRight: '10px' }}>
              {expiryDate}
            </div>
          </div>
        </div>
      );
    }
  }

  showHideClosedCardTransactionsTooltip() {
    window.scrollTo(window.pageXOffset, 0);
    $('#hide-closed-card-transactions-tooltip').tooltip('show');
  }

  isClosedCardTransaction() {
    return (
      this.props.cardCount > 1 &&
      this.props.transaction.attributes.block?.reason === 'AccountClosed' &&
      this.props.hideClosedCardTransactionsEnabled
    );
  }

  renderExplanation() {
    const { transaction } = this.props;

    const boxStyle = this.state.showSettings
      ? {
          boxShadow: '0px 1px 2px #ccc',
          padding: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
        }
      : { padding: '10px' };

    const explanationStyle =
      transaction.attributes.status === 'blocked'
        ? { backgroundColor: 'rgba(255, 106, 106, 0.33)', borderRadius: '5px' }
        : { background: 'none' };

    let explanation;
    if (!transaction.attributes.includeMerchantSetting) {
      if (transaction.attributes.explanation) {
        explanation = <div>{capitalize(transaction.attributes.explanation)}</div>;
      } else {
        explanation = (
          <div>
            Please feel free to email us at {this.props.supportEmail} if you have any questions.
          </div>
        );
      }
    } else if (transaction.attributes.isReversal) {
      explanation = (
        <div>
          The merchant chose not to finalize this transaction and either reversed the charge or
          completed a new, separate transaction. Please contact our support team for more
          information.
        </div>
      );
    } else if (transaction.attributes.explanation) {
      if (this.isClosedCardTransaction()) {
        explanation = (
          <div>
            <b>{capitalize(transaction.attributes.explanation)}</b> This transaction was attempted
            on a card that was closed. If you no longer wish to see transactions on closed cards,
            check the box at the top of the page or{' '}
            <a
              className="cursor-pointer"
              onClick={this.showHideClosedCardTransactionsTooltip}
              onKeyUp={this.showHideClosedCardTransactionsTooltip}
              role="link"
              tabIndex="0"
            >
              click here
            </a>
            .
          </div>
        );
      } else if (this.props.isCardholderView && transaction.attributes.status == 'flagged') {
        explanation = (
          <div>
            This is a {transaction.attributes.humanReadableType}. Please feel free to email us at{' '}
            {this.props.supportEmail} if you have any questions.
          </div>
        );
      } else {
        explanation = <div>{capitalize(transaction.attributes.explanation)}</div>;
      }
    } else if (transaction.attributes.humanReadableType) {
      explanation = (
        <div>
          This is a {transaction.attributes.humanReadableType}. Please feel free to email us at{' '}
          {this.props.supportEmail} if you have any questions.
        </div>
      );
    } else {
      return;
    }

    return (
      <div className="new-form__section" style={boxStyle}>
        <div className="new-form--compact new-form__section--subheader" style={explanationStyle}>
          <div className="new-form-field">
            <div className="new-form__label">Explanation:</div>
            <div className="new-form__data" style={{ paddingRight: 10 }}>
              {explanation}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSettings() {
    const { transaction } = this.props;
    if (
      this.props.isCardholderView ||
      !this.canEdit() ||
      !this.state.showSettings ||
      !transaction.attributes.includeMerchantSetting
    )
      return;

    let amountInput;
    if (this.state.ruleType === 'never_block') {
      amountInput = (
        <div className="new-form-field" style={{ marginTop: 7 }}>
          <div className="new-form__label">Allow transactions:</div>
          <div className="new-form__data flex flex--space-between">
            <div className="radio-group radio-group--inline">
              <label
                className="margin-bottom-0"
                htmlFor={`allowTransactionsNoLimit${transaction.id}`}
              >
                <input
                  checked={this.state.noLimitChecked}
                  id={`allowTransactionsNoLimit${transaction.id}`}
                  name="budget_item_type"
                  onChange={this.setTransactionLimitRadio}
                  type="radio"
                  value="true"
                />
                Of any amount
              </label>
              <label
                className="margin-bottom-0"
                htmlFor={`allowTransactionsDollarLimit${transaction.id}`}
              >
                <input
                  checked={!this.state.noLimitChecked}
                  id={`allowTransactionsDollarLimit${transaction.id}`}
                  name="budget_item_type"
                  onChange={this.setTransactionLimitRadio}
                  type="radio"
                  value="false"
                />
                Up to&nbsp;&nbsp;
                <div className="money-input-wrapper">
                  <input
                    disabled={this.state.noLimitChecked}
                    onChange={this.handleMaxAllowAmount}
                    style={{ width: 100, marginBottom: 0 }}
                    type="number"
                  />
                </div>
              </label>
            </div>
            <SaveOrCancelButtons onCancel={this.toggleShowSettings} onSave={this.saveRuleset} />
          </div>
        </div>
      );
    }

    return (
      <div
        className="new-form__section new-form--compact relative"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
      >
        <div className="new-form-field">
          <div className="new-form__label">Merchant:</div>
          <div className="new-form__data">{transaction.attributes.merchantName}</div>
        </div>
        <div className="new-form-field">
          <div className="new-form__label">
            <label htmlFor="transaction_setting">Transaction setting:</label>
          </div>
          <div className="new-form__data flex flex--space-between">
            <select
              defaultValue=""
              id="transaction_setting"
              onChange={this.handleSelectChange}
              required
              style={{ marginBottom: 0 }}
            >
              <option disabled hidden value="">
                {' '}
                Select a new setting
              </option>
              <option value="do_nothing">No special settings</option>
              <option value="never_block">Always allow</option>
              <option value="block">Always block</option>
            </select>
            {!amountInput && (
              <SaveOrCancelButtons onCancel={this.toggleShowSettings} onSave={this.saveRuleset} />
            )}
          </div>
        </div>

        {amountInput}
      </div>
    );
  }

  updateQDEState(e) {
    this.setState({
      QDEValue: e.target.value === 'true',
      showQDEbuttons: true,
    });
  }

  hideQDEButtons() {
    this.setState({ showQDEbuttons: false });
  }

  resetQDE() {
    this.setState({
      QDEValue: this.getQDEValue(),
      showQDEbuttons: false,
    });
  }
  getQDEDisplayValue(qdeValue) {
    if (qdeValue === true) {
      return 'Yes';
    }
    if (qdeValue === false) {
      return 'No';
    }
    return 'Unset';
  }

  renderQDE() {
    if (this.hideQualifiedDisabilityExpense()) return;

    const yesId = `QDEYes${this.props.detailKey}`;
    const noId = `QDENo${this.props.detailKey}`;

    if (!this.canEdit()) {
      return (
        <div className="new-form__section new-form--compact new-form__section--inset new-form__section--last relative">
          <div className="new-form-field qualified-disability-expense">
            <div className="new-form__label">Qualified Disability Expense:</div>
            <div className="new-form__data">{this.getQDEDisplayValue(this.getQDEValue())}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="new-form__section new-form--compact new-form__section--inset new-form__section--last relative">
        <div className="new-form-field qualified-disability-expense">
          <div className="new-form__label">Qualified Disability Expense:</div>
          <div className="new-form__data flex flex--space-between">
            <div className="radio-group radio-group--inline">
              <label className="margin-bottom-0" htmlFor={yesId}>
                <input
                  checked={this.state.QDEValue == true}
                  id={yesId}
                  onChange={this.updateQDEState}
                  type="radio"
                  value="true"
                />{' '}
                Yes
              </label>
              <label className="margin-bottom-0" htmlFor={noId}>
                <input
                  checked={this.state.QDEValue == false}
                  id={noId}
                  onChange={this.updateQDEState}
                  type="radio"
                  value="false"
                />{' '}
                No
              </label>
            </div>
            {this.state.showQDEbuttons && (
              <SaveOrCancelButtons onCancel={this.resetQDE} onSave={this.updateQDE} />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAttachments() {
    if (this.hideReceipts()) return;
    const { transaction } = this.props;

    return (
      <CardTransactionAttachments
        canEdit={this.canEdit()}
        cardTransactionId={transaction.id}
        isCardholderView={this.props.isCardholderView}
        style="link"
      />
    );
  }

  renderNotes() {
    if (this.hideNotes()) return;
    const { transaction } = this.props;

    return <TransactionNote canEdit={this.canEdit()} cardTransactionId={transaction.id} />;
  }

  render() {
    const { transaction } = this.props;

    let dialogId, slidedownContent;
    switch (transaction.attributes.humanReadableType) {
      case 'charge':
        if (!transaction.attributes.includeMerchantSetting) {
          if (transaction.attributes.explanation) {
            dialogId = 'transaction_p3_dialog_prototype';
          } else {
            dialogId = 'transaction_other_dialog_prototype';
          }
        } else {
          dialogId = 'transaction_charge_dialog_prototype';
        }
        break;
      case 'withdrawal':
        if (transaction.attributes.explanation) {
          dialogId = 'transaction_p3_dialog_prototype';
        } else {
          dialogId = 'transaction_other_dialog_prototype';
        }
        break;
      default:
        dialogId = 'transaction_other_dialog_prototype';
    }

    switch (dialogId) {
      case 'transaction_p3_dialog_prototype': {
        slidedownContent = (
          <div className="table-slidedown">
            {this.renderCardInfo()}
            {this.renderExplanation()}
            {this.renderAttachments()}
          </div>
        );

        break;
      }
      case 'transaction_other_dialog_prototype': {
        slidedownContent = (
          <div className="table-slidedown">
            {this.renderCardInfo()}
            <div className="new-form__section new-form--compact new-form__section--last relative">
              <div style={{ margin: '10px 0' }}>
                This is a {transaction.attributes.humanReadableType}. Please feel free to email us
                at {this.props.supportEmail} if you have any questions.
              </div>
            </div>
            {this.renderAttachments()}
          </div>
        );

        break;
      }
      case 'transaction_charge_dialog_prototype': {
        slidedownContent = (
          <div className="table-slidedown">
            {this.renderCardInfo()}
            {this.renderExplanation()}
            {this.renderSettings()}
            {this.renderAttachments()}
            {this.renderNotes()}
            {this.renderQDE()}
          </div>
        );

        break;
      }
      // No default
    }

    return (
      <tr className="table-detail-row" key={this.props.detailKey}>
        <td className="nopadding transaction-row-new" colSpan={6}>
          {slidedownContent}
        </td>
      </tr>
    );
  }
}

TransactionRowDetails.propTypes = {
  accountId: PropTypes.string.isRequired,
  cardCount: PropTypes.number.isRequired,
  closeDetail: PropTypes.func,
  detailKey: PropTypes.number.isRequired,
  hideClosedCardTransactionsEnabled: PropTypes.bool.isRequired,
  isCardholderView: PropTypes.bool,
  showNotes: PropTypes.bool.isRequired,
  showQDE: PropTypes.bool.isRequired,
  showReceipts: PropTypes.bool.isRequired,
  supportEmail: PropTypes.string.isRequired,
  transaction: CardTransactionShape.isRequired,
};
