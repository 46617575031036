import axios from 'axios';
import _extend from 'underscore/modules/extend';
import _keys from 'underscore/modules/keys';
import DashboardActions from 'react/member/actions/dashboard_actions';

const DashboardRulesetStore = flux.createStore({
  pendingRules: [],
  mccLabels: {},
  notificationPrefs: {},
  contactInfo: {},
  ruleset: {},
  merchantNameClusters: [],
  additionalEmails: [],
  userEmails: [],
  accountSlug: '',
  programName: '',
  allowsFamilyFunder: false,
  showBudgetSettings: false,
  showCurfewSettings: false,
  showClosedCardCheckbox: false,
  warnRules: {},
  blockRules: {},

  actions: [
    DashboardActions.fetchAccountInfo,
    DashboardActions.fetchRulesetInfo,
    DashboardActions.fetchOrganizationInfo,
    DashboardActions.fetchMerchantNameClusters,
    DashboardActions.destroyRule,
    DashboardActions.addAdditionalEmail,
    DashboardActions.removeAdditionalEmail,
    DashboardActions.updateNotificationPreference,
    DashboardActions.updateMobileForNotification,
  ],

  fetchAccountInfo(account_id) {
    const url = RailsRoutes.dashboard_account_path({
      id: account_id,
      format: 'json',
    });

    $.get(url, (data) => {
      this.notificationPrefs = data.data.attributes.notificationPreferences;
      this.contactInfo = data.data.meta.contactInfo || {};
      this.additionalEmails = data.data.attributes.additionalEmails;
      this.accountSlug = account_id;
      this.programName = data.data.meta.locale.programName;
      this.allowsFamilyFunder = data.data.meta.allowsFamilyFunder;
      this.showClosedCardCheckbox = data.data.meta.showClosedCardCheckbox;
      this.emit('accountInfo.fetch');
    });
  },

  fetchRulesetInfo(account_id) {
    const url = RailsRoutes.dashboard_account_ruleset_path({
      account_id,
      format: 'json',
    });

    $.get(url, (data) => {
      this.blockRules = data.data.attributes.blockRules;
      this.budgetAmount = data.data.attributes.budgetAmount;
      this.budgetPeriod = data.data.attributes.budgetPeriod;
      this.mccLabels = data.data.meta.mccLabels;
      this.pendingRules = data.data.meta.pendingRules;
      this.remainingBudgetAmount = data.data.meta.remainingBudgetAmount;
      this.warnRules = data.data.attributes.warnRules;
      this.emit('rulesetInfo.fetch');
    });
  },

  fetchOrganizationInfo(account_id) {
    const url = RailsRoutes.dashboard_account_find_organization_path({
      account_id,
      format: 'json',
    });

    $.get(url, (data) => {
      this.userEmails = data.data.meta.userEmails;
      this.showBudgetSettings = data.data.meta.showBudgetSettings;
      this.showCurfewSettings = data.data.meta.showCurfewSettings;
      this.emit('organizationInfo.fetch');
    });
  },

  destroyRule(checkedRules, ruleType) {
    const destroyUrl = RailsRoutes.dashboard_clear_rules_path();
    const maxTxRule = {};
    const ruleKey = Object.keys(checkedRules)[0];
    const value = checkedRules[ruleKey];
    maxTxRule[value] = '';
    if (ruleType.match(/merchant/i)) {
      checkedRules[`maxTransactionSizeBy${ruleType}`] = maxTxRule;
    } else {
      checkedRules['maxTransactionSizeByMCC'] = maxTxRule;
    }
    $.post(
      destroyUrl,
      checkedRules,
      (data) => {
        this.blockRules = data.block_rules;
        this.emit('ruleset.destroyComplete');
      },
      'json',
    );
  },

  fetchMerchantNameClusters() {
    const url = RailsRoutes.dashboard_merchant_name_clusters_path({
      format: 'json',
    });

    $.get(url, (data) => {
      this.merchantNameClusters = data.merchant_name_clusters;
      this.emit('merchantNameClusters.fetch');
    });
  },

  updateNotificationPreference(accountId, changedPref) {
    const url = RailsRoutes.dashboard_account_notification_preferences_path(accountId, {
      format: 'json',
    });

    $.ajax({
      data: changedPref,
      url,
      method: 'POST',
    }).done((response) => {
      this.notificationPrefs = response.notification_preferences;
      const pref = _keys(changedPref)[0];
      this.emit(`account.notificationPreferences.${pref}.updated`);
      this.emit('account.notificationPreferences.updated');
      Truelink.flash('success', 'Notification Preferences updated!', false, true);
    });
  },

  addAdditionalEmail(accountId, newAdditionalEmail) {
    const url = RailsRoutes.dashboard_additional_emails_path(accountId, {
      format: 'json',
    });
    const formData = [
      {
        name: 'account[email]',
        value: newAdditionalEmail,
      },
      {
        name: 'change',
        value: 'add',
      },
    ];

    $.ajax({
      data: formData,
      url,
      method: 'PUT',
    })
      .done((response) => {
        Truelink.flash('success', 'Email address added!', false, true);
        this.additionalEmails = response.result;
        this.emit('account.additionalEmails.updated');
      })
      .fail(() => {
        this.emit('account.additionalEmails.error');
      });
  },

  removeAdditionalEmail(accountId, existingAdditionalEmail) {
    const url = RailsRoutes.dashboard_additional_emails_path(accountId, {
      format: 'json',
    });

    const formData = [
      {
        name: 'account[email]',
        value: existingAdditionalEmail,
      },
      {
        name: 'change',
        value: 'remove',
      },
    ];

    $.ajax({
      data: formData,
      url,
      method: 'PUT',
    }).done((response) => {
      Truelink.flash('success', 'Email address removed!', false, true);
      this.additionalEmails = response.result;
      this.emit('account.additionalEmails.updated');
    });
  },

  updateMobileForNotification(accountSlug, newMobile) {
    const url = RailsRoutes.dashboard_update_mobile_path(accountSlug, {
      format: 'json',
    });
    const params = {
      person: {
        mobile: newMobile,
      },
    };

    axios
      .put(url, params)
      .then((arg) => {
        const data = arg.data;
        this.contactInfo = _extend(this.contactInfo, {
          mobile: data.mobile,
        });
        this.emit('person.mobile.updated');
        Truelink.flash('success', 'Update successful!');
      })
      .catch(() => {
        this.emit('person.mobile.updated.error');
      });
  },

  exports: {
    getPendingRules() {
      return this.pendingRules;
    },
    getMccLabels() {
      return this.mccLabels;
    },
    getNotificationPrefs() {
      return this.notificationPrefs;
    },
    getContactInfo() {
      return this.contactInfo;
    },
    getRuleset() {
      return this.ruleset;
    },
    updateRuleset(ruleset) {
      this.ruleset = ruleset;
      return this.emit('ruleset.updated');
    },
    updateWarnRules(warnRules) {
      this.warnRules = warnRules;
      return this.emit('warnRules.updated');
    },
    getMerchantNameClusters() {
      return this.merchantNameClusters;
    },
    getAccountSlug() {
      return this.accountSlug;
    },
    getAdditionalEmails() {
      return this.additionalEmails;
    },
    getUserEmails() {
      return this.userEmails;
    },
    getProgramName() {
      return this.programName;
    },
    getAllowsFamilyFunder() {
      return this.allowsFamilyFunder;
    },
    getShowClosedCardCheckbox() {
      return this.showClosedCardCheckbox;
    },
    getShowBudgetSettings() {
      return this.showBudgetSettings;
    },
    getShowCurfewSettings() {
      return this.showCurfewSettings;
    },
    getWarnRules() {
      return this.warnRules;
    },
    getBlockRules() {
      return this.blockRules;
    },
    getBudgetAmount() {
      return this.budgetAmount;
    },
    getBudgetPeriod() {
      return this.budgetPeriod;
    },
    getRemainingBudgetAmount() {
      return this.remainingBudgetAmount;
    },
    updateBlockRules(blockRules) {
      this.blockRules = blockRules;
      this.emit('blockRules.updated');
    },
    updateBudgetAmount(budget_amount) {
      this.budgetAmount = budget_amount;
    },
    updateBudgetPeriod(budget_period) {
      this.budgetPeriod = budget_period;
    },
    updateRemainingBudgetAmount(calculate_budget_remaining_amt) {
      this.remainingBudgetAmount = calculate_budget_remaining_amt;
    },
  },
});

export default DashboardRulesetStore;
