import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import _contains from 'underscore/modules/contains';
import _isEmpty from 'underscore/modules/isEmpty';
import ToggleLine from './ToggleLine';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import programNameShape from 'react/shared/shapes/account_program_name_shape';
import { isAbleAccount } from 'react/shared/utils/account_program_type';

export default function SpendingCategories({
  blockRules,
  addBlockRules,
  removeBlockRules,
  programName,
}) {
  const [modal, setModal] = useState(null);
  const handleGasStationEdgeCase = () => {
    const cardholderName = $('#current_cardholder_name').text();
    const title = 'You have chosen to block gas purchases inside gas stations';
    const message = (
      <>
        <p>
          If you plan to allow gas purchases at the pump, please note that ${cardholderName} will
          need to have between $50 - $150 on their True Link Card due to funds pre-authorization
          requirements for gas purchases at the pump.
        </p>
        <p style={{ marginTop: '12px' }}>
          To avoid this pre-authorization funds requirement, please allow purchases inside the gas
          station.
        </p>
      </>
    );
    setModal({ title, message });
  };

  const handleGroceryStoreEdgeCase = () => {
    const title = 'Do you want to allow Walmart or Target?';
    const message = (
      <>
        <p>
          Blocking Grocery Store purchases may mean that purchases at Walmart or Target are
          occasionally blocked, since sometimes Walmart and Target identify themselves as Grocery
          Stores in the Visa system.
        </p>
        <p style={{ marginTop: '12px' }}>
          To make sure that purchases at Walmart or Target are allowed, scroll up and click "Add
          Merchant Setting". Then you can select to allow all purchases at Walmart or Target.
        </p>
      </>
    );
    setModal({ title, message });
  };

  const handleCharityStoreEdgeCase = () => {
    const title = 'You have blocked purchases at Charities.';
    const message = (
      <>
        <p>
          This setting may mean that purchases at Salvation Army stores or Goodwill stores are
          occasionally blocked, as sometimes they identify themselves as Charities in the Visa
          system.
        </p>
        <p style={{ marginTop: '12px' }}>
          To make sure that purchases at these stores are allowed, scroll up and click "Add Merchant
          Setting". Then you can select to allow all purchases at "Salvation Army" or "Goodwill".
        </p>
      </>
    );
    setModal({ title, message });
  };

  const disableAllowedOnlineExceptionCategory = (ruleValue) => {
    const { allowlistSemantics: allowlistAllowedExceptions = [] } = blockRules;

    return _contains(allowlistAllowedExceptions, ruleValue)
      ? {
          disabled: true,
          hoverText:
            "You've already allowed this category in the Top-line Protections section of your Spending Monitor",
        }
      : {};
  };

  const disableBlockedCategoryForAbleAccounts = (ruleText) =>
    isAbleAccount(programName)
      ? {
          disabled: true,
          hoverText: `Your ABLE Card does not allow purchases at merchants in spending category: ${ruleText}`,
        }
      : {};

  const spendingCategoryConfig = [
    {
      text: 'Auto Repair & Dealers',
      rules: { denylistSemantics: 'AUTO_REPAIR_AND_DEALERS' },
      detailText: 'Examples: Auto dealers, auto repair shops, tire stores, towing services',
    },
    {
      text: 'Bars & Liquor Stores',
      rules: { denylistSemantics: 'ALCOHOL', denylistMerchants: 'LIQUOR' },
      detailText: 'Examples: Bars, liquor stores, nightclubs',
    },
    {
      text: 'Beauty',
      rules: { denylistSemantics: 'BEAUTY' },
      detailText: 'Examples: Beauty salons, barber shops, spas',
    },
    {
      text: 'Charitable Donations',
      rules: { denylistSemantics: 'CHARITY' },
      detailText: 'Examples: Charities, donations to political or religious organizations',
      checkedCallback: handleCharityStoreEdgeCase,
    },
    {
      text: 'Dating & Escort',
      rules: { denylistSemantics: 'DATING_AND_ESCORT' },
      detailText: 'Examples: eHarmony, match.com, escort services',
    },
    {
      text: 'Direct Sales',
      rules: { denylistSemantics: 'DIRECT_SALES' },
      detailText: 'Examples: Telemarketers, mail order, door-to-door sales',
    },
    {
      text: 'Discount & Variety Stores',
      rules: { denylistSemantics: 'DISCOUNT_AND_VARIETY_STORES' },
      detailText: 'Examples: Costco, Dollar General, Michaels',
    },
    {
      text: 'Education',
      rules: { denylistSemantics: 'EDUCATION' },
      detailText: 'Examples: University fees, vocational schools, college bookstore',
    },
    {
      text: 'Financial Transactions',
      rules: { denylistSemantics: 'FINANCIAL_TRANSACTIONS' },
      detailText: 'Examples: Security brokers, credit reporting',
    },
    {
      text: 'Grocery Stores',
      rules: { denylistSemantics: 'FOOD_GROCERY' },
      detailText: 'Examples: Supermarkets, bakeries, butchers',
      checkedCallback: handleGroceryStoreEdgeCase,
    },
    {
      text: 'Gambling & Casinos',
      rules: { denylistSemantics: 'GAMBLING' },
      detailText: 'Examples: Casinos, bingo halls, race tracks',
      ...disableBlockedCategoryForAbleAccounts('Gambling & Casinos'),
    },
    {
      text: 'Gas Stations - Inside',
      rules: { denylistSemantics: 'GAS_STATION_INSIDE' },
      detailText: 'Examples: Chevron Food Mart, Mobil On the Run, indoor cashier',
      checkedCallback: handleGasStationEdgeCase,
    },
    {
      text: 'Gas Stations - At Pump',
      rules: { denylistSemantics: 'GAS_STATION_PUMP' },
      detailText: 'Examples: Pay at the pump',
    },
    {
      text: 'Hearing Aids',
      rules: { denylistSemantics: 'HEARING_AIDS' },
      detailText: 'Example: Hearing aid stores and suppliers',
    },
    {
      text: 'Home Repair & Maintenance',
      rules: { denylistSemantics: 'HOME_REPAIR' },
      detailText: 'Examples: Contractors, gardeners, carpenters',
    },
    {
      text: 'Hotels',
      rules: { denylistSemantics: 'HOTELS' },
      detailText: 'Examples: hotels',
    },
    {
      text: 'Insurance',
      rules: { denylistSemantics: 'INSURANCE' },
      detailText: 'Example: Insurance companies or brokers',
    },
    {
      text: 'Jewelry',
      rules: { denylistSemantics: 'JEWELRY' },
      detailText: 'Example: Jewelry stores',
    },
    {
      text: 'Laundry',
      rules: { denylistSemantics: 'CLEANING_SERVICES' },
      detailText: 'Examples: Laundromats, dry cleaners',
    },
    {
      text: 'Local Transit',
      rules: { denylistSemantics: 'LOCAL_TRANSIT' },
      detailText: 'Examples: Public transportation, taxis, tolls, parking lots, garages',
    },
    {
      text: 'Medical & Care',
      rules: { denylistSemantics: 'MEDICAL_AND_CARE' },
      detailText:
        "Examples: Doctor's offices, ambulances, medical devices, hospitals, labs, eyewear stores",
      ...disableAllowedOnlineExceptionCategory('MEDICAL_AND_CARE'),
    },
    {
      text: 'Money Transfers',
      rules: { denylistSemantics: 'MONEY_TRANSFERS' },
      detailText: 'Examples: Western Union, USPS Money Orders, Money Gram',
      ...disableBlockedCategoryForAbleAccounts('Money Transfers'),
    },
    {
      text: 'Pawn Shops',
      rules: { denylistSemantics: 'PAWN_SHOPS' },
      detailText: 'Example: Pawn shops',
    },
    {
      text: 'Pets',
      rules: { denylistSemantics: 'PETS' },
      detailText: 'Examples: Veterinarians, pet supply stores',
    },
    {
      text: 'Pharmacy',
      rules: { denylistSemantics: 'PHARMACY' },
      detailText: 'Examples: Walgreens, CVS, Rite Aid, drug stores, pharmacies',
    },
    {
      text: 'Phone, TV & Cable',
      rules: { denylistSemantics: 'PHONE_TV_CABLE' },
      detailText: 'Examples: Phone bills, television bills, cable bills',
      ...disableAllowedOnlineExceptionCategory('PHONE_TV_CABLE'),
    },
    {
      text: 'Professional Services',
      rules: { denylistSemantics: 'PROFESSIONAL_SERVICES' },
      detailText: 'Examples: Attorneys, storage facilities, printing services, moving companies',
    },
    {
      text: 'Recreation & Entertainment',
      rules: { denylistSemantics: 'RECREATION_AND_ENTERTAINMENT' },
      detailText: 'Examples: Movie theaters, bowling alleys, sports clubs, amusement parks',
    },
    {
      text: 'Restaurants',
      rules: { denylistSemantics: 'FOOD_RESTAURANT' },
      detailText: 'Example: Fast food restaurants, fine dining, caterers',
      ...disableAllowedOnlineExceptionCategory('FOOD_RESTAURANT'),
    },
    {
      text: 'Shopping',
      rules: { denylistSemantics: 'SHOPPING' },
      detailText: "Example: Sears, Best Buy, Macy's",
    },
    {
      text: 'Smoke Shops',
      rules: { denylistSemantics: 'SMOKE_SHOPS' },
      detailText: 'Examples: Cigar stores, pipe stores, tobacco shops',
    },
    {
      text: 'Subscriptions',
      rules: { denylistSemantics: 'PERIODICALS' },
      detailText: 'Examples: Magazines, music and tv streaming, Amazon Prime',
    },
    {
      text: 'Taxes & Government',
      rules: { denylistSemantics: 'FEES_AND_TAXES' },
      detailText:
        'Examples: State universities, court costs, taxes, bail and bond fees, alimony and child support',
    },
    {
      text: 'Travel & Transportation',
      rules: { denylistSemantics: 'TRAVEL_AND_TRANSPORTATION' },
      detailText: 'Examples: Airlines, car rentals, bus lines, trains, cruise lines',
    },
    {
      text: 'Utilities',
      rules: { denylistSemantics: 'UTILITIES' },
      detailText: 'Examples: Gas bills, electricity bills, water bills',
      ...disableAllowedOnlineExceptionCategory('UTILITIES'),
    },
  ];

  const renderCell = (categoryProps) => {
    if (_isEmpty(categoryProps)) return <td />;

    const ruleKey = Object.keys(categoryProps.rules)[0];
    const ruleDefinition = categoryProps.rules[ruleKey].split(';')[0];

    const rulesetSelections = blockRules[ruleKey] || [];
    const blocked = _contains(rulesetSelections, ruleDefinition);

    return (
      <td>
        <ToggleLine
          flex
          hr={false}
          is_checked={blocked}
          updateChecked={addBlockRules}
          updateUnChecked={removeBlockRules}
          {...categoryProps}
        />
      </td>
    );
  };

  const handleDismissModal = useCallback(() => setModal(null), []);

  return (
    <>
      {modal && (
        <PopUp
          footer={
            <TrueLinkButton onClick={handleDismissModal} variant="primary">
              Okay
            </TrueLinkButton>
          }
          header={modal.title}
          hideDismissButton
          maxWidth="600px"
          onClose={handleDismissModal}
          openModal
        >
          {modal.message}
        </PopUp>
      )}
      <table className="categories_table" style={{ marginBottom: '15px' }}>
        <tbody>
          {spendingCategoryConfig.map((_, index) => {
            if (index % 2 !== 0) {
              return null;
            }

            return (
              <tr key={`spending-categories-row-${index / 2}`}>
                {renderCell(spendingCategoryConfig[index])}
                {renderCell(spendingCategoryConfig[index + 1])}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

SpendingCategories.propTypes = {
  blockRules: PropTypes.object.isRequired,
  addBlockRules: PropTypes.func.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
  programName: programNameShape.isRequired,
};
