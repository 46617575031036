import PropTypes from 'prop-types';
import React from 'react';
import Toggle from 'react/shared/card/components/Toggle';
import SavedMessage from 'react/shared/components/SavedMessage';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import bindAll from 'react/shared/utils/bind_all';

export default class SpecificRuleLine extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  handleChange(e) {
    if (e.target.checked) {
      this.props.addBlockRules(this.props.checkedRules, this);
    } else {
      this.props.addBlockRules(this.props.unCheckedRules, this);
    }
  }

  handleEditClick() {
    this.props.showEditModal(this);
  }

  limitText() {
    let text;
    if (this.props.is_checked) {
      text = '';
    } else if (this.props.limit) {
      text = `Limit: $${this.props.limit}`;
    } else {
      text = 'No limit';
    }
    return text;
  }

  render() {
    const { ruleType, text } = this.props;
    const id = `specific_rule_${text.replace(/[^a-z0-9]/gi, '_')}_${ruleType.replace(
      /[^a-z0-9]/gi,
      '_',
    )}`;
    return (
      <tr>
        <td>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="toggle_label" htmlFor={id}>
            <Toggle checked={this.props.is_checked} id={id} onChange={this.handleChange} />
          </label>
        </td>
        <td>
          {text}
          <SavedMessage />
        </td>
        <td>{this.limitText()}</td>
        <td style={{ paddingRight: '15px' }}>
          <TrueLinkLink onClick={this.handleEditClick}>Edit</TrueLinkLink>
        </td>
      </tr>
    );
  }
}

SpecificRuleLine.propTypes = {
  addBlockRules: PropTypes.func.isRequired,
  limit: PropTypes.number,
  showEditModal: PropTypes.func.isRequired,
  is_checked: PropTypes.bool.isRequired,
  checkedRules: PropTypes.object.isRequired,
  unCheckedRules: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  ruleType: PropTypes.string,
};
