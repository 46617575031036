import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import CalIcon from 'images/referrals/cal-icon.png';
import CardOverMobileIcon from 'images/referrals/card-over-mobile.png';
import CardWithLockIcon from 'images/referrals/card-with-lock.png';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    backgroundColor: PALETTE.paleAqua,
    borderTop: '1px solid rgba(0,0,0,.1)',
    padding: '5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 2rem',
    },
  },
  container: {
    margin: '0 auto',
    maxWidth: '70rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '0.5rem',
    fontFamily: 'Tiempos headline,Times New Roman,sans-serif',
    fontWeight: '300',
  },
  subHeader: {
    textAlign: 'center',
    marginBottom: '0.5rem',
    fontFamily: '"Calibre", sans-serif',
    fontWeight: '300',
  },
  content: {
    fontFamily: 'Tiempos Headline',
    fontSize: '35px',
  },
  items: {
    marginTop: '48px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  text: {
    marginBottom: '0',
    width: '320px',
  },
  img: {
    width: '94px',
    height: '79px',
    objectFit: 'contain',
    marginBottom: '1rem',
  },
}));

const cardsContent = [
  {
    icon: CardOverMobileIcon,
    altText: 'card over mobile icon',
    title: (
      <p>
        An easy to fund <br />
        prepaid Visa card
      </p>
    ),
    content:
      'Easy to set up and fund with scheduled or one-time transfers from an existing bank account.',
  },
  {
    icon: CardWithLockIcon,
    altText: 'lock card icon',
    title: (
      <p>
        Customizable <br />
        spending rules
      </p>
    ),
    content:
      'Set where the Visa card works and where it doesn’t to help protect spending and help prevent fraud.',
  },
  {
    icon: CalIcon,
    altText: 'calendar icon',
    title: (
      <p>
        Visibility into spending <br />
        with alerts and history
      </p>
    ),
    content:
      'Get real-time alerts to stay informed of recent purchases and generate reports to keep track of spending history.',
  },
];

export default function HowItWorksCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.section} data-testid="howItWorksCard">
      <div className={classes.container}>
        <Typography className={classes.header} variant={props.desktop ? 'h1' : 'h2'}>
          How it works
        </Typography>
        {!props.isCareAgencyExperiment && (
          <Typography className={classes.subHeader} variant="body1">
            For a $12 monthly fee*, get peace of mind with the True Link Visa Card and Spending
            Monitor.
          </Typography>
        )}
        <Grid
          alignItems="center"
          className={classes.items}
          container
          item
          justifyContent="center"
          spacing={8}
        >
          {cardsContent.map((item, index) => (
            <Grid className={classes.item} item key={index} sm={4} xs={12}>
              <img alt={item.altText} className={classes.img} src={item.icon} />
              <Typography component="div" gutterBottom variant="h5">
                {item.title}
              </Typography>
              <Typography className={classes.text} variant="body1">
                {item.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

HowItWorksCard.propTypes = {
  desktop: PropTypes.bool,
  isCareAgencyExperiment: PropTypes.bool,
};
