import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AmountCell from './AmountCell';
import EditableCell from './EditableCell';
import api from './api';
import DatePostedCell from 'react/member/components/dashboard/deposits/individual_trusts/DatePostedCell';
import TrueLinkTable from 'react/shared/components/true_link/main/table/TrueLinkTable';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function IndividualDepositsTab({ clientSlug }) {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateWealthAccountEvent = useCallback(
    (rowIndex, columnId, value) => {
      const wealthAccountEvent = deposits[rowIndex];

      wealthAccountEvent[columnId] = value;
      api.putWealthAccountEvent(clientSlug, wealthAccountEvent);
      Truelink.flash('success', 'Deposit Updated!');
    },
    [clientSlug, deposits],
  );

  useEffect(() => {
    api.getWealthAccountEvents(clientSlug).then(({ data }) => {
      setDeposits(data);
      setLoading(false);
    });
  }, [clientSlug]);

  const columns = useMemo(
    () => [
      {
        Header: 'Process Date',
        accessor: 'date_posted',
        Cell: DatePostedCell,
      },
      {
        Header: 'Type',
        accessor: 'type_description',
      },
      {
        Header: 'Description',
        accessor: (row) => ({ key: 'memo', memo: row.memo, updateWealthAccountEvent }),
        Cell: EditableCell,
      },
      {
        Header: 'Amount',
        accessor: (row) => tlFieldTransformers.formatMoney(row.amount),
        Cell: AmountCell,
      },
    ],
    [updateWealthAccountEvent],
  );

  return (
    <div>
      <TrueLinkTable
        columns={columns}
        data={deposits}
        initialSortBy={[{ id: 'date_posted', desc: true }]}
        loading={loading}
        minColumnWidth={5}
        noDataText="No data available in table"
        pageSize={20}
        paginated
        sortable
      />
    </div>
  );
}

IndividualDepositsTab.propTypes = {
  clientSlug: PropTypes.string.isRequired,
};
