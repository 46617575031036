import { CARD_ORDER_TYPES } from 'react/shared/utils/CardOrderType';

export const isCardLostOrStolen = (card) =>
  !!card && ['LOST_CARD', 'STOLEN_CARD'].includes(card.status);

export const isCardAwaitingReplacement = (card) =>
  isCardLostOrStolen(card) && card?.card_reissue?.type === CARD_ORDER_TYPES.REISSUE_A;

export const isCardClosed = (card) => !!card && card.status === 'CLOSED';

export const isCardClosing = (card) => !!card && card.has_open_card_close_request;

export const isCardActivated = (card) => !!card && card.is_active && card.has_been_activated;

export const isCardOpen = (card) => isCardActivated(card) && card.status === 'OPEN';

export const isCardInactive = (card) => isCardActivated(card) && card.status === 'INACTIVE';
