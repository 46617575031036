import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AccountReceipts from './account_receipts/AccountReceipts';
import api from './api.js';
import DetailedTransactionReportSection from './detailed_transaction_report/DetailedTransactionReportSection';
import MonthlyStatements from './monthly_statements/MonthlyStatements';
import DashboardInProductAd from 'react/member/components/in_product_ads/DashboardInProductAd';
import InProductAdShape from 'react/member/shapes/in_product_ad_shape';
import BlockedChargesReportSection from 'react/shared/card/components/reports_tab/blocked_charges_report/BlockedChargesReportSection';
import TransactionReportSection from 'react/shared/card/components/reports_tab/transaction_report/TransactionReportSection';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import { isAbleActAccount, isRepPayee } from 'react/shared/utils/account_card_program';

export default function CardReportsTabContainer({
  accountId,
  canEdit,
  inProductAd,
  monthlyStatementDates,
}) {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    api.getAccount(accountId).then((response) => setAccount(response.data.data));
  }, [accountId]);

  if (account === null) return <LoadingIndicator />;

  const { cardProgramNumber } = account.attributes;
  const { showAccountReceipts } = account.meta;

  const enableBlockedChargesReport = !isRepPayee(cardProgramNumber);
  const enableReceipts = showAccountReceipts && !isAbleActAccount(cardProgramNumber);
  const enableQDEReport = isAbleActAccount(cardProgramNumber);

  return (
    <>
      {inProductAd && <DashboardInProductAd inProductAd={inProductAd} />}
      <div id="dashboard_reports">
        <TransactionReportSection accountId={accountId} isCardholder={false} />
        {enableBlockedChargesReport && <BlockedChargesReportSection accountId={accountId} />}
        <MonthlyStatements
          isCardholder={false}
          monthlyStatementDates={monthlyStatementDates}
          showIncludeReceiptsCheckbox={enableReceipts}
        />
        {enableReceipts && <AccountReceipts accountId={accountId} canEdit={canEdit} />}
        {enableQDEReport && <DetailedTransactionReportSection accountId={accountId} />}
      </div>
    </>
  );
}

CardReportsTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  inProductAd: InProductAdShape,
  monthlyStatementDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      card_slug: PropTypes.string.isRequired,
      last_4_digits: PropTypes.string.isRequired,
    }),
  ),
};
