import PropTypes from 'prop-types';
import React from 'react';
import CardNotPresentExceptions from './CardNotPresentExceptions';
import ToggleLine from './ToggleLine';
import programNameShape from 'react/shared/shapes/account_program_name_shape';
import { isAbleAccount } from 'react/shared/utils/account_program_type';
import bindAll from 'react/shared/utils/bind_all';

export default class TopLineProtections extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  handleEdgeCases() {
    // When CardNotPresent block rule toggle is unchecked, remove all the exception rules
    const rulesToRemove = {
      allowlistSemantics: 'UTILITIES;MEDICAL_AND_CARE;FOOD_RESTAURANT;PHONE_TV_CABLE',
      maxTransactionSizeBySemantic: {
        UTILITIES: '',
        MEDICAL_AND_CARE: '',
        FOOD_RESTAURANT: '',
        PHONE_TV_CABLE: '',
      },
    };
    this.props.removeBlockRules(rulesToRemove, null);
  }

  disableInternationalPurchasesForABLE() {
    return isAbleAccount(this.props.programName)
      ? {
          disabled: true,
          hoverText: 'Your ABLE Card does not allow international purchases',
        }
      : {};
  }

  render() {
    const block_rules = this.props.block_rules;
    const hide_exceptions = !block_rules['blockCardNotPresent'];

    return (
      <div className="top-line-protections-container">
        <ToggleLine
          detailText="The ScamWatch list contains merchants whose behavior has been flagged by multiple users and therefore is likely fraudulent. We recommend blocking these."
          is_checked={!!block_rules['blockScamWatch']}
          name="blockScamWatch"
          rules={{ blockScamWatch: true }}
          text="Merchants on True Link's ScamWatch list"
          updateChecked={this.props.addBlockRules}
          updateUnChecked={this.props.removeBlockRules}
        />
        <ToggleLine
          hr={false}
          is_checked={!!block_rules['blockCardNotPresent']}
          name="blockCardNotPresent"
          rules={{ blockCardNotPresent: true, denylistMerchants: 'PAYPAL' }}
          text="Online and phone purchases"
          uncheckedCallback={this.handleEdgeCases}
          updateChecked={this.props.addBlockRules}
          updateUnChecked={this.props.removeBlockRules}
        />
        <CardNotPresentExceptions
          addBlockRules={this.props.addBlockRules}
          blockRules={block_rules}
          hidden={hide_exceptions}
          removeBlockRules={this.props.removeBlockRules}
        />
        <hr />
        <ToggleLine
          is_checked={!!block_rules['blockInternationalTransactions']}
          name="blockInternationalTransactions"
          rules={{ blockInternationalTransactions: true }}
          text="International transactions"
          updateChecked={this.props.addBlockRules}
          updateUnChecked={this.props.removeBlockRules}
          {...this.disableInternationalPurchasesForABLE()}
        />
      </div>
    );
  }
}

TopLineProtections.propTypes = {
  block_rules: PropTypes.object.isRequired,
  addBlockRules: PropTypes.func,
  removeBlockRules: PropTypes.func,
  programName: programNameShape,
};
