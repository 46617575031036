import PropTypes from 'prop-types';

export const bulkRulesetChangeShape = PropTypes.shape({
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      account_id: PropTypes.number,
    }),
  ).isRequired,
  amount: PropTypes.string,
  apply_to_default_spending_monitor: PropTypes.bool,
  applied_at: PropTypes.string,
  id: PropTypes.number,
  merchant: PropTypes.string,
  rule: PropTypes.string,
});
