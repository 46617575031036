import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkChip from 'react/shared/components/true_link/main/TrueLinkChip';

export default function DatePostedCell({ value: datePostedString, row }) {
  return (
    <div>
      <span>{moment(datePostedString).format('MM/DD/YYYY')}</span>
      {row.original.has_notification && <TrueLinkChip color="primary" label="New" size="small" />}
    </div>
  );
}

DatePostedCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
};
