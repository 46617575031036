import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import AccountMerchantSettingsModal from './AccountMerchantSettingsModal';
import DatatableLite from './DatatableLite';
import SpendingMonitorDetailsModal from './SpendingMonitorDetailsModal';
import { formatRuleDisplay } from 'react/member/utils/Ruleset';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class BulkOperationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dtClassRef: 'bulk-operations-history',
      selectedAccountCount: 0,
      selectedAccounts: [],
      allAccountsSelected: false,
      bulkRulesetChange: undefined,
      showDetailsModal: false,
      showAccountMerchantSettingsModal: false,
    };

    bindAll(this);
  }

  columns() {
    return [
      <th className="js-datatable-col-date" key="datatable-date">
        Date
      </th>,
      <th className="js-datatable-col-merchant" key="datatable-mechant">
        Merchant
      </th>,
      <th className="js-datatable-col-setting" key="datatable-setting">
        Setting
      </th>,
      <th className="js-datatable-col-number-cards" key="datatable-number-cards">
        Cards
      </th>,
      <th className="js-datatable-col-applied-by" key="datatable-applied-by">
        Applied By
      </th>,
      <th className="js-datatable-col-details" key="datatable-details">
        Details
      </th>,
    ];
  }

  columnDefs() {
    return [
      {
        targets: ['js-datatable-col-date'],
        sortable: true,
        width: '20%',
        data: 'date',
        render: (data, type, record) => moment(record.applied_at).format('YYYY-MM-DD, LT'),
      },
      {
        targets: ['js-datatable-col-merchant'],
        sortable: true,
        width: '15%',
        data: 'merchant',
        render: (data, type, record) => record.merchant,
      },
      {
        targets: ['js-datatable-col-setting'],
        sortable: true,
        width: '25%',
        data: 'setting',
        render: (data, type, record) => formatRuleDisplay(record),
      },
      {
        targets: ['js-datatable-col-number-cards'],
        width: '10%',
        data: 'number-cards',
        render: (data, type, record) => record.accounts.length,
      },
      {
        targets: ['js-datatable-col-applied-by'],
        width: '15%',
        data: 'applied-by',
        render: (data, type, record) => record.user.full_name,
      },
      {
        targets: ['js-datatable-col-details'],
        sortable: false,
        width: '10%',
        data: 'details',
        render: (data, type, record) =>
          `<a href='${RailsRoutes.bulk_ruleset_change_path({
            id: record.id,
          })}' class='display_details'>Details</a>`,
      },
    ];
  }

  route() {
    return RailsRoutes.bulk_ruleset_changes_path({ format: 'json' });
  }

  initialSort() {
    return [[0, 'desc']];
  }

  reDrawDatatable() {
    $(`.${this.state.dtClassRef}`).DataTable().ajax.reload();
  } // For questions, see end of file note.

  closeDetailsModal() {
    this.setState({ showDetailsModal: false });
  }

  openAccountMerchantSettingsModal({ prePopulate = false }) {
    if (prePopulate) {
      const { bulkRulesetChange } = this.state;
      this.setState({
        showAccountMerchantSettingsModal: true,
        selectedAccounts: bulkRulesetChange.accounts,
        selectedAccountCount: bulkRulesetChange.accounts.length,
      });
    } else {
      this.setState({ showAccountMerchantSettingsModal: true });
    }
  }

  handleSelectAccounts(selectedAccounts, selectedAccountCount) {
    this.setState({ selectedAccounts, selectedAccountCount });
  }

  toggleAllAccountsSelect(allSelected) {
    this.setState({ allAccountsSelected: allSelected });
  }

  closeAccountMerchantSettingsModal() {
    this.setState({
      selectedAccountCount: 0,
      selectedAccounts: [],
      allAccountsSelected: false,
      showAccountMerchantSettingsModal: false,
    });
  }

  datatableEventListeners() {
    $('.display_details').click((ev) => {
      ev.preventDefault();
      const { href } = ev.target;
      axios
        .get(href)
        .then(({ data }) => this.setState({ bulkRulesetChange: data, showDetailsModal: true }));
    });
  }
  render() {
    const { showDefaultSpendingMonitorOption } = this.props;

    const {
      showAccountMerchantSettingsModal,
      showDetailsModal,
      dtClassRef,
      bulkRulesetChange,
      selectedAccountCount,
      selectedAccounts,
      allAccountsSelected,
    } = this.state;

    return (
      <div>
        <SubSectionHeader first>Spending Monitor</SubSectionHeader>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Add a merchant setting to multiple accounts' spending monitors</div>
            <TrueLinkButton
              className="btn normal"
              onClick={this.openAccountMerchantSettingsModal}
              variant="none"
            >
              Add merchant setting for multiple accounts
            </TrueLinkButton>
          </div>
          <div
            style={{
              marginTop: 15,
              borderTop: '1px solid #ddd',
              paddingTop: 15,
            }}
          >
            <div className="info-box--with-icon--no-background">
              Looking to update another spending monitor setting -- alerts, access to cash, spending
              limits, etc. -- on many cards? <br />
              Email us at support@truelinkfinancial.com or call us at 1-877-264-8783 and we'll take
              care of it.
            </div>
          </div>
        </div>
        <SubSectionHeader noMarginBottom>History</SubSectionHeader>
        <DatatableLite
          addEventListeners={this.datatableEventListeners}
          classRef={dtClassRef}
          columnDefs={this.columnDefs()}
          columns={this.columns()}
          dom="frtip"
          emptyTableText="No spending monitor history available"
          initialSort={this.initialSort()}
          pageLength={25}
          route={this.route()}
          selectStyle="api"
        />
        <AccountMerchantSettingsModal
          allAccountsSelected={allAccountsSelected}
          closeAccountMerchantSettingsModal={this.closeAccountMerchantSettingsModal}
          handleSelectAccounts={this.handleSelectAccounts}
          reDrawDatatable={this.reDrawDatatable}
          selectedAccountCount={selectedAccountCount}
          selectedAccounts={selectedAccounts}
          showAccountMerchantSettingsModal={showAccountMerchantSettingsModal}
          showDefaultSpendingMonitorOption={showDefaultSpendingMonitorOption}
          toggleAllAccountsSelect={this.toggleAllAccountsSelect}
        />
        <SpendingMonitorDetailsModal
          bulkRulesetChange={bulkRulesetChange}
          closeDetailsModal={this.closeDetailsModal}
          openAccountMerchantSettingsModal={this.openAccountMerchantSettingsModal}
          reDrawDatatable={this.reDrawDatatable}
          showDetailsModal={showDetailsModal}
        />
      </div>
    );
  }
}
// We needed a way to refresh the ajax and grab new data after adding/deleting a new merchant rule setting.
// This is because when the focus changes from the add/delete merchant rule modal, there's no re-render (and thus, no fetch of new data).
// So we need to reload it. -Thomas

BulkOperationsContainer.propTypes = {
  showDefaultSpendingMonitorOption: PropTypes.bool.isRequired,
};
