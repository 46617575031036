import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import { bulkRulesetChangeShape } from './BulkOperationsShape';
import DeleteSettingsConfirmation from './DeleteSettingsConfirmation';
import SpendingMonitorDetails from './SpendingMonitorDetails';
import { arrangeRulesetParams } from 'react/member/utils/Ruleset';
import PopUp from 'react/shared/components/popups/PopUp';
import bindAll from 'react/shared/utils/bind_all';
import { callAPI, getUrl } from 'react/shared/utils/call_api';

export default class SpendingMonitorDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.spendingMonitorDefaults();

    bindAll(this);
  }

  spendingMonitorDefaults() {
    return {
      showMonitorDetails: true,
      showDeleteSettings: false,
      modalHeader: 'Bulk spending monitor update details',
    };
  }

  closeDetailsModal() {
    this.setState(this.spendingMonitorDefaults());
    this.props.closeDetailsModal();
  }

  handleDeleteConfirmation() {
    const { accounts, id, rule, merchant, amount } = this.props.bulkRulesetChange;
    const accountIds = accounts.map((account) => account.account_id);
    const rulesetParams = _extend(arrangeRulesetParams(rule, merchant, amount), {
      bulk_ruleset_change_id: id,
      account_ids: accountIds,
    });
    const url = getUrl('bulk_remove_block_rules');
    callAPI(url, rulesetParams, null).then(() => {
      Truelink.flash('success', 'Successfully deleted bulk ruleset change!');
      window.setTimeout(() => Truelink.flashClear(), 5000);
      this.closeDetailsModal();
      this.props.reDrawDatatable();
    });
  }

  toggleDeleteMerchantSettings() {
    this.setState(
      (prevState) => ({
        showMonitorDetails: !prevState.showMonitorDetails,
        showDeleteSettings: !prevState.showDeleteSettings,
      }),
      () => this.configureModalHeader(),
    );
  }

  configureModalHeader() {
    const { showMonitorDetails } = this.state;
    const { accounts } = this.props.bulkRulesetChange;

    const modalHeader = showMonitorDetails
      ? 'Bulk spending monitor update details'
      : `Delete merchant setting for account${accounts.length > 1 ? 's' : ''}`;

    this.setState({ modalHeader });
  }

  render() {
    const { showDetailsModal, bulkRulesetChange, openAccountMerchantSettingsModal } = this.props;

    const { showDeleteSettings, showMonitorDetails, modalHeader } = this.state;

    const dismissBtnText = showMonitorDetails ? 'Close' : 'Cancel';

    return (
      <PopUp
        dismissBtnText={dismissBtnText}
        header={modalHeader}
        hideFooter
        maxWidth="35rem"
        modalBodyClass="modal-body--new-form modal-body--no-footer"
        onClose={this.closeDetailsModal}
        openModal={showDetailsModal}
      >
        <SpendingMonitorDetails
          bulkRulesetChange={bulkRulesetChange}
          closeDetailsModal={this.closeDetailsModal}
          openAccountMerchantSettingsModal={openAccountMerchantSettingsModal}
          showMonitorDetails={showMonitorDetails}
          toggleDeleteMerchantSettings={this.toggleDeleteMerchantSettings}
        />
        <DeleteSettingsConfirmation
          bulkRulesetChange={bulkRulesetChange}
          onDeleteConfirmation={this.handleDeleteConfirmation}
          showDeleteSettings={showDeleteSettings}
        />
      </PopUp>
    );
  }
}

SpendingMonitorDetailsModal.propTypes = {
  bulkRulesetChange: bulkRulesetChangeShape,
  closeDetailsModal: PropTypes.func.isRequired,
  openAccountMerchantSettingsModal: PropTypes.func.isRequired,
  reDrawDatatable: PropTypes.func.isRequired,
  showDetailsModal: PropTypes.bool.isRequired,
};
