import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DashboardCardStatus from 'react/member/card/components/DashboardCardStatus';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: PALETTE.grey,
  },
  headerInner: (multiAccounts) => ({
    width: '940px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 0px',
    paddingTop: multiAccounts ? '60px' : '30px',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
  }),
  accountRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '27px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  backButton: {
    position: 'absolute',
    top: '10px',
    fontSize: '17px',
    color: '#222',
    [theme.breakpoints.down('sm')]: {
      left: '5px',
    },
  },

  headerText: (multiAccounts) => ({
    fontWeight: 'bold',
    paddingLeft: '10px',
    [theme.breakpoints.down('md')]: {
      display: multiAccounts ? 'none' : 'inline',
    },
  }),
}));

export default function DashboardHeader(props) {
  const multiAccounts = props.allAccounts?.length > 0;
  const classes = useStyles(multiAccounts);
  const currentDashboard = props.allAccounts?.find((a) => a.slug == props.accountId);
  const [value, setValue] = React.useState(currentDashboard);
  const [inputValue, setInputValue] = React.useState('');

  const onChange = (_e, value) => {
    setValue(value);
    if (value.slug) window.location.href = `/member/dashboard/${value.slug}`;
  };

  const allCardAccountsSelect = (
    <Autocomplete
      disableClearable
      getOptionLabel={(option) =>
        option.nickname ? `${option.cardholder_name} (${option.nickname})` : option.cardholder_name
      }
      id="select_account"
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={props.allAccounts || []}
      renderInput={(params) => <TextField {...params} label="Card Accounts" variant="outlined" />}
      renderOption={(props, option) => (
        <li {...props} key={option.slug}>
          {option.nickname
            ? `${option.cardholder_name} (${option.nickname})`
            : option.cardholder_name}
        </li>
      )}
      size={'small'}
      style={{ width: 250 }}
      value={value}
    />
  );

  return (
    <div className="dashboard-header">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              {multiAccounts && (
                <a className={classes.backButton} href={RailsRoutes.accounts_list_path()}>
                  <i className="fa fa-angle-left chevron-with-link" />
                  Back to all card accounts
                </a>
              )}
              {props.defaultAccount ? (
                <div className={classes.accountRow}>
                  <span className={classes.headerText}>Default New Account Spending Monitor</span>
                </div>
              ) : (
                <>
                  <div className={classes.accountRow}>
                    {multiAccounts ? allCardAccountsSelect : <span>{props.fullName}</span>}
                    <span className={classes.headerText}>Dashboard</span>
                  </div>
                  <DashboardCardStatus
                    accountId={props.accountId}
                    last4Digits={props.last4Digits}
                    status={props.status}
                  />
                </>
              )}
            </div>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

DashboardHeader.propTypes = {
  accountId: PropTypes.string.isRequired,
  allAccounts: PropTypes.array,
  defaultAccount: PropTypes.bool,
  fullName: PropTypes.string,
  last4Digits: PropTypes.string,
  status: PropTypes.string.isRequired,
};
