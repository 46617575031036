import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import noop from 'underscore/modules/noop';
import TrueLinkCardImage from 'images/true-link-card.png';
import ToggleLine from 'react/shared/card/components/spending_monitor_tab/ToggleLine';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  container: {
    margin: '40px auto',
    maxWidth: '70rem',
    minWidth: '400px',
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
      flexDirection: 'column-reverse',
      gap: '20px',
    },
  },
  content: {
    margin: '10px 0 15px',
  },
  incentiveContainer: {
    paddingRight: '7rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '2rem',
      paddingRight: '0',
    },
  },
  imgContainer: {
    background: PALETTE.paleAqua,
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
      paddingBottom: '0',
    },
  },
  imgBgContainer: {
    position: 'relative',
    padding: '20px',
  },
  img: {
    width: '400px',
    marginBottom: theme.spacing(5),
  },
  spendingMonitor: {
    position: 'absolute',
    bottom: '-20px',
    right: '-60px',
    transform: 'scale(0.7)',
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      bottom: '-60px',
    },
  },
  spendingMonitorTitle: {
    fontWeight: '600',
    paddingBottom: '15px',
  },
}));

export default function IncentiveCard(props) {
  const classes = useStyles();
  let contentText = 'Help safeguard spending with the True Link Visa® Prepaid Card';
  let helpText1 =
    'True Link helps families and professionals protect the spending of the people they care for. Track purchases, get real-time alerts, and more.';
  const helpText2 =
    "Sign up today and you'll get three months of True Link fees waived* when you activate your new Visa card.";

  if (props.isCareAgencyExperiment) {
    contentText = 'Your Care Agency has invited you to use a True Link Visa® Prepaid Card';
    helpText1 =
      'The True Link Visa Card helps empower Caregivers to more easily spend on behalf of your loved one.';
  }

  return (
    <Grid
      alignItems="center"
      className={classes.container}
      container
      data-testid="incentiveCard"
      direction="row"
      justifyContent="center"
    >
      <Grid
        className={classes.incentiveContainer}
        container
        direction="column"
        item
        md={6}
        spacing={3}
        xs={10}
      >
        <Grid item>
          {!props.isCareAgencyExperiment && (
            <Typography color="primary" variant="h4">
              {props.referrerFirstName} {props.referrerLastName} thinks you’d like True Link
            </Typography>
          )}
          <Typography className={classes.content} variant={props.desktop ? 'h2' : 'h3'}>
            {contentText}
          </Typography>
          <Typography variant="body1">{helpText1}</Typography>
          {!props.isCareAgencyExperiment && (
            <>
              <br />
              <Typography variant="body1">{helpText2}</Typography>
            </>
          )}
        </Grid>
        <Grid item>
          <TrueLinkLink className="primary" href={RailsRoutes.signups_card_new_path()}>
            Sign up
          </TrueLinkLink>
        </Grid>
      </Grid>
      <Grid className={classes.imgContainer} item md={6} xs={12}>
        <div className={classes.imgBgContainer}>
          <img alt="True Link Card" className={classes.img} src={TrueLinkCardImage} />
          <Card className={classes.spendingMonitor}>
            <Typography className={classes.spendingMonitorTitle} variant="body2">
              Spending monitor categories:
            </Typography>
            <ToggleLine
              hr={false}
              is_checked
              name=""
              rules={{}}
              text="Telemarketers"
              updateChecked={noop}
              updateUnChecked={noop}
            />
            <div style={{ marginTop: '10px' }} />
            <ToggleLine
              hr={false}
              is_checked={false}
              name=""
              rules={{}}
              text="Pharmacy"
              updateChecked={noop}
              updateUnChecked={noop}
            />
            <br />
            <Typography className={classes.spendingMonitorTitle} variant="body2">
              Merchant exception to allow:
            </Typography>
            <ToggleLine
              hr={false}
              is_checked={false}
              name=""
              rules={{}}
              text="Peet’s Coffee"
              updateChecked={noop}
              updateUnChecked={noop}
            />
          </Card>
        </div>
      </Grid>
    </Grid>
  );
}

IncentiveCard.propTypes = {
  desktop: PropTypes.bool,
  isCareAgencyExperiment: PropTypes.bool,
  referrerFirstName: PropTypes.string,
  referrerLastName: PropTypes.string,
};
