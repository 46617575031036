import PropTypes from 'prop-types';
import React from 'react';
import CustomCheckbox from 'react/shared/card/components/CustomCheckbox';
import SavedMessage from 'react/shared/components/SavedMessage';
import bindAll from 'react/shared/utils/bind_all';

export default class SpendingBudget extends React.Component {
  constructor(props) {
    super(props);

    const rules = this.props.block_rules;
    const isBudgetSet = !!(rules.budgetAmount && rules.budgetPeriod);
    let amount;
    let period;
    if (isBudgetSet) {
      amount = rules.budgetAmount;
      period = rules.budgetPeriod;
    } else {
      amount = 50;
      period = this.props.periods.find((obj) => obj.name === 'Daily').value;
    }
    this.state = { amount, period, isBudgetSet };

    bindAll(this);
  }

  handleCheckboxChange() {
    if (this.state.isBudgetSet) {
      this.removeSpendingBudget();
    } else {
      this.setSpendingBudget();
    }
  }

  handleAmountChange(e, callback) {
    if (e.target.value) {
      this.setState({ amount: Number.parseFloat(e.target.value) }, callback);
    } else {
      this.setState({ amount: 0 }, callback);
    }
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleAmountChange(e, () => {
        if (this.state.isBudgetSet) {
          this.updateAmountRule();
        } else {
          this.setSpendingBudget();
        }
      });
    }
  }

  updateAmountRule(_e) {
    if (this.state.isBudgetSet) {
      this.props.addBlockRules({ budgetAmount: this.state.amount }, this);
    }
  }

  handlePeriodChange(e) {
    this.setState({ period: Number.parseInt(e.target.value, 10) }, () => {
      if (this.state.isBudgetSet) {
        this.props.addBlockRules({ budgetPeriod: this.state.period }, this);
      }
    });
  }

  setSpendingBudget() {
    this.props.addBlockRules(
      {
        budgetAmount: this.state.amount,
        budgetPeriod: this.state.period,
      },
      this,
      () => {
        this.setState({ isBudgetSet: true });
      },
    );
  }

  removeSpendingBudget() {
    this.props.removeBlockRules(
      {
        budgetAmount: '',
        budgetPeriod: '',
      },
      this,
      () => {
        this.setState({ isBudgetSet: false });
      },
    );
  }

  render() {
    const selectOptions = this.props.periods.map((p) => (
      <option key={p.value} value={p.value}>
        {p.name}
      </option>
    ));

    return (
      <div className="spendingBudget">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="checkbox_label">
          <CustomCheckbox
            checked={this.state.isBudgetSet}
            id="spending_budget"
            onChange={this.handleCheckboxChange}
          />
          Set&nbsp;
          <select onChange={this.handlePeriodChange} value={this.state.period}>
            {selectOptions}
          </select>
          &nbsp;budget of $&nbsp;
          <input
            className="form-control--underline checkbox_textfield"
            onBlur={this.updateAmountRule}
            onChange={this.handleAmountChange}
            onKeyDown={this.handleKeyDown}
            type="text"
            value={this.state.amount}
          />
          <SavedMessage />
        </label>
      </div>
    );
  }
}

SpendingBudget.propTypes = {
  addBlockRules: PropTypes.func.isRequired,
  block_rules: PropTypes.object.isRequired,
  periods: PropTypes.array.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
};

SpendingBudget.defaultProps = {
  periods: [
    { name: 'Daily', value: 1 },
    { name: 'Weekly', value: 2 },
    { name: 'Monthly', value: 3 },
  ],
};
