import PropTypes from 'prop-types';
import React from 'react';
import CheckboxLine from 'react/shared/card/components/CheckboxLine';

export default function TemporaryOverrides(props) {
  const block_rules = props.block_rules;

  return (
    <div>
      <CheckboxLine
        aboveOverlay
        id="allow_all"
        isChecked={!!block_rules['allowAllTransactions']}
        rules={{ allowAllTransactions: true }}
        text2="Allow all transactions - This temporarily removes all settings so all purchases are allowed"
        updateChecked={props.addBlockRules}
        updateData={props.updateBlockRules}
        updateUnChecked={props.removeBlockRules}
      />
      <CheckboxLine
        aboveOverlay
        id="block_all"
        isChecked={!!block_rules['blockAllTransactions']}
        rules={{ blockAllTransactions: true }}
        text2="Block all transactions - This temporarily overrides all card settings so all purchases are blocked"
        updateChecked={props.addBlockRules}
        updateData={props.updateBlockRules}
        updateUnChecked={props.removeBlockRules}
      />
    </div>
  );
}

TemporaryOverrides.propTypes = {
  addBlockRules: PropTypes.func.isRequired,
  block_rules: PropTypes.object.isRequired,
  removeBlockRules: PropTypes.func.isRequired,
  updateBlockRules: PropTypes.func,
};
